<form class="flex pt-2" [formGroup]="formGroup">
  <cap-dropdown formControlName="brand_attribute_base_data_value_id"
                [label]="('dental.brand_attribute_base_data_value_id' | translate) + to.labelSuffix"
                placeholder="-"
                [autoDisplayFirst]="false"
                [options]="implantBrandOptions"
                [disabled]="!implantBrandOptions?.length"
                [optionLabel]="'value'"
                [optionValue]="'id'"
                [appendTo]="'body'"
                [sortAlphabetically]="false">
  </cap-dropdown>

  <cap-dropdown formControlName="system_attribute_base_data_value_id"
                [label]="('dental.system_attribute_base_data_value_id' | translate) + (systemRequired ? to.labelSuffix : '')"
                placeholder="-"
                [autoDisplayFirst]="false"
                [options]="implantSystemOptions"
                [disabled]="!implantSystemOptions?.length"
                [optionLabel]="'value'"
                [optionValue]="'id'"
                [appendTo]="'body'"
                [sortAlphabetically]="false">
  </cap-dropdown>

  <cap-dropdown formControlName="platform_attribute_base_data_value_id"
                [label]="('dental.platform_attribute_base_data_value_id' | translate) + (platformRequired ? to.labelSuffix : '')"
                placeholder="-"
                [autoDisplayFirst]="false"
                [options]="implantPlatformOptions"
                [disabled]="!implantPlatformOptions?.length"
                [optionLabel]="'value'"
                [optionValue]="'id'"
                [appendTo]="'body'"
                [sortAlphabetically]="false">
  </cap-dropdown>
</form>
