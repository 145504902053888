import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { LoginAction, LoginConfig, LoginService as CapturumLoginService } from '@capturum/login';
import { ToastService } from '@capturum/ui/api';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { PublicLocaleApiService } from '@features/auth/services/public-locale-api.service';
import Locale from '@capturum/auth/lib/locale.interface';

@Injectable()
export class LoginService extends CapturumLoginService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private loginError$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private loginBusy$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected readonly config: LoginConfig,
    protected readonly router: Router,
    protected readonly activatedRoute: ActivatedRoute,
    protected readonly toastService: ToastService,
    protected readonly translateService: TranslateService,
    protected readonly formBuilder: FormBuilder,
    private publicLocaleApiService: PublicLocaleApiService,
    private authService: AuthService,
  ) {
    super(config, router, activatedRoute, toastService, translateService, formBuilder);

    this.getLocales().pipe(first()).subscribe(locales => {
      const locale = this.getDefaultLocaleCode(locales);

      this.setLanguage(locale);
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setLanguage(language: string): void {
    this.translateService.reloadLang(language);
    this.translateService.use(language);
  }

  public getLoginError$(): Observable<string> {
    return this.loginError$.asObservable();
  }

  public isLoginBusy$(): Observable<boolean> {
    return this.loginBusy$.asObservable();
  }

  public login(data: { email: string, password: string, remember: boolean, locale_id: string }): Observable<any> {
    this.loginBusy$.next(true);

    const { email, password, remember, locale_id }: any = data;

    return this.authService.login(email, password, { remember, locale_id }).pipe(
      catchError((error) => {
        super.setAction(LoginAction.login, false, error.status);
        super.onLoginFail(error);
        this.loginError$.next('auth.sign-in.error');

        return throwError(error);
      }),
      finalize(() => this.loginBusy$.next(false)),
    );
  }

  public onLoginSuccess(response: any): void {
    super.setAction(LoginAction.login, true);
    super.onLoginSuccess(response);
  }

  public getLocales(): Observable<Locale[]> {
    return this.publicLocaleApiService.getLocales();
  }

  public getDefaultLocaleCode(locales: Locale[]): string {
    const currentLanguage = this.translateService.currentLang;
    let hostLanguage = document.location.host.split('.').pop();
    hostLanguage = hostLanguage === 'ie' ? 'en' : hostLanguage;
    const isHostLanguageAvailable = hostLanguage && locales?.some(locale => locale.code === hostLanguage);

    return isHostLanguageAvailable ? hostLanguage : currentLanguage;
  }

  public getCurrentTenant(): Observable<{ tenant_key: string }> {
    return this.publicLocaleApiService.apiHttp.get('/tenant_current');
  }
}
