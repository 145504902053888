import { Pipe, PipeTransform } from '@angular/core';
import Autolinker from 'autolinker';

@Pipe({
  name: 'parseUrls',
})
export class ParseUrls implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return;
    }

    return Autolinker.link(value, {
      email: false,
      phone: false,
      decodePercentEncoding: false,
      stripTrailingSlash: false,
      sanitizeHtml: false,
    });
  }
}
