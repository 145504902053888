import { Component, OnInit } from '@angular/core';
import { BatchStatusActionsService, VersionCheckService } from '@capturum/complete';
import { DownloadUrlAction } from '@core/batch-status-actions/download-url.service';

@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <cap-toast></cap-toast>
  `,
  providers: [VersionCheckService],
})
export class AppComponent implements OnInit {

  constructor(
    private batchStatusActionService: BatchStatusActionsService,
  ) {
  }

  public ngOnInit(): void {
    this.batchStatusActionService.addActions({ download_url: DownloadUrlAction });
  }
}
