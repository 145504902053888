import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ApiModule } from '@capturum/api';
import { environment } from '@environments/environment';
import { AuthModule } from '@capturum/auth';
import { LoginModule } from '@capturum/login';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { CompleteModule, ErrorMessageInterceptor, TranslationLoader, TranslationService } from '@capturum/complete';
import { SharedModule as CapturumSharedModule } from '@capturum/shared';
import { CapturumButtonModule } from '@capturum/ui/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { FormlyModule } from '@ngx-formly/core';
import { API_DATE_BLACKLIST, API_DATE_PROPERTIES, DatesFormats } from '@core/configs/date-config';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormBuilderModel } from '@features/client/form-builder/models/form-builder.model';
import { FormBuilderModule } from '@features/client/form-builder/form-builder.module';
import { NgxsModule } from '@node_modules/@ngxs/store';
import { ConfirmationService } from 'primeng/api';
import { BaseDataService } from '@core/services/base-data.service';
import { NgxsReduxDevtoolsPluginModule } from '@node_modules/@ngxs/devtools-plugin';
import { DentalCompleteResolver } from '@core/resolvers/dental-complete.resolver';
import { DentalBaseDataResolver } from '@core/resolvers/dental-base-data.resolver';
import { OrderResolver } from '@core/resolvers/order.resolver';
import { AttributesBaseDataResolver } from '@core/resolvers/attributes-base-data.resolver';
import { AttributesTranslationResolver } from '@core/resolvers/attributes-translation.resolver';
import { LoginService } from '@features/auth/services/login.service';
import { passwordValidator } from '@shared/validators/password.validator';
import * as Sentry from '@sentry/angular-ivy';
import { CapturumDialogService } from '@capturum/ui/api';
import { CapturumBuilderCoreModule } from '@capturum/builders/core';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CapturumToastModule } from '@capturum/ui/toast';
import { SentryErrorHandler } from '@core/error-handlers/SentryErrorHandler';
import { Router } from '@angular/router';

export function onAuthError(): void {
  window.location.href = `auth/login?redirectUrl=${window.location.pathname}`;
}

export class CustomMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string {
    return `[[ ${params.key} ]]`;
  }
}

Sentry.init({
  dsn: environment.sentryUrl,
  environment: environment.name,
});

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CapturumBuilderCoreModule.forRoot({ baseUrl: 'builders' }),
    CapturumFormRendererModule.forRoot({ translationKeyPrefix: 'dental' }),
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomMissingTranslationHandler,
      },
      loader: {
        provide: TranslateLoader,
        useClass: TranslationLoader,
      },
    }),
    NgxPermissionsModule.forRoot(),
    AuthModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      authRejectionRoute: 'auth/login',
    }),
    ApiModule.forRoot({
      baseUrl: environment.baseUrl,
      production: environment.production,
      onAuthError,
      dateInterceptor: {
        dateProperties: API_DATE_PROPERTIES,
        blackListedURLs: API_DATE_BLACKLIST,
        blackListedStatusCodes: [401, 204],
        displayFormat: DatesFormats.dateFnsDisplayFormat,
        sendFormat: DatesFormats.dateFnsSendFormat,
      },
    }),
    LoginModule.forRoot({
      environment,
      productName: 'complete-frontend',
      redirectAfterLogin: 'order',
      loginService: LoginService,
      passwordValidator: passwordValidator,
    }),
    CapturumSharedModule.forRoot(),
    SharedModule,
    CompleteModule.forRoot({
      indexedDbModels: [
        FormBuilderModel,
      ],
      databaseName: 'complete-db',
      baseDataService: BaseDataService,
      version: 1,
    }, NgxPermissionsService, NgxPermissionsModule),
    CapturumButtonModule,
    CoreModule,
    ProgressSpinnerModule,
    FormlyModule.forRoot(),
    FormBuilderModule.forRoot(),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    CapturumToastModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    ConfirmationService,
    DentalCompleteResolver,
    DentalBaseDataResolver,
    OrderResolver,
    AttributesBaseDataResolver,
    AttributesTranslationResolver,
    CapturumDialogService,
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMessageInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        return () => {};
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class AppModule {
}
