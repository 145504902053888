import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from '@node_modules/rxjs';
import { OrderWorkflowStep } from '@features/client/order-workflow/enums/order-workflow-step.enum';

@Injectable()
export class OrderWorkflowService implements OnDestroy {
  private processStep$: BehaviorSubject<OrderWorkflowStep> = new BehaviorSubject<OrderWorkflowStep>(null);

  public ngOnDestroy(): void {
    this.processStep$.next(null);
  }

  public setProcessStep(nextStep: OrderWorkflowStep = null): void {
    this.processStep$.next(nextStep);
  }

  public getProcessStep(): Observable<OrderWorkflowStep> {
    return this.processStep$.asObservable();
  }
}
