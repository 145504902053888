<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <app-uploader [control]="formControl"
                [label]="to.label"
                [tooltip]="to.tooltip"
                [description]="to.description"
                [multiple]="to.multiple"
                [tag]="to?.tag"
                [maxSize]="to?.validations?.max"
                [maxCount]="to?.validations?.count"
                [extensionsAccepted]="to?.availableExtensions"
                [fileableType]="to?.fileableType"
                [messages]="to?.messages">
  </app-uploader>
</div>
