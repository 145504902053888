import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification } from '@shared/components/notification/notifications.model';
import { NotificationType } from '@shared/components/notification/notifications.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private pendingNotifications: Notification[] = [];
  private otherNotifications: Notification[] = [];
  private notification$: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>(null);

  public showNotification(data: Notification): void {
    if (data.type === NotificationType.pending) {
      this.pendingNotifications.push(data);
    } else {
      this.otherNotifications.push(data);
    }

    this.notification$.next([...this.pendingNotifications, ...this.otherNotifications]);

    setTimeout(() => {
      this.closeNotification();
    }, 10000);
  }

  public getNotification(): Observable<Notification[]> {
    return this.notification$.asObservable();
  }

  public closeNotification(type: string = null, index: number = 0): void {
    if (type === NotificationType.pending) {
      this.pendingNotifications.splice(index, 1);
    } else {
      this.otherNotifications.splice(index, 1);
    }

    this.notification$.next([...this.pendingNotifications, ...this.otherNotifications]);
  }
}
