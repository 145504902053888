import { Component } from '@angular/core';
import { get } from 'lodash';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-label-input',
  templateUrl: './label-input.component.html',
  styleUrls: ['./label-input.component.scss'],
})
export class LabelInputComponent extends FieldType {
  public getValue: any = get;
}
