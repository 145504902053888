import { Order } from '@features/client/order-workflow/models/order.model';
import { ProductTypeMapItem } from '@features/client/order-workflow/models/product.model';
import { OrderProduct } from '@features/client/order-workflow/models/order-product.model';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';

export class SetOrder {
  public static readonly type = '[OrderWorkflow] Set order';

  constructor(public order: Order) {
  }
}

export class ClearOrderWorkflow {
  public static readonly type = '[OrderWorkflow] Clear order workflow';
}

export class SetProductType {
  public static readonly type = '[OrderWorkflow] Set product type';

  constructor(public productType: ProductTypeMapItem) {
  }
}

export class SetOrderProduct {
  public static readonly type = '[OrderWorkflow] Set order product';

  constructor(public orderProduct: OrderProduct) {
  }
}

export class PatchOrder {
  public static readonly type = '[OrderWorkflow] Patch order';

  constructor(public unsavedOrder: Partial<Order>) {
  }
}

export class OpenChat {
  public static readonly type = '[OrderWorkflow] Open chat';

  constructor(public openChat: boolean) {
  }
}

export class SetExternalAuthStatus {
  public static readonly type = '[OrderWorkflow] Set external auth status';

  constructor(public externalAuthStatus: ExternalAuthStatus[] ) {
  }
}

