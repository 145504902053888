import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assets',
})
export class AssetsPipe implements PipeTransform {
  public transform(value: string, type: string = 'images'): string {
    switch (type) {
      case 'pontic-shape':
        return `./assets/svg/pontic-shape/${value}`;
      case 'images':
        return `./assets/images/${value}`;
      default:
        return `./assets/${value}`;
    }
  }
}
