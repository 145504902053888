import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterMetadata } from '@node_modules/primeng/api';
import { FilterMatchMode } from '@capturum/ui/api';
import * as moment from '@node_modules/moment';
import { DatesFormats } from '@core/configs/date-config';
import { FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit, OnDestroy {
  @Input() public activeFilters: Record<string, FilterMetadata>;
  @Input() public disableFilters: boolean;
  @Input() public hasDateFilter: boolean = false;

  @Output() public filter: EventEmitter<FilterMetadata & { field: string }> = new EventEmitter<FilterMetadata & { field: string }>();
  @Output() public search: EventEmitter<string> = new EventEmitter<string>();

  public datesRangeFilter: Date[];
  public searchControl = new FormControl();
  public maxDate = new Date();

  private destroy$ = new Subject();

  constructor() {

  }

  public ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(1000),
      takeUntil(this.destroy$),
   ).subscribe(search => this.search.emit(search));
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public filterByCreatedAtDate(): void {
    const hasDatesRangeFilter = this.datesRangeFilter && this.datesRangeFilter[0];
    const value = hasDatesRangeFilter ? this.solveCreatedAtDatesRange() : null;

    this.filter.emit({
      field: 'created_at',
      matchMode: FilterMatchMode.DATEBETWEEN,
      value,
    });
  }

  private solveCreatedAtDatesRange(): string[] {
    const startDate = moment(this.datesRangeFilter[0]);
    const endDate = this.datesRangeFilter[1] ? moment(this.datesRangeFilter[1]) : moment(startDate);

    return [
      startDate.startOf('day').format(DatesFormats.sendWithTimeFormat),
      endDate.endOf('day').format(DatesFormats.sendWithTimeFormat)
    ];
  }
}
