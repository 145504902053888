import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { loginRoutes } from '@capturum/login';
import { IndexedDbGuard, PublicTranslationResolver } from '@capturum/complete';
import { AuthGuard } from '@capturum/auth';
import { FormBuilderResolver } from '@features/client/form-builder/resolvers/form-builder.resolver';
import { DentalCompleteResolver } from '@core/resolvers/dental-complete.resolver';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'client',
    pathMatch: 'full',
  },
  {
    path: loginRoutes[0].path,
    loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [IndexedDbGuard],
    resolve: {
      publicTranslations: PublicTranslationResolver,
    },
  },
  {
    path: 'client',
    loadChildren: () => import('./features/client/client.module').then((m) => m.ClientModule),
    canActivate: [IndexedDbGuard, AuthGuard],
    resolve: {
      complete: DentalCompleteResolver,
      formBuilders: FormBuilderResolver,
    },
  },
  {
    path: 'power-bi',
    loadChildren: () => import('@capturum/powerbi/pages').then((m) => m.CapturumPowerBIPagesModule),
  },
  {
    path: '**',
    redirectTo: 'client',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
