import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FormBuilderApiService } from '@features/client/form-builder/services/form-builder-api.service';

@Injectable()
export class FormBuilderResolver implements Resolve<Observable<any>> {
  constructor(private readonly formBuilderApiService: FormBuilderApiService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.formBuilderApiService.resolveFormBuilders();
  }
}
