import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModuleKeys } from '@core/enums/module-keys.enum';

@Pipe({
  name: 'translateDental',
})
export class TranslateDentalPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {
  }

  public transform(value: string, interpolation: any = null): string {
    return this.translateService.instant(`${ModuleKeys.dental}.${value}`, interpolation);
  }
}
