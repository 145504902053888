import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ImplantCustomApiService extends ApiService<any> {
  protected endpoint: string = 'form-builder';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public getImplants(implantValues: string[]): Observable<any> {
    const values = implantValues.map(value => `/${value}`).join().replace(/,/g, '');

    return this.apiHttp.get<any>(`/${this.endpoint}/order-implant-custom${values}`).pipe(map(value => value.data));
  }
}
