import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUrlPipe } from './pipes/file-url.pipe';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { SharedModule } from '@shared/shared.module';
import { CapturumSharedModule } from '@capturum/ui/api';
import { UploaderComponent } from '@shared/modules/uploader/containers/uploader/uploader.component';
import { PreviewerComponent } from '@shared/modules/uploader/components/previewer/previewer.component';
import { TranslateModule } from '@node_modules/@ngx-translate/core';
import { ConfirmDialogModule } from '@node_modules/primeng/confirmdialog';
import { PreviewerPopupComponent } from './components/previewer-popup/previewer-popup.component';
import { DownloadFileService } from '@shared/modules/uploader/services/download-file.service';
import { FileableStateConfigService } from '@shared/modules/uploader/services/fileable-state-config.service';
import { ImageUploaderComponent } from '@shared/modules/uploader/containers/image-uploader/image-uploader.component';
import { ProgressSpinnerModule } from '@node_modules/primeng/progressspinner';
import { ScansDownloadStatusComponent } from '@shared/modules/uploader/components/scans-download-info/scans-download-status.component';

@NgModule({
  declarations: [
    UploaderComponent,
    PreviewerComponent,
    FileUrlPipe,
    PreviewerPopupComponent,
    ImageUploaderComponent,
    ScansDownloadStatusComponent,
  ],
  exports: [
    UploaderComponent,
    PreviewerComponent,
    FileUrlPipe,
    ImageUploaderComponent,
  ],
  imports: [
    CommonModule,
    CapturumFileUploadModule,
    SharedModule,
    CapturumSharedModule,
    TranslateModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
  ],
  providers: [
    DownloadFileService,
    FileableStateConfigService,
  ],
})
export class UploaderModule {
}
