<div class="preview-popup">
  <div *ngFor="let file of items?.slice(0, itemsToPreviewCount)" class="file">
    <div class="file__preview">
      <ng-container *ngIf="isImageFile(file)">
        <img *ngIf="showThumbnail"
             [src]="imagePathProperty ? file[imagePathProperty] : file | fileUrl"
             [alt]="file[nameProperty]" />
      </ng-container>

      <ng-container *ngIf="!isImageFile(file)">
        <i class="far fa-file"></i>
      </ng-container>
    </div>

    <p *ngIf="showFilename" class="file__name" (click)="fileService.downloadFile(file)">
      {{ file[nameProperty] || file.filename }}
    </p>

    <i *ngIf="deletable"
       class="far fa-times file__remove"
       [title]="'button.delete' | translate"
       (click)="handleRemoveIconClick($event, file)">
    </i>
  </div>

  <div *ngIf="!showLoader && items?.length > itemsToPreviewCount" class="d-flex">
    <span class="dots">...</span>
    <cap-button *ngIf="!showLoader"
                (click)="showPopup = true"
                styleClass="files-count"
                [label]="items?.length - itemsToPreviewCount">
    </cap-button>
  </div>

  <i *ngIf="showLoader" class="fas fa-spinner-third"></i>
</div>

<p-dialog [header]="('dental.order.tooth_cross.uploaded_pictures.label'| translate) + '(' + items?.length + ')'"
          [(visible)]="showPopup"
          [baseZIndex]="10000"
          [modal]="true"
          [style]="{ width: '100%', maxWidth: '709px' }">
  <div class="files-list" #fileList [ngClass]="{ 'pr-3': (fileList?.scrollHeight > fileList?.clientHeight) }">
    <div *ngFor="let file of items" class="file">
      <div class="file__preview">
        <ng-container *ngIf="isImageFile(file)">
          <img *ngIf="showThumbnail"
               [src]="imagePathProperty ? file[imagePathProperty] : file | fileUrl"
               [alt]="file[nameProperty]" />
        </ng-container>

        <ng-container *ngIf="!isImageFile(file)">
          <i class="far fa-file"></i>
        </ng-container>
      </div>

      <p *ngIf="showFilename" class="file__name" (click)="fileService.downloadFile(file)">
        {{ file[nameProperty] || file.filename }}
      </p>

      <i *ngIf="deletable"
         class="fas fa-trash-alt file__remove"
         [title]="'button.delete' | translate"
         (click)="handleRemoveIconClick($event, file)">
      </i>
    </div>
  </div>

  <div class="text-right mt-4">
    <cap-button [label]="'button.close' | translate"
                styleClass="primary"
                (click)="showPopup = false">
    </cap-button>
  </div>
</p-dialog>
