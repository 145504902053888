import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ClientRoutes } from '@core/enums/routes.enum';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class OrderInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentPath: string = this.router.routerState.snapshot.url;

    if (this.isOrderIdPath(currentPath)) {
      const orderId = this.getOrderIdFromCurrentPath(currentPath);

      if (this.isValidOrderId(orderId)) {
        request = request.clone({
          setHeaders: {
            // Set the header so Backend filters the
            // data OOTB by current order's id.
            'api-order-id': orderId,
          },
        });
      }
    }

    return next.handle(request);
  }

  protected isOrderIdPath(currentPath: string): boolean {
    return currentPath.startsWith(ClientRoutes.clientOrders);
  }

  protected getOrderIdFromCurrentPath(currentPath: string): string {
    const segmentsCount = ClientRoutes.clientOrders.split('/').length;
    const currentPathParts = currentPath.split('/');

    return currentPathParts[segmentsCount] || null;
  }

  protected isValidOrderId(orderId: string): boolean {
    return orderId && uuidv4(orderId);
  }
}
