<div class="calendar" [class.readonly]="readonly">
  <div class="calendar__header">
    <div *ngIf="returnDate" class="d-flex align-items-center">
      <h6>{{ 'dental.order.return_date.label' | translate }}: </h6>
      <span>{{ returnDate | date: 'dd-MM-yyyy' }}</span>
    </div>

    <div class="arrows">
      <i class="fas fa-chevron-left" (click)="selectMonth.emit(calendarNavigation.previous)"></i>
      <p (click)="selectMonth.emit(calendarNavigation.today)">{{ 'calendar-locale.today' | translate }}</p>
      <i class="fas fa-chevron-right" (click)="selectMonth.emit(calendarNavigation.next)"></i>
    </div>
  </div>

  <div class="calendar__body">
    <div class="calendar__body__week">
      <div class="date-item"></div>

      <div class="date-item" *ngFor="let week of daysOfWeek">{{ week }}</div>
    </div>

    <div class="calendar__body__months">
      <div class="date-item"></div>

      <div class="calendar__body__months__day">
        <div *ngFor="let day of daysOfMonths; let index = index; let first = first;"
             [ngClass]="{
                'date-item': true,
                'current': day?.date.toString() === currentDate.toString(),
                'available': day?.available,
                'active': day?.date.toString() === returnDate.toString(),
                'greyed-out': day?.isWithin2Weeks
             }"
             [ngStyle]="{ 'margin-top': day?.weekNumber === 0 ? '3rem' : '0' }"
             (click)="chooseDay(day)">
          <p *ngIf="first || day?.day === 1"
             class="month-name">
            {{ day?.date | date: 'MMM' }}
          </p>

          <span>{{ day?.day }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
