import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Input() public position: 'br' | 'bl' | 'tr' | 'tl' = 'br';
  @Input() public isShort: boolean = false;
}
