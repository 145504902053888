import { Injectable, Injector } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { cloneDeep, get, isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map, shareReplay, tap, throttleTime } from 'rxjs/operators';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { InputConfiguration } from '@features/client/form-builder/models/input-configuration.model';
import { OptionsPipe } from '@features/client/form-builder/pipes/options.pipe';
import { FormInputHelperService } from '@features/client/form-builder/services/form-input-helper.service';
import { Store } from '@node_modules/@ngxs/store';
import { FormBuilderConfigService, UrlPlaceholderConfig } from '@features/client/form-builder/form-builder.config';
import { SettingService } from '@capturum/complete';

@Injectable()
export class InputTypeBuilder implements FormInputConfig<InputConfiguration> {
  protected inputType: string;
  protected subscription: Subscription = new Subscription();
  protected options$: Observable<any[]>;

  constructor(
    protected readonly optionsPipe: OptionsPipe,
    protected readonly formInputHelperService: FormInputHelperService,
    protected readonly translateService: TranslateService,
    protected readonly store: Store,
    private readonly injector: Injector,
    private readonly formBuilderConfigService: FormBuilderConfigService,
    private readonly settingService: SettingService,
  ) {
  }

  public getInput(configuration: InputConfiguration, defaultValue: any): FormlyFieldConfig {
    const input: FormlyFieldConfig = {
      key: configuration.name,
      defaultValue: get(defaultValue, configuration.name),
      wrappers: ['default-input-wrapper'],
      hide: 'hide_setting' in configuration && configuration?.hide_setting !== null ? !this.settingService.getValue(configuration?.hide_setting) : false,
      templateOptions: {
        label: configuration.label ? this.translateService.instant(`${ModuleKeys.dental}.${configuration.label}`) : null,
        tooltip: configuration.tooltip ? this.translateService.instant(`${ModuleKeys.dental}.${configuration.tooltip}`) : null,
        placeholder: configuration.placeholder ? this.translateService.instant(`${ModuleKeys.dental}.${configuration.placeholder}`) : null,
        label_key: this.formInputHelperService.getLabelKeyBySource(configuration.options?.source),
        value_key: this.formInputHelperService.getValueKeyBySource(configuration.options?.source),
        image_key: configuration.options?.source?.image_key,
        options$: new BehaviorSubject([]),
        description: configuration.options?.description ? this.translateService.instant(`${ModuleKeys.dental}.${configuration.options?.description}`) : null,
        is_base_data: configuration.options?.source?.type === 'base_data',
        is_attribute_base_data: configuration.options?.source?.type === 'attribute_base_data',
        is_disabled: 'is_disabled' in configuration ? configuration?.is_disabled : false,
        disabled: 'is_disabled' in configuration ? configuration?.is_disabled : false,
        is_hidden: 'is_hidden' in configuration ? configuration?.is_hidden : false,
        is_editable: defaultValue && 'is_editable' in defaultValue ? defaultValue?.is_editable : true,
        autofocus: !!configuration.options?.autofocus,
        ...this.formInputHelperService.getValidations(configuration.validations as []),
      },
      type: this.inputType,
    };

    input.templateOptions.labelSuffix = input.templateOptions.required ? '*' : '';

    if (input.templateOptions.label && input.templateOptions.required) {
      input.templateOptions.label += input.templateOptions.labelSuffix;
    }

    input.hooks = {
      onInit: (field: FormlyFieldConfig) => {
        if (configuration.sets) {
          this.formInputHelperService.handleOptionsSourceSets(field, configuration);
        }

        if (configuration.dependencies && configuration.dependencies.length) {
          this.iterateField(field, configuration, input);
        } else {
          this.checkSourcePlaceholders(configuration, field, input);
          this.pushOptions(field, input);
        }

        this.formInputHelperService.setCustomValidations(configuration.validations, field);
      },
    };

    this.appendOptions(input, configuration);
    this.setDefaultValue(input, defaultValue);

    this.formInputHelperService.setInputConfiguration(configuration.name, cloneDeep(input));

    return input;
  }

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    // Do nothing.
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    // Do nothing.
  }

  protected iterateField(field: FormlyFieldConfig, configuration: InputConfiguration, input: FormlyFieldConfig): void {
    if (configuration.dependencies) {
      this.checkSourcePlaceholders(configuration, field, input);

      const dependencyArray = (Array.isArray(configuration.dependencies[0]))
        ? configuration.dependencies as string[][]
        : (configuration.dependencies as string[]).map((dependency: string) => [dependency]);

      this.formInputHelperService.resolveDependencies(
        field, dependencyArray, configuration.dependency_operator, configuration, this.options$, input,
      );
    }
  }

  protected setDefaultOption(field: FormlyFieldConfig, options: any[]): void {
    const defaultOption = options.find(option => option.is_default);
    const defaultValue = get(defaultOption, field.templateOptions.value_key);

    if (isNil(field.defaultValue) && !isNil(defaultValue)) {
      field.formControl.setValue(defaultValue);
    }
  }

  protected checkSourcePlaceholders(configuration: InputConfiguration, field: FormlyFieldConfig, input: FormlyFieldConfig): void {
    const url: string = configuration?.options?.source?.data?.url;

    if (url && this.hasPlaceholders(url)) {
      const placeholders = this.getPlaceholders(url);
      const configPlaceholders = this.formBuilderConfigService.urlPlaceholders.find((placeholder: UrlPlaceholderConfig) => placeholders.some(key => key === placeholder.key));
      const placeholderValue = configPlaceholders?.callback(this.injector);

      if (placeholderValue) {
        configuration.options.source.data.url = this.replacePlaceholderToValue(url, configPlaceholders.key, placeholderValue);

        this.setOptions(configuration);
      }

      this.subscription.add(field.form.valueChanges.pipe(throttleTime(1000)).subscribe(valueChanges => {
        let newUrl = url;

        placeholders.forEach((placeholder) => {
          const formControlValue = valueChanges[placeholder];

          if (formControlValue) {
            newUrl = this.replacePlaceholderToValue(newUrl, placeholder, formControlValue);
          }
        });

        if (!this.hasPlaceholders(newUrl)) {
          const newConfiguration = { ...configuration };

          newConfiguration.options.source.data.url = newUrl;

          this.setOptions(newConfiguration);
          this.pushOptions(field, input);
        }
      }));
    } else {
      this.setOptions(configuration, field);
      this.pushOptions(field, input);
    }
  }

  protected setOptions(configuration: InputConfiguration, field?: FormlyFieldConfig): void {
    this.options$ = this.optionsPipe.transform(configuration.options?.source).pipe(
      map(options => this.formInputHelperService.getOptionsWithImageKey(options, configuration.options?.source?.image_key)),
      shareReplay(1),
      tap(() => {
        if (!field?.formControl.value && field?.formControl.value !== field?.defaultValue) {
          field.formControl.setValue(field.defaultValue);
        }
      }));
  }

  protected replacePlaceholderToValue(url: string, placeholder: string, value: string): string {
    return url.replace('{' + placeholder + '}', value);
  }

  protected pushOptions(field: FormlyFieldConfig, input: FormlyFieldConfig): void {
    this.options$.pipe(first()).subscribe((options) => {
      this.setDefaultOption(field, options);

      input.templateOptions.options$.next(options);
    });
  }

  protected hasPlaceholders(endpoint: string): boolean {
    return endpoint.includes('{') && endpoint.includes('}');
  }

  protected getPlaceholders(endpoint: string): string[] {
    return endpoint.split('/')
      .filter((path: string) => this.isPlaceholder(path))
      .map((value: string) => value.slice(1, -1));
  }

  protected isPlaceholder(value: string): boolean {
    return value.startsWith('{') && value.endsWith('}');
  }
}
