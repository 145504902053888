<div class="flex pt-2" *ngIf="formGroup" [formGroup]="formGroup">
  <cap-dropdown [formControlName]="colorCategoryAttributeName"
                [label]="to.label"
                [options]="colorSystemCategories$ | async"
                [optionLabel]="to.label_key"
                [optionValue]="to.value_key"
                [appendTo]="'body'"
                (change)="changeColorSystemType()"
                [sortAlphabetically]="false">
    <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
  </cap-dropdown>

  <ng-container *ngIf="selectedCategory$ | async as selectedCategory">
    <ng-container [ngSwitch]="selectedCategory.type">
      <ng-container *ngSwitchCase="InputType.colorSystem">
        <app-color-system-picker [colorCategories]="colorSystemCategories$ | async"
                                 [selectedColorSystem]="selectedCategory"
                                 (colorChanged)="updateColorForm($event)">
        </app-color-system-picker>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
