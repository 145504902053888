<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <cap-input *ngIf="to.keyFilter; else defaultInput"
             [formControl]="formControl"
             [label]="to.label"
             [disabled]="!to.is_editable"
             [hasTooltip]="to.tooltip"
             [autofocus]="to.autofocus"
             [placeholder]="to.placeholder"
             [filter]="to.keyFilter">
    <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
  </cap-input>

  <ng-template #defaultInput>
    <cap-input [formControl]="formControl"
               [label]="to.label"
               [disabled]="!to.is_editable"
               [autofocus]="to.autofocus"
               [hasTooltip]="to.tooltip"
               [placeholder]="to.placeholder">
      <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
    </cap-input>
  </ng-template>
</div>
