import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { CustomInputConfiguration } from '@features/client/form-builder/models/custom-input-configuration.model';
import { isEmpty } from 'lodash';

@Injectable()
export class CustomInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CustomInputConfiguration> {
  protected appendOptions(input: FormlyFieldConfig, configuration: CustomInputConfiguration): void {
    input.type = configuration.options.key;
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    if (!input.defaultValue) {
      input.defaultValue = isEmpty(value) ? null : value;
    }

    // Solve color system and the other custom input types with object as a value.
    if (input.defaultValue === null) {
      input.defaultValue = value ? (value[input.key as string] || null) : null;
    }
  }
}
