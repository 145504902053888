<div class="preview">
  <div *ngFor="let file of items" class="w-100" (click)="cardClick.emit(file[nameProperty])">
    <div class="file">
      <div class="file__preview" [ngClass]="{ 'file__preview--centered': !isImageFile(file) }">
        <ng-container *ngIf="isImageFile(file)">
          <img *ngIf="showThumbnail"
               [alt]="file[nameProperty]"
               [src]="imagePathProperty ? file[imagePathProperty] : file | fileUrl" />
        </ng-container>

        <ng-container *ngIf="!isImageFile(file)">
          <i class="far fa-file"></i>
        </ng-container>
      </div>

      <p *ngIf="showFilename" class="file__name" (click)="fileService.downloadFile(file)">
        {{ file[nameProperty] || file.filename }}
      </p>

      <i *ngIf="deletable"
         class="fas fa-trash-alt file--remove no-print"
         [title]="'button.delete' | translate"
         (click)="handleRemoveIconClick($event, file)">
      </i>
    </div>
  </div>

  <div *ngIf="showLoader" class="spinner-wrapper">
    <i class="fas fa-spinner-third"></i>
  </div>
</div>
