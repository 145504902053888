import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
})
export class DropdownInputComponent extends FieldType implements OnInit, OnDestroy {
  private destroy = new Subject<boolean>();

  public ngOnInit(): void {
    this.formControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(value => {
      this.form.patchValue({ [this.key as string]: value }, { emitEvent: false });
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next(true);  
    this.destroy.complete();  
  }
}
