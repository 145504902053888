import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonAnimations } from '@shared/animations/common.animations';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [CommonAnimations.rightSidebar, CommonAnimations.fadeIn],
})
export class SidebarComponent {
  @Input() public class: string = '';
  @Input() public showSidebar: boolean;
  @Input() public sidebarTitle: string;
  @Input() public isLogo: boolean = false;
  @Input() public sidebarSubTitle: string;
  @Input() public backgroundColor: string = 'rgba(0, 0, 0, 0.7)';
  @Input() public headerBackgroundColor: string = 'linear-gradient(90deg, #145996, #1a2c68)';
  @Input() public headerCloseColor: string = '#fff';
  @Input() public overlay: boolean = true;
  @Output() public showSidebarChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public overlayClick: EventEmitter<void> = new EventEmitter<void>();
}
