import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';
import { map } from 'rxjs/operators';
import { ApiHttpService, ApiIndexResult, ApiService } from '@capturum/api';
import { Observable, of } from 'rxjs';
import { BaseDataService } from '@core/services/base-data.service';
import { FormInputHelperService } from '@features/client/form-builder/services/form-input-helper.service';
import { DynamicData } from '@features/client/form-builder/models/dynamic-data.model';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Pipe({
  name: 'options',
})
export class OptionsPipe extends ApiService<any> implements PipeTransform {
  constructor(
    apiHttpService: ApiHttpService,
    private readonly formInputHelperService: FormInputHelperService,
    private readonly baseDataService: BaseDataService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
  ) {
    super(apiHttpService);
  }

  public transform(source: any): Observable<any> {
    switch (source?.type) {
      case 'endpoint': {
        const url = `/${source.data.url}${this.getOptionsQuery({ include: get(source, 'data.meta.includes') || [] })}`;

        return this.apiHttp.get<ApiIndexResult<DynamicData[]>>(url)
          .pipe(map((response) => response?.data));
      }
      case 'object': {
        return of(this.formInputHelperService.getValueAsArrayByKeyOrDefault(source.data.from, []));
      }
      case 'base_data': {
        return this.baseDataService.getBaseDataValuesByKeyAndAttribute(source.data.key);
      }
      case 'attribute_base_data': {
        return this.attributeBaseDataService.getBaseDataValuesByKeyAndAttribute(source.data.key);
      }
      default: {
        return of([]);
      }
    }
  }
}
