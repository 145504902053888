import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Step } from '@features/client/workflow-manager/models/step.model';

@Injectable()
export class WorkflowApiService extends ApiService<any> {
  protected endpoint: string = 'workflow';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public getStepsByName(name: string): Observable<Step[]> {
    return this.get(`${name}/steps`);
  }
}
