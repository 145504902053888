import { Injectable } from '@angular/core';
import { TranslationService } from '@capturum/complete';
import { v4 as uuidv4 } from 'uuid';
import { StorageService } from './storage.service';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@capturum/auth';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class AttributesTranslationService extends TranslationService {
  private loaded: boolean;

  constructor(
    private readonly storageService: StorageService,
    api: ApiHttpService,
    translateService: TranslateService,
    authService: AuthService,
    config: PrimeNGConfig
  ) {
    super(api, translateService, authService, config);
  }

  public isLoaded(): boolean {
    return this.loaded;
  }

  public init(orderId: string): this {
    this.resetParameters();
    this.setOrderEndpoint(orderId);

    this.loaded = true;

    return this;
  }

  public resetParameters(): void {
    this.endpoint = 'attribute-translation';
    this.loaded = false;
  }

  private setOrderEndpoint(orderId: string): void {
    const localId = this.storageService.get("user")?.locale_id;

    if (orderId && uuidv4(orderId)) {
      this.endpoint += `/${orderId}${localId ? '?locale_id=' + localId : ''}`;
    }
  }
}
