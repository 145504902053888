<div class="tooltip-arrow"
     [ngClass]="{
       'arrow-top': ['br', 'bl'].includes(position),
       'arrow-bottom': ['tr', 'tl'].includes(position),
       short: isShort
     }">
</div>

<div [class]="'tooltip-body ' + position"
     [ngClass]="{
       'body-top': ['br', 'bl'].includes(position),
       'body-bottom': ['tr', 'tl'].includes(position),
       short: isShort
     }">
  <ng-content></ng-content>
</div>
