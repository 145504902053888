import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolify',
})
export class BoolifyPipe implements PipeTransform {
  public transform(value: unknown): boolean {
    return !!value;
  }
}
