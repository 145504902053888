import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationService } from '@core/services/notification.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { DentalValidatorService } from '@core/services/dental-validator.service';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { NotificationType } from '@shared/components/notification/notifications.enum';

@Injectable({
  providedIn: 'root',
})
export class HttpCodeInterceptor implements HttpInterceptor {
  constructor(
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly validatorService: DentalValidatorService,
    private readonly translateService: TranslateService,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
              case 403:
              case 405:
              case 422:
                this.parseValidations(error.error);

                break;
              case 401:
                this.parseValidations(error.error);
                this.router.navigate([AppRoutes.login]);

                break;
              default:

                break;
            }
          }
        },
      ),
    );
  }

  private parseValidations(response: { errors: Record<string, string[]>, message: string }): void {
    const keys = response.errors && Object.keys(response.errors);
    const nonField = !response.errors;

    if (keys && keys.length) {
      const fieldErrors = keys.reduce((accumulator, key) =>
        ({ ...accumulator, [key]: response.errors[key] }), {});

      this.validatorService.backendValidation.next(fieldErrors);
    }

    if (nonField) {
      const message = response.message;

      this.notificationService.showNotification({ title: this.translateService.instant('toast.error.title'), description: message, type: NotificationType.error });
    }
  }
}
