import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { isObject } from 'lodash';
import { FieldType } from '@ngx-formly/core';
import { DatesFormats } from '@core/configs/date-config';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { CalendarNavigation } from '@shared/modules/calendar/enums/calendar-navigation.enum';

interface CalendarDayData {
  day: number;
  date: Date;
  dayOfWeek: string;
  weekNumber: number;
  weekIndex: number;
  available: boolean;
  isWithin2Weeks: boolean;
}

@Component({
  selector: 'app-patient-calendar',
  templateUrl: './patient-calendar.container.html',
})
export class PatientCalendarContainer extends FieldType implements OnInit, OnDestroy {
  @Input() public activeDay: Date;
  @Input() public isDatepicker: boolean = false;
  @Input() public isExpectedDate: boolean = false;
  @Output() public selectDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() public closeDatepicker: EventEmitter<any> = new EventEmitter<any>();

  public currentDate: Date = moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
  public currentMonth: Date = moment().set('date', 1).toDate();
  public disabledRangeInDays: number = 0;
  public selectableRangeInDays: number = 42;
  public daysOfWeek: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  public months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public startDayOfWeek: number;
  public daysOfMonths: CalendarDayData[] = [];
  public firstAvailableDate: moment.Moment;
  public lastAvailableDate: moment.Moment;
  public returnDate: Date;
  public selectedMonth: Date;
  public weekNumbers: number[] = [];
  private subscription: Subscription = new Subscription();
  private selectedMonthIndex: number;

  public ngOnInit(): void {
    this.firstAvailableDate = moment(this.currentDate).add(this.disabledRangeInDays, 'days');
    this.returnDate = this.determineReturnDate();

    this.selectCalendarMonth();
    this.selectAvailableDay();

    // @ts-ignore
    this.weekNumbers = [...(new Set(this.daysOfMonths.map(v => v.weekIndex)))].splice(0, 6);
    this.activeDay = moment(this.activeDay, DatesFormats.displayFormat).toDate();

    if (this.field) {
      this.subscription = this.formControl.valueChanges.pipe(distinctUntilChanged(), filter(Boolean)).subscribe(value => {
        if (isObject(value)) {
          this.formControl.reset();
        } else {
          this.formControl.setValue(moment(value).format(DatesFormats.sendFormat));
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public selectCalendarMonth(action?: string): void {
    if (action === CalendarNavigation.next) {
      this.currentMonth = moment(this.currentMonth).add(1, 'months').toDate();
    } else if (action === CalendarNavigation.previous) {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'months').toDate();
    } else if (action === CalendarNavigation.today) {
      this.currentMonth = this.currentDate;
    }

    if (this.currentDate?.getMonth() === this.currentMonth?.getMonth() && this.currentDate?.getFullYear() === this.currentMonth?.getFullYear()) {
      this.currentMonth = moment(this.currentMonth).set('date', this.currentDate.getDate()).toDate();
    } else {
      this.currentMonth = moment(this.currentMonth).set('date', 1).toDate();
    }

    this.lastAvailableDate = moment(this.currentMonth).add(this.disabledRangeInDays + this.selectableRangeInDays, 'days');
    this.daysOfMonths = this.generateDaysToDisplay(this.currentMonth);
  }

  public determineReturnDate(): Date {
    if (this.field?.defaultValue && !isObject(this.field.defaultValue)) {
      const initialValue = new Date(this.field.defaultValue);

      initialValue.setHours(0, 0, 0);

      return initialValue;
    }

    return moment(this.currentDate)
      .add(this.disabledRangeInDays + 1, 'days')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }

  public selectAvailableDay(selectedDay?: any): void {
    if (!!selectedDay) {
      this.returnDate = selectedDay;
    }

    if (this.field) {
      this.formControl.setValue(moment(this.returnDate).format(DatesFormats.sendFormat));
    }
  }

  public selectDatepickerMonth(increase: boolean): void {
    const month = new Date();

    if (increase) {
      this.selectedMonthIndex++;
    } else if (!increase && this.selectedMonthIndex > 0) {
      this.selectedMonthIndex--;
    }

    month.setMonth(month.getMonth() + this.selectedMonthIndex);

    this.selectedMonth = month;

    this.generateDaysToDisplay();
  }

  public getWeekIndexInMonth(day: moment.Moment): number {
    const startOfMonth = moment(day).startOf('month');
    const endOfMonth = moment(day).endOf('month');

    const currentMomentDate = moment(startOfMonth);
    const weeks = [];

    while (currentMomentDate.isBefore(endOfMonth)) {
      weeks.push(currentMomentDate.isoWeek());
      currentMomentDate.add(1, 'weeks').startOf('isoWeek');
    }

    return weeks.indexOf(day.isoWeek());
  }

  private generateDaysToDisplay(day: Date = this.currentDate): any[] {
    const calendarData: CalendarDayData[] = [];
    const iterDate = moment(day).startOf('isoWeek');

    while (iterDate.diff(this.lastAvailableDate) < 0) {
      const date = iterDate.clone();

      calendarData.push({
        day: date.date(),
        date: date.toDate(),
        dayOfWeek: date.format('ddd'),
        weekNumber: this.getWeekIndexInMonth(date), // Number of week in month (0-5)
        weekIndex: date.isoWeek(), // Number of week (1-53) but counting from Mon
        available: date.isAfter(this.firstAvailableDate),
        isWithin2Weeks: date.isAfter(this.currentDate) && date.isBefore(moment(this.currentDate).add(2, 'weeks'))
      });

      iterDate.add(1, 'days');
    }

    return calendarData;
  }
}
