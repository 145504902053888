import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { OrderWorkflowFacade } from '@features/client/order-workflow/facades/order-workflow.facade';
import { OrderWorkflowStep } from '@features/client/order-workflow/enums/order-workflow-step.enum';
import { Subscription } from 'rxjs';
import { DeliveryReturnDateFacade } from '@features/client/order-workflow/facades/delivery-return-date.facade';
import { delay, first } from 'rxjs/operators';
import { VaporFile } from '@shared/modules/uploader/models/file.model';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-scans-download-status',
  templateUrl: './scans-download-status.component.html',
  styleUrls: ['./scans-download-status.component.scss'],
})
export class ScansDownloadStatusComponent implements OnInit, OnDestroy {
  @Output() public downloadedScans: EventEmitter<VaporFile[]> = new EventEmitter<VaporFile[]>();

  public scansDownloadLeft: number;
  private localInterval: Timeout;
  private subscription: Subscription = new Subscription();

  constructor(
    private readonly orderWorkflowFacade: OrderWorkflowFacade,
    private readonly deliveryReturnDateFacade: DeliveryReturnDateFacade,
  ) {
  }

  public ngOnInit(): void {
    this.orderWorkflowFacade.getActiveStep().pipe(first()).subscribe(step => {
      if (step === OrderWorkflowStep.deliveryReturnDate) {
        this.checkDownloadStatus();
      }
    });

    this.subscription.add(this.orderWorkflowFacade.callDownloadStatusMethod().subscribe(() => {
      clearInterval(this.localInterval);

      this.checkDownloadStatus();
    }));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    clearInterval(this.localInterval);
  }

  private checkDownloadStatus(): void {
    this.getDownloadStatus();

    this.localInterval = setInterval(() => {
      this.getDownloadStatus();
    }, 10000);
  }

  private getDownloadStatus(): void {
    setTimeout(() => {
      this.orderWorkflowFacade.getDownloadStatus().pipe(first()).subscribe(response => {
        this.scansDownloadLeft = response.cases_in_queue;

        if (!this.scansDownloadLeft) {
          clearInterval(this.localInterval);

          this.deliveryReturnDateFacade.fetchDeliveryReturnDateData().pipe(first(), delay(2000)).subscribe(order => {
            this.downloadedScans.emit(order.scanFiles);
          });
        }
      });
    }, 2000);
  }
}
