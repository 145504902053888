<div *ngIf="showSidebar && overlay"
     [@fadeIn]="showSidebar"
     class="dental-sidebar-close"
     [ngStyle]="{ 'background': backgroundColor }"
     (click)="overlayClick.emit()">
</div>

<div *ngIf="showSidebar" [@rightSidebar]="showSidebar" class="dental-sidebar">
  <div class="dental-sidebar__header" [ngClass]="class" [ngStyle]="{ 'background': headerBackgroundColor, 'color': headerCloseColor }">
    <div *ngIf="sidebarTitle || isLogo" class="d-flex align-items-center">
      <div *ngIf="isLogo" class="sidebar-logo">
        <img [src]="'logo-heart.png' | assets: 'images'" alt="">
      </div>

      <div>
        <h3>{{ sidebarTitle }}</h3>
        <p *ngIf="sidebarSubTitle">{{ sidebarSubTitle }}</p>
      </div>
    </div>

    <i class="far fa-times"
       (click)="showSidebar = false; showSidebarChange.emit(false)">
    </i>
  </div>

  <div class="dental-sidebar__body"
       [ngStyle]="{ 'height': sidebarSubTitle ? 'calc(100% - 6.313rem)' : 'calc(100% - 4.5rem)' }">
    <ng-content></ng-content>
  </div>
</div>
