import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-checkbox-list-input',
  templateUrl: './checkbox-list-input.component.html',
})
export class CheckboxListInputComponent extends FieldType {
  public setChecked(option: any, checked: boolean): void {
    let controlValue = this.formControl.value;
    const optionValue = option[this.to.value_key];

    if (checked) {
      if (Array.isArray(controlValue)) {
        controlValue.push(optionValue);
      } else {
        controlValue = [optionValue];
      }
    } else {
      controlValue = controlValue.filter((item) => item !== optionValue);
    }

    this.formControl.setValue(controlValue);
  }
}
