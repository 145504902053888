import { Component, Input } from '@angular/core';
import { Color } from '@features/client/order-workflow/models/color.model';

@Component({
  selector: 'app-tooth-part',
  templateUrl: 'tooth-part-component.svg',
  styleUrls: ['./tooth-part.component.scss'],
})
export class ToothPartComponent {
  @Input() public generalBase: Color;
  @Input() public topBase: Color;
  @Input() public middleBase: Color;
  @Input() public bottomBase: Color;
  @Input() public partCount: number;
}
