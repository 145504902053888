<app-calendar *ngIf="!isDatepicker"
              [daysOfWeek]="daysOfWeek"
              [daysOfMonths]="daysOfMonths"
              [months]="months"
              [returnDate]="returnDate"
              [startDayOfWeek]="startDayOfWeek"
              [currentDate]="currentDate"
              [readonly]="field.templateOptions.readonly"
              (selectMonth)="selectCalendarMonth($event)"
              (selectAvailableDay)="selectAvailableDay($event)">
</app-calendar>

<app-datepicker *ngIf="isDatepicker"
                [daysOfWeek]="daysOfWeek"
                [currentDate]="currentDate"
                [daysOfMonths]="daysOfMonths"
                [weekNumbers]="weekNumbers"
                [activeDay]="activeDay"
                [selectedMonth]="selectedMonth"
                (selectMonth)="selectDatepickerMonth($event)"
                (closeDatepicker)="closeDatepicker.emit()"
                (selectDate)="selectDate.emit($event)">
</app-datepicker>

<div class="validator show my-3">
  <span *ngIf="key === 'patient_return_at' && formControl.errors" class="error-text">
    {{ 'dental.error.invalid_patient_return_at' | translate }}
  </span>
</div>
