import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Order } from '@features/client/order-workflow/models/order.model';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import { SetOrder } from '@features/client/order-workflow/state/order-workflow.actions';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { OrderWorkflowStepFacade } from '@features/client/order-workflow/facades/order-workflow-step.facade';
import * as moment from '@node_modules/moment';
import { DatesFormats } from '@core/configs/date-config';

@Injectable()
export class DeliveryReturnDateFacade extends OrderWorkflowStepFacade {
  private readonly include: string[] = ['scanFiles'];
  private daysAfterToday: number = 15;

  public fetchDeliveryReturnDateData(): Observable<Order> {
    return this.store?.select(OrderWorkflowSelectors.getOrder).pipe(
      first(),
      switchMap(({ id }: Order) => this.orderWorkflowFacade.getOrder(id, { include: this.include })),
      map(order => {
        return {
          ...order,
          delivery_location_id: order?.location_id,
          location_id: order?.location_id,
          patient_return_at: order.patient_return_at || moment(new Date).add(this.daysAfterToday, 'days').format(DatesFormats.sendFormat),
        };
      }),
      tap(order => this.store.dispatch(new SetOrder(order))),
      first(),
    );
  }

  public getSubmitData(formData: any): Partial<Order> {
    if (formData.hasOwnProperty('location_id') && formData.location_id === null) {
      delete formData.location_id;
    }

    return {
      ...formData,
      patient_return_at: formData.patient_return_at || moment(new Date).add(this.daysAfterToday, 'days').format(DatesFormats.sendFormat),
    };
  }
}
