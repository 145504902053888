<div class="custom-button-block mt-1" [class.mb-3]="isLogged">
  <button class="custom-button" (click)="connect()">
    <img alt="" [src]="field.key + '.png' | assets" />
    <span *ngIf="!isLogged">{{ 'dental.order.impression_delivery_method.' + field.key | translate }}</span>
    <span *ngIf="isLogged">
    {{ 'dental.order.impression_delivery_method.ios_open_scans.button' | translate: {variable: systemTypeName} }}
  </span>
  </button>

  <!--    TODO: Temporary comment before client will pay for this part-->
  <!--    <a *ngIf="isLogged" class="logout" (click)="logout()">{{ 'link.logout' | translate }}</a>-->
</div>

<p *ngIf="!isLogged" class="warning-message mb-3 mt-1">
  {{ 'dental.order.impression_delivery_method.ios_not_logged_in.label' | translate: {variable: systemTypeName} }}
</p>

