import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TextInputTypeBuilder } from '@features/client/form-builder/builders/text-input-type.builder';
import { TextInputConfiguration } from '@features/client/form-builder/models/text-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable()
export class DateInputTypeBuilder extends TextInputTypeBuilder {
  protected inputType: string = InputType.date;

  protected appendOptions(input: FormlyFieldConfig, configuration: TextInputConfiguration): void {
    input.templateOptions.start_date = configuration.options.start_date;
    input.templateOptions.min_date = configuration.options.min_date;
    input.templateOptions.max_date = configuration.options.max_date;
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    input.defaultValue = input.defaultValue ? new Date(input.defaultValue) : null;
  }
}
