import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { TranslateService } from '@node_modules/@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MockNotificationService {
  public mockNotifications: Observable<any> = of([
    {
      type: 'info',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.info`),
      created_at: '2021-03-19 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.waiting_for_a_tray`, { order: '054889' }),
    },
    {
      type: 'success',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.success`),
      created_at: '2021-03-19 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.has_been_delivered`, {
        order: '054889',
        patient: 'Patient',
      }),
    },
    {
      type: 'warning',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.warning`),
      created_at: '2021-03-19 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.design_waiting_for_approval`),
    },
    {
      type: 'success',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.success`),
      created_at: '2021-03-18 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.has_been_delivered`, {
        order: '054889',
        patient: 'Patient',
      }),
    },
    {
      type: 'info',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.info`),
      created_at: '2021-03-17 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.status_changed`, {
        order: '054889',
        status: 'Changed',
      }),
    },
    {
      type: 'success',
      title: this.translateService.instant(`${ModuleKeys.dental}.notification.level.success`),
      created_at: '2021-03-16 14:43:00',
      message: this.translateService.instant(`${ModuleKeys.dental}.order.notification.has_been_delivered`, {
        order: '054889',
        patient: 'Patient',
      }),
    },
  ]);

  constructor(private readonly translateService: TranslateService) {
  }
}
