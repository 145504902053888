export class SetSteps {
  public static readonly type = '[WorkflowManager] Set steps';

  constructor(public steps: string[]) {
  }
}

export class SetActiveStep {
  public static readonly type = '[WorkflowManager] Set active step';

  constructor(public activeStep: string) {
  }
}
