import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-calendar-footer',
  templateUrl: './calendar-footer.component.html',
  styleUrls: ['./calendar-footer.component.scss']
})
export class CalendarFooterComponent {
  @Output()
  public onSave = new EventEmitter();
  @Output()
  public onClear = new EventEmitter();
  @Output()
  public onSetToday = new EventEmitter<Date>();

  public setToday(): void {
    this.onSetToday.emit(new Date());
  }
}
