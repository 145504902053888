import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { AuthService, MapItem } from '@capturum/auth';
import { BatchStatusService, FinishedBatchStatus } from '@capturum/complete';
import { Order } from '@features/client/order-workflow/models/order.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class OrderApiService extends ApiService<Order> {
  protected endpoint: string = 'order';

  constructor(
    public apiHttp: ApiHttpService,
    public batchStatusService: BatchStatusService,
    public authService: AuthService,
  ) {
    super(apiHttp);
  }

  public place(id: string, data: Order = {}): Observable<Order> {
    return this.apiHttp.post(`/${this.endpoint}/${id}/place`, data);
  }

  public continue(data: any, options?: ListOptions): Observable<Order> {
    return this.apiHttp
      .put(`/${this.endpoint}/${data.id}/continue${this.getOptionsQuery(options)}`, data)
      .pipe(map((response: any) => response.data));
  }

  public update<T>(data: any, options?: ListOptions): Observable<T> {
    return this.apiHttp
      .put(`/${this.endpoint}/${data.id}${this.getOptionsQuery(options)}`, data)
      .pipe(map((response: any) => response.data));
  }

  public clearToothCross(id: string): Observable<Order> {
    const options = { include: ['toothCross'] };

    return this.apiHttp
      .get(`/${this.endpoint}/${id}/clear${this.getOptionsQuery(options)}`)
      .pipe(map((response: any) => response.data));
  }

  public getPdf(id: string): Observable<Blob> {
    return this.apiHttp.getBlob(`/${this.endpoint}/${id}/pdf`, '');
  }

  public getPdfOrders(options?: ListOptions): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/pdf-summaries/index${this.getOptionsQuery(options)}`);
  }

  public getPdfSummaries(options?: ListOptions): Observable<any> {
    return this.apiHttp.get(`/${this.endpoint}/pdf-summaries/list${this.getOptionsQuery(options)}`);
  }

  public sendMessage(id: string, message: string): Observable<Order> {
    return this.apiHttp.post(`/${this.endpoint}/${id}/support/message`, { message });
  }

  public markAsRead(id: string): Observable<Order> {
    return this.apiHttp.post(`/${this.endpoint}/${id}/support/message/mark-as-read`, null);
  }

  public addJawProducts(orderId: string, productId: string, applyToJaw: string): Observable<Order> {
    const options = { include: ['toothCross'], parameters: [{ field: 'apply_to_jaw', value: applyToJaw }] };

    return this.apiHttp
      .post(`/${this.endpoint}/${orderId}/jaw-products/${productId}${this.getOptionsQuery(options)}`, {})
      .pipe(map((response: any) => response.data));
  }

  public deleteJawProducts(orderId: string, productId: string, applyToJaw: string): Observable<Order> {
    const options = { include: ['toothCross'], parameters: [{ field: 'apply_to_jaw', value: applyToJaw }] };

    return this.apiHttp
      .delete(`/${this.endpoint}/${orderId}/jaw-products/${productId}${this.getOptionsQuery(options)}`)
      .pipe(map((response: any) => response.data));
  }

  public getDownloadStatus(id: string): Observable<{ cases_in_queue: number }> {
    return this.apiHttp.get(`/${this.endpoint}/${id}/ios-jobs-status`);
  }

  public export(exportKeys: string[], options?: ListOptions): Observable<FinishedBatchStatus> {
    return this.apiHttp
      .post<{ data: { batch_id: string } }>(`/${this.endpoint}/export${this.getOptionsQuery(options)}`, {
        fields: exportKeys,
      })
      .pipe(
        switchMap((response) => {
          return this.batchStatusService.getIsUpdatedBatch(
            response?.data?.batch_id,
            true,
            'dental.notification.level.success',
            true
          );
        })
      );
  }

  public import(file: File): Observable<FinishedBatchStatus> {
    const options = {
      headers: new HttpHeaders().delete('Content-Type').set('authorization', 'Bearer ' + this.authService.getToken())
    };
    const formData = new FormData();

    formData.append('file', file);

    return this.apiHttp.post<{ data: { batch_id: string } }>(`/${this.endpoint}/import`, formData, options).pipe(
      switchMap((response) => {
        return this.batchStatusService.getIsUpdatedBatch(
          response?.data?.batch_id,
          true,
          'dental.notification.level.success',
          true
        );
      })
    );
  }

  public exportFieldsList(): Observable<{ data: MapItem[] }> {
    return this.apiHttp.get(`/${this.endpoint}/export-fields-list`);
  }
}
