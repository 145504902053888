import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'defaultValue',
})
export class DefaultValuePipe implements PipeTransform {
  public constructor(
    private readonly translateService: TranslateService,
  ) {
  }

  public transform(value: any, defaultValue: string = '-'): any {
    return ((typeof value !== 'number' && !(!!value))
      || (Array.isArray(value) && value.length === 0)
      || (typeof value === 'number' ? false : isEmpty(value))
      || (value === this.translateService.instant('base-data.null'))
    )
      ? defaultValue
      : value;
  }
}
