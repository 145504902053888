import { Injectable } from '@angular/core';
import { ApiHttpService, ApiListResult, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import Locale from '@capturum/auth/lib/locale.interface';

@Injectable({ providedIn: 'root' })
export class PublicLocaleApiService extends ApiService<Locale> {
  protected endpoint = 'locale';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public getLocales(): Observable<Locale[]> {
    return this.list().pipe(
      map((result: ApiListResult) => result?.data?.map((locale: any) => {
        return { ...locale, url: `assets/flags/${locale.code}.svg`, label: locale.name };
      })),
    );
  }
}
