import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { FileInputConfiguration } from '@features/client/form-builder/models/file-input-configuration.model';
import { camelCase, get, isEmpty, isString } from 'lodash';

@Injectable()
export class FileInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<FileInputConfiguration> {
  protected inputType: string = InputType.file;

  protected appendOptions(input: FormlyFieldConfig, configuration: FileInputConfiguration): void {
    input.templateOptions.multiple = configuration.options.is_multiple;
    input.templateOptions.messages = configuration.messages;
    input.templateOptions.validations = this.prepareValidations(configuration);
    input.templateOptions.availableExtensions = this.prepareAvailableExtensions(configuration);

    if (!isEmpty(configuration.options.tag)) {
      input.templateOptions.tag = configuration.options.tag;
    }

    input.templateOptions.fileableType = configuration.options.fileable;
  }

  protected prepareValidations(configuration: FileInputConfiguration): { mimes: string, max?: number } {
    const validations = { mimes: '' };

    for (const validation in configuration.validations) {
      if (configuration.validations.hasOwnProperty(validation)) {
        if (isString(configuration.validations[validation])) {
          const validationParts = configuration.validations[validation].split(':');
          const validationName = validationParts[0];
          let validationRules = validationParts[1];

          if (validationName === 'max') {
            validationRules = validationRules * 1024; // Convert KB to B.
          }

          if (validationName === 'count') {
            validationRules = parseInt(validationRules.toString(), 10);
          }

          validations[validationName] = validationRules;
        } else {
          validations[validation] = configuration.validations[validation];
        }
      }
    }

    return validations;
  }

  protected prepareAvailableExtensions(configuration: FileInputConfiguration): string {
    return this.prepareValidations(configuration).mimes.split(',')
      .map((extension: string) => `.${extension}`)
      .join(',');
  }

  protected setDefaultValue(input: FormlyFieldConfig, value: any): void {
    input.defaultValue = get(value, camelCase(input.key as string));
  }
}
