import { Selector } from '@node_modules/@ngxs/store';
import { WorkflowManagerState } from '@features/client/workflow-manager/state/workflow-manager.state';
import { WorkflowManagerStateModel } from '@features/client/workflow-manager/state/workflow-manager-state.model';

export class WorkflowManagerSelectors {
  @Selector([WorkflowManagerState])
  public static getSteps(state: WorkflowManagerStateModel): string[] {
    return state.steps;
  }

  @Selector([WorkflowManagerState])
  public static getActiveStep(state: WorkflowManagerStateModel): string {
    return state.activeStep;
  }
}
