<header class="header" [class.has-shadow]="hasShadow">
  <div class="header__logo">
    <a routerLink="/">
      <img [src]="'iris-logo.svg' | assets" alt="" />
    </a>
  </div>

  <div class="header__actions">
    <cap-button *ngxPermissionsOnly="'user.manage.self'"
                styleClass="icon-btn-primary mr-2 d-none d-lg-flex"
                icon="fas fa-user"
                routerLink="/client/profile">
    </cap-button>

    <div *ngIf="false" class="header__actions__notifications">
      <cap-button styleClass="icon-btn-primary mr-2"
                  icon="fas fa-bell"
                  (onClick)="toggleNotifications(true)">
      </cap-button>

      <span class="header__actions__notifications__count">{{ notifications?.length }}</span>
    </div>

    <cap-button styleClass="icon-btn-primary mr-2 d-none d-lg-flex"
                icon="fas fa-sign-out"
                title="{{ 'link.logout' | translate }}"
                (onClick)="handleLogoutClick()">
    </cap-button>

    <cap-button styleClass="icon-btn-primary mr-2 d-lg-none"
      icon="fas fa-ellipsis-v"
      (onClick)="toggleNavigation()">
    </cap-button>
  </div>
</header>

<app-sidebar *ngIf="showSidebar"
             [showSidebar]="showSidebar"
             [class]="'px-5'"
             [backgroundColor]="'rgba(0, 0, 0, 0)'"
             [sidebarTitle]="'dental.notifications.label' | translate"
             (showSidebarChange)="toggleNotifications($event)"
             (overlayClick)="toggleNotifications(false)">
  <cap-notification-bar [notifications]="notifications"
                        [momentDateFormat]="''"
                        [translations]="translations"
                        [showDates]="true">
  </cap-notification-bar>
</app-sidebar>

<app-sidebar *ngIf="showNavigation"
             [showSidebar]="showNavigation"
             [backgroundColor]="'#fff'"
             [headerBackgroundColor]="'#fff'"
             [headerCloseColor]="'#000'"
             [overlay]="false"
             (showSidebarChange)="toggleNavigation()"
             (overlayClick)="toggleNavigation()">
  <nav class="nav">
    <a *ngxPermissionsOnly="['order.manage.all', 'order.manage.own']"
       class="nav__route" 
       routerLink="cases" 
       routerLinkActive="active"
       (click)="toggleNavigation()">
      <div class="route">
        <div class="route__label-wrapper">
          <i class="route__icon fas fa-file-alt"></i>
          <span class="route__label ml-4">{{ 'dental.orders.label' | translate }}</span>
        </div>
        <i class="fas fa-angle-right"></i>
      </div>
      <hr class="route__border">
    </a>
    <a *ngxPermissionsOnly="'setting.manage.tenant'"
       class="nav__route" 
       routerLink="settings" 
       routerLinkActive="active" 
       (click)="toggleNavigation()">
      <div class="route">
        <div class="route__label-wrapper">
          <i class="route__icon fas fa-cog"></i>
          <span class="route__label ml-4">{{ 'link.settings' | translate }}</span>
        </div>
        <i class="fas fa-angle-right"></i>
      </div>
      <hr class="route__border">
    </a>
    <a class="nav__route" 
        routerLink="/client/profile"
        routerLinkActive="active" 
        (click)="toggleNavigation()">
      <div class="route">
        <div class="route__label-wrapper">
          <i class="route__icon fas fa-user "></i>
          <span class="route__label ml-4">{{ 'link.profile' | translate }}</span>
        </div>
        <i class="fas fa-angle-right"></i>
      </div>
      <hr class="route__border">
    </a>
    <a class="nav__route" (click)="handleLogoutClick()">
      <div class="route">
        <div class="route__label-wrapper">
          <i class="route__icon fas fa-sign-out"></i>
          <span class="route__label ml-4">{{ 'link.logout' | translate }}</span>
        </div>
        <i class="fas fa-angle-right"></i>
      </div>
      <hr class="route__border">
    </a>
  </nav>
</app-sidebar>
