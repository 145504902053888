<app-header></app-header>

<div class="app-menu d-none d-lg-flex">
  <div *ngxPermissionsOnly="['role.manage']" class="app-menu__item" routerLink="dashboard" routerLinkActive="active">
    <i class="fas fa-chart-line"></i>
    {{ 'link.dashboard' | translate }}
  </div>
  <div *ngxPermissionsOnly="['order.manage.all', 'order.manage.own']" class="app-menu__item" routerLink="cases" routerLinkActive="active">
    <i class="fas fa-file-alt"></i>
    {{ 'dental.orders.label' | translate }}
  </div>
  <div *ngxPermissionsOnly="'setting.manage.tenant'" class="app-menu__item" routerLink="settings" routerLinkActive="active">
    <i class="fas fa-cog"></i>
    {{ 'link.settings' | translate }}
  </div>
</div>

<main class="main-content">
  <router-outlet></router-outlet>
  <app-notification></app-notification>

  <div *ngIf="loadingService.isLoading() | async" class="spinner">
    <p-progressSpinner></p-progressSpinner>
  </div>
</main>
