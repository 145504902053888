import { OrderWorkflowState } from '@features/client/order-workflow/state/order-workflow.state';
import { Selector } from '@node_modules/@ngxs/store';
import { Order } from '@features/client/order-workflow/models/order.model';
import { OrderWorkflowStateModel } from '@features/client/order-workflow/state/order-workflow-state.model';
import { ProductTypeMapItem } from '@features/client/order-workflow/models/product.model';
import { OrderProduct } from '@features/client/order-workflow/models/order-product.model';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';

export class OrderWorkflowSelectors {
  @Selector([OrderWorkflowState])
  public static getOrder(state: OrderWorkflowStateModel): Order {
    return state.order;
  }

  @Selector([OrderWorkflowState])
  public static getUnsavedOrder(state: OrderWorkflowStateModel): Partial<Order> {
    return state.unsavedOrder;
  }

  @Selector([OrderWorkflowState])
  public static getProductType(state: OrderWorkflowStateModel): ProductTypeMapItem {
    return state.productType;
  }

  @Selector([OrderWorkflowState])
  public static getOrderProduct(state: OrderWorkflowStateModel): OrderProduct {
    return state.orderProduct;
  }

  @Selector([OrderWorkflowState])
  public static openChat(state: OrderWorkflowStateModel): boolean {
    return state.openChat;
  }

  @Selector([OrderWorkflowState])
  public static externalAuthStatus(state: OrderWorkflowStateModel): ExternalAuthStatus[] {
    return state.externalAuthStatus;
  }
}
