import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { DropdownInputConfiguration } from '@features/client/form-builder/models/dropdown-input-configuration.model';
import { InputConfiguration } from '@features/client/form-builder/models/input-configuration.model';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class ImplantCustomInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<DropdownInputConfiguration> {
  protected inputType: string = InputType.implantCustom;

  protected appendOptions(input: FormlyFieldConfig, configuration: InputConfiguration): void {
    input.templateOptions.is_attribute_base_data = true;
  }
}
