<div class="d-flex pt-2" appManageValidatorDisplay [control]="formControl">
  <cap-checkbox [formControl]="formControl"
                [label]="to.label">
  </cap-checkbox>

  <i *ngIf="to?.tooltip"
     appHideFromSummaryPdf
     [pTooltip]="to?.tooltip"
     class="fas fa-info-circle"
     tooltipPosition="top"
     tooltipStyleClass="mt-1">
  </i>
</div>

<span *ngIf="to?.description" class="small-description">{{ to?.description }}</span>
