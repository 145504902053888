import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService } from '@capturum/api';
import { combineLatest, from, Observable } from 'rxjs';
import { FormBuilderConfigService } from '@features/client/form-builder/form-builder.config';
import { FormBuilderModel } from '@features/client/form-builder/models/form-builder.model';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class FormBuilderApiService extends ApiService<any> {
  protected endpoint = '';

  constructor(
    apiHttpService: ApiHttpService,
    private readonly formBuilderConfigService: FormBuilderConfigService,
  ) {
    super(apiHttpService);
  }

  public resolveFormBuilders(): Observable<any> {
    return combineLatest([
      from(FormBuilderModel?.query().clear()),
      this.apiHttp.get<ApiIndexResult<any>>(this.formBuilderConfigService.formBuilderApi),
    ]).pipe(
      switchMap((response: any[]) => from(FormBuilderModel?.query().bulkPut(response[1].data))),
    );
  }

  public getFormBuilderByKey(key: string): Observable<any> {
    return from(FormBuilderModel?.query().where({ key }).first());
  }
}
