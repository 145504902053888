import { Action, State, StateContext } from '@node_modules/@ngxs/store';
import { WorkflowManagerStateModel } from '@features/client/workflow-manager/state/workflow-manager-state.model';
import { SetActiveStep, SetSteps } from '@features/client/workflow-manager/state/workflow-manager.actions';
import { Injectable } from '@angular/core';

@State<WorkflowManagerStateModel>({ name: 'workflowManager' })
@Injectable()
export class WorkflowManagerState {
  @Action(SetSteps)
  public setSteps(ctx: StateContext<WorkflowManagerStateModel>, { steps }: SetSteps): void {
    ctx.patchState({ steps });
  }

  @Action(SetActiveStep)
  public setActiveStep(ctx: StateContext<WorkflowManagerStateModel>, { activeStep }: SetActiveStep): void {
    ctx.patchState({ activeStep });
  }
}
