import { ApiHttpService, ApiService } from '@capturum/api';
import { Injectable } from '@angular/core';
import { CapturumFile } from '@shared/modules/uploader/models/file.model';

@Injectable()
export class DownloadFileService extends ApiService<Blob> {
  protected endpoint: string = 'file';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public downloadFile(file: CapturumFile): void {
    const anchor = document.createElement('a');

    anchor.target = '_blank';
    anchor.style.display = 'none';
    anchor.href = file.public_url;
    anchor.download = file.filename;

    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
}
