import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { CheckboxInputTypeBuilder } from '@features/client/form-builder/builders/checkbox-input-type.builder';
import { CheckboxListInputTypeBuilder } from '@features/client/form-builder/builders/checkbox-list-input-type.builder';
import { TextInputTypeBuilder } from '@features/client/form-builder/builders/text-input-type.builder';
import { TextareaInputTypeBuilder } from '@features/client/form-builder/builders/textarea-input-type.builder';
import { LabelInputTypeBuilder } from '@features/client/form-builder/builders/label-input-type.builder';
import { EmailInputTypeBuilder } from '@features/client/form-builder/builders/email-input-type.builder';
import { ImageInputTypeBuilder } from '@features/client/form-builder/builders/image-input-type.builder';
import { NumberInputTypeBuilder } from '@features/client/form-builder/builders/number-input-type.builder';
import { DateInputTypeBuilder } from '@features/client/form-builder/builders/date-input-type.builder';
import { RadioInputTypeBuilder } from '@features/client/form-builder/builders/radio-input-type.builder';
import { DropdownInputTypeBuilder } from '@features/client/form-builder/builders/dropdown-input-type.builder';
import { CustomInputTypeBuilder } from '@features/client/form-builder/builders/custom-input-type.builder';
import { FileInputTypeBuilder } from '@features/client/form-builder/builders/file-input-type.builder';
import { ColorSystemInputTypeBuilder } from '@features/client/form-builder/builders/color-system-input-type.builder';
import { ImplantCustomInputTypeBuilder } from '@features/client/form-builder/builders/implant-custom-input-type.builder';
import { CalendarInputTypeBuilder } from '@features/client/form-builder/builders/calendar-input-type.builder';
import { CustomButtonInputTypeBuilder } from '@features/client/form-builder/builders/custom-button-input-type.builder';

export const inputTypeBuilders = {
  [InputType.checkbox]: CheckboxInputTypeBuilder,
  [InputType.checkboxList]: CheckboxListInputTypeBuilder,
  [InputType.text]: TextInputTypeBuilder,
  [InputType.textarea]: TextareaInputTypeBuilder,
  [InputType.label]: LabelInputTypeBuilder,
  [InputType.email]: EmailInputTypeBuilder,
  [InputType.image]: ImageInputTypeBuilder,
  [InputType.number]: NumberInputTypeBuilder,
  [InputType.date]: DateInputTypeBuilder,
  [InputType.radio]: RadioInputTypeBuilder,
  [InputType.dropdown]: DropdownInputTypeBuilder,
  [InputType.custom]: CustomInputTypeBuilder,
  [InputType.file]: FileInputTypeBuilder,
  [InputType.colorSystem]: ColorSystemInputTypeBuilder,
  [InputType.implantCustom]: ImplantCustomInputTypeBuilder,
  [InputType.calendar]: CalendarInputTypeBuilder,
  [InputType.customButton]: CustomButtonInputTypeBuilder,
};
