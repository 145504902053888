import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@capturum/auth';
import { ValidatorService } from '@capturum/ui/api';
import { BaseDataService } from '@core/services/base-data.service';
import { DependencyOperator } from '@features/client/form-builder/enums/dependency-operator.enum';
import { InputConfiguration } from '@features/client/form-builder/models/input-configuration.model';
import { SetValue } from '@features/client/form-builder/state/form-builder.actions';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Store } from '@node_modules/@ngxs/store';
import { get, isArray, isEmpty, isNil } from 'lodash';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { delay, distinctUntilChanged, first, map, startWith, switchMap } from 'rxjs/operators';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

export interface DependencyPart {
  field: string;
  values: string[];
  control: FormControl;
}

@Injectable()
export class FormInputHelperService implements OnDestroy {
  private subscription: Subscription = new Subscription();
  private inputsConfiguration: any = {};

  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly baseDataService: BaseDataService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
    private readonly validatorService: ValidatorService,
  ) {
    this.setDefaultStateValues();
    this.addCustomTranslation();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();

    this.inputsConfiguration = {};
  }

  public handleOptionsSourceSets(field: FormlyFieldConfig, configuration: any): void {
    this.subscription.add(
      field.formControl.valueChanges.pipe(
        startWith(field.formControl.value),
        switchMap((fieldValue) => field.templateOptions.options$.asObservable()
          .pipe(
            map((fieldOptions) => ({ fieldValue, fieldOptions })),
          ),
        ),
      ).subscribe(({ fieldValue, fieldOptions }) => {
        this.setValueByKey(
          configuration.sets,
          fieldOptions.find(item => item[configuration.options.source.value_key] === fieldValue) ?? fieldValue,
        );
      }),
    );
  }

  public resolveDependencies(
    field: FormlyFieldConfig,
    dependencies: string[][],
    dependencyOperator: DependencyOperator,
    configuration?: InputConfiguration,
    options$?: Observable<any>,
    input?: FormlyFieldConfig,
  ): void {
    const dependencyParts = this.getDependencyParts(dependencies, field.form);

    this.subscription.add(
      combineLatest(this.getDependenciesSource(dependencyParts)).pipe(
      ).subscribe((data: boolean[]) => {
        const requirementsMet = (dependencyOperator === DependencyOperator.and)
          ? data.every((requirementMet) => requirementMet === true)
          : data.some((requirementMet) => requirementMet === true);

        field.hide = !requirementsMet;

        if (!requirementsMet) {
          field.formControl.setValue(null, { emitEvent: false });
        }

        // Assign the default value.
        if (!field.hide) {
          // Get the options of the dropdown if they have any.
          if (options$) {
            options$.pipe(first()).subscribe((options) => {
              this.setDefaultOption(field, options);

              input.templateOptions.options$.next(options);
            });
          }
        }
      }),
    );
  }

  public getValidations(validations: string[]): {} {
    const validators = {};

    if (!isEmpty(validations) && validations.length) {
      validations.forEach(validation => {
        if (validation === 'required') {
          validators['required'] = true;
        } else if (validation.startsWith('max')) {
          validators['maxLength'] = validation.split(':')[1];
        }
      });
    }

    return validators;
  }

  /*
   * Handle attribute fields that have a 'required_if' validation property (e.g. has_courier checkbox in the "Submit
   * impression & Patient return date" tab of the accordion).
   */
  public setCustomValidations(validations: any, field: FormlyFieldConfig): void {
    if (validations?.hasOwnProperty('required_if')) {
      combineLatest([
        ...this.getDependencyParts([validations['required_if']], field.form).map(
          (dependencyGroup: DependencyPart[]) => this.getDependencyListeners(dependencyGroup),
        ),
        field.formControl.valueChanges.pipe(
          startWith(field.formControl.value),
        ),
      ]).pipe(
        delay(0), // @TODO: Figure out why we need a delay(0) every time we use a formValueChanges in a combineLatest
      ).subscribe(([hasEmptyValues, changedValue]: [boolean[], any]) => {
        if (hasEmptyValues.length && hasEmptyValues.every((value) => value) && !changedValue) {
          field.formControl?.setErrors({ required: true });
        } else {
          field.formControl?.clearValidators();
          field.formControl?.updateValueAndValidity({ onlySelf: false, emitEvent: false });
        }
      });
    }
  }

  public getLabelKeyBySource(source: any): string {
    return source?.type === 'base_data' ? 'label' : source?.label_key;
  }

  public getValueKeyBySource(source: any): string {
    return source?.type === 'base_data' ? 'value' : source?.value_key;
  }

  public setValueByKey(key: string, value: any): void {
    this.store.dispatch(new SetValue(key, value));
  }

  public getValueByKeyOrDefault(key: string, defaultValue: any = null): any {
    const formBuilderState = this.store.selectSnapshot(state => state.formBuilder);
    const value = get(formBuilderState, key);

    return isNil(value) ? defaultValue : value;
  }

  public getValueAsArrayByKeyOrDefault(key: string, defaultValue: any = null): any {
    const formBuilderState = this.store.selectSnapshot(state => state.formBuilder);
    let value = get(formBuilderState, key);

    if (!isNil(value)) {
      value = isArray(value) ? value : [value];
    }

    return isNil(value) ? defaultValue : value;
  }

  public getOptionsWithImageKey(options: any[], imageKey: string): any[] {
    if (imageKey) {
      options = options.map(option => ({
        ...option,
        url: `assets/images/form-builder${imageKey}.png`,
      }));
    }

    return options;
  }

  public setInputConfiguration(name: string, configuration: any): void {
    this.inputsConfiguration[name] = configuration;
  }

  private setDefaultStateValues(): void {
    this.store.dispatch([
      new SetValue('currentUser', this.authService.getUser()),
    ]);
  }

  private addCustomTranslation(): void {
    this.validatorService.setValidationMessages({
      maxlength: 'dental.validation.max.text',
    });
  }

  private getDependencyParts(dependencyGroups: string[][], form: FormGroup): DependencyPart[][] {
    return dependencyGroups.map((dependencyGroup: string[]) => {
      return dependencyGroup.map((dependency: string) => {
        return this.getDependencyPart(dependency, form);
      });
    });
  }

  private getDependencyPart(dependency: string, form: FormGroup): DependencyPart {
    let field, values;

    if (dependency.includes(':')) {
      const dependencyParts = dependency.split(':');

      field = dependencyParts[0];
      values = dependencyParts[1].split(',');
    } else {
      field = dependency;
    }

    return { field, values, control: form.get(field) as FormControl } as DependencyPart;
  }

  private getDependenciesSource(dependencyGroups: DependencyPart[][]): Observable<any>[] {
    return !!dependencyGroups?.length ? dependencyGroups.map((dependencyGroup: DependencyPart[]) => {
      return this.getDependencyListeners(dependencyGroup).pipe(
        map((conditions: boolean[]) => {
          return conditions.every(conditionIsMet => conditionIsMet);
        }),
      );
    }) : null;
  }

  private getDependencyListeners(dependencyGroup: DependencyPart[]): Observable<any> {
    return combineLatest(
      dependencyGroup.map((dependencyPart: DependencyPart) => {
        return dependencyPart.control?.valueChanges.pipe(
          startWith(dependencyPart.control.value),
          distinctUntilChanged(),
          switchMap(value => this.transformBaseDataValues(value, dependencyPart)),
          map((value: string) => this.isConditionMet(value, dependencyPart)),
        );
      }),
    );
  }

  private transformBaseDataValues(value: any, dependency: DependencyPart): Observable<any> {
    const isFieldBaseDataValue = this.inputsConfiguration[dependency.field]?.templateOptions?.is_base_data;
    const isFieldAttributeBaseDataValue = this.inputsConfiguration[dependency.field]?.templateOptions?.is_attribute_base_data;

    if (value && (isFieldBaseDataValue || isFieldAttributeBaseDataValue)) {
      const service = isFieldBaseDataValue ? this.baseDataService : this.attributeBaseDataService;

      return service.getById(value).pipe(
        map(baseDataItem => baseDataItem.value),
      );
    } else {
      return of(value);
    }
  }

  private isConditionMet(value: any, dependency: DependencyPart): boolean {
    if (dependency.values?.includes('empty') && this.checkIfValueIsEmpty(value)) {
      return true;
    } else if (dependency.values?.length) {
      return dependency.values.includes(value);
    } else {
      return !this.checkIfValueIsEmpty(value) && !isNil(value);
    }
  }

  private checkIfValueIsEmpty(value: any): boolean {
    return isNil(value)
      || (typeof value === 'boolean' && value === false)
      || (typeof value === 'string' && value === '')
      || (isArray(value) && !value.length);
  }

  private setDefaultOption(field: FormlyFieldConfig, options: any[]): void {
    const defaultOption = options.find(option => option.is_default);
    let defaultValue = get(defaultOption, field.templateOptions.value_key);

    if (isEmpty(defaultValue)) {
      defaultValue = null;
    }

    if (isNil(field.defaultValue) && !isNil(defaultValue)) {
      field.formControl.setValue(defaultValue);
    }
  }
}
