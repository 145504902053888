import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseDataService } from '@core/services/base-data.service';

@Injectable()
export class DentalBaseDataResolver implements Resolve<Observable<boolean>> {
  constructor(private baseDataService: BaseDataService) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.baseDataService.loadBaseDataInMap();
  }
}
