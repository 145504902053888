import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent {
  @Input() public savedSelectedDay: Date | string = new Date();
  @Input() public daysOfWeek: string[];
  @Input() public weekNumbers: number[];
  @Input() public currentDate: Date = new Date();
  @Input() public selectedMonth: Date = new Date();
  @Input() public daysOfMonths: any = [];
  @Input() public activeDay: Date;
  @Output() public closeDatepicker: EventEmitter<void> = new EventEmitter<void>();
  @Output() public selectDate: EventEmitter<Date> = new EventEmitter<Date>();
  @Output() public selectMonth: EventEmitter<boolean> = new EventEmitter<boolean>();

  public selectedDay: Date = new Date();

  public selectAvailableDate(date: any, available: boolean): void {
    if (available) {
      this.selectedDay = date;
    }
  }

  public saveSelectedDay(): void {
    this.savedSelectedDay = this.selectedDay;

    this.closeDatepicker.emit();
    this.selectDate.emit(this.savedSelectedDay);
  }
}
