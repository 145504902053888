import { Injectable } from '@angular/core';
import { BatchStatusAction, BatchStatusActionProvider } from '@capturum/complete';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DownloadUrlAction implements BatchStatusActionProvider {
  constructor(private http: HttpClient) {
  }

  public execute(action: BatchStatusAction): void {
    const headers = new HttpHeaders();
    headers.delete('Content-Type');

    if (Array.isArray(action?.action) && action?.action?.length) {
      const requests = action.action.map((actionInfo) => {
        return this.http.get(actionInfo.url, { headers, responseType: 'blob', }).pipe(
          map((file) => {
            return {
              file,
              filename: actionInfo.filename,
            };
          })
        );
      });

      forkJoin<{filename: string, file: Blob}[]>(requests)
        .pipe(first())
        .subscribe((files) => {
        files.forEach((file) => {
          saveAs(file.file, file.filename);
        });
      });
    } else if (action?.action?.url){
      this.http.get(action.action.url, { headers, responseType: 'blob', }).pipe(
        map((file) => {
          return {
            file,
            filename: action.action.filename,
          };
        })
      ).subscribe(file => {
        saveAs(file.file, file.filename);
      });
    }
  }
}
