import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowManager } from '@features/client/workflow-manager/interfaces/workflow-manager';
import { WorkflowApiService } from '@features/client/workflow-manager/services/workflow-api.service';
import { WorkflowManagerConfigService } from '@features/client/workflow-manager/workflow-manager.config';
import { Step } from '@features/client/workflow-manager/models/step.model';
import { Store } from '@node_modules/@ngxs/store';
import { SetActiveStep, SetSteps } from '@features/client/workflow-manager/state/workflow-manager.actions';
import { WorkflowManagerSelectors } from '@features/client/workflow-manager/state/workflow-manager.selectors';
import { tap } from 'rxjs/operators';

@Injectable()
export class WorkflowManagerService implements WorkflowManager {
  constructor(
    private readonly workflowApiService: WorkflowApiService,
    private readonly workflowManagerConfigService: WorkflowManagerConfigService,
    private readonly store: Store,
  ) {
  }

  public fetchSteps(): Observable<Step[]> {
    return this.workflowApiService.getStepsByName(this.workflowManagerConfigService.key)
      .pipe(
        tap((steps: Step[]) => this.setStepKeys(steps)),
      );
  }

  public setActiveStep(step: string): void {
    this.store.dispatch(new SetActiveStep(step));
  }

  public getActiveStep(): Observable<string> {
    return this.store.select(WorkflowManagerSelectors.getActiveStep);
  }

  private setStepKeys(steps: Step[]): void {
    const stepKeys = steps.map((step: Step) => step.step_key);

    this.store.dispatch(new SetSteps(stepKeys));

    this.setActiveStep(stepKeys[0]);
  }
}
