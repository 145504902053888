import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@capturum/auth';
import { Router } from '@angular/router';
import { filter, first, switchMap } from 'rxjs/operators';
import { AppRoutes } from '@core/enums/routes.enum';
import { NotificationItem } from '@capturum/ui/api';
import { MockNotificationService } from '@core/services/mock-notification.service';
import * as moment from 'moment';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { DatesFormats } from '@core/configs/date-config';
import { IosApiService } from '@features/client/order-workflow/services/ios-api.service';
import { forkJoin, of } from 'rxjs';
import { StorageService } from '@core/services/storage.service';
import { Store } from '@ngxs/store';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import { environment } from '@environments/environment';
import { ExternalSystem } from '@features/client/order-workflow/enums/external-system.enum';
import { SettingService } from '@capturum/complete';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  @Input() public hasShadow: boolean;

  public showSidebar: boolean = false;
  public showNavigation: boolean = false;
  public notifications: NotificationItem[];
  public translations: { today: string, yesterday: string } = {
    today: this.translateService.instant('calendar-locale.today'),
    yesterday: this.translateService.instant('dental.calendar-locale.yesterday'),
  };

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly mockNotificationService: MockNotificationService,
    private readonly translateService: TranslateService,
    private readonly iosService: IosApiService,
    private readonly storageService: StorageService,
    private readonly store: Store,
    private readonly settingService: SettingService,
  ) {
  }

  public ngOnInit(): void {
    this.getNotifications();
  }

  public toggleNotifications(toggle: boolean): void {
    setTimeout(() => {
      this.showSidebar = toggle;
    }, 300);
  }

  public toggleNavigation(): void {
    this.showNavigation = !this.showNavigation;
  }

  public handleLogoutClick(): void {
    const email = this.storageService.get('user')?.email;
    const externalSystems = this.store.selectSnapshot(OrderWorkflowSelectors.externalAuthStatus)?.filter(system => system?.success) || [];
    const hasThreeShape = externalSystems.some(system => system.systemType === ExternalSystem.threeShape);
    const redirectUrl = 'https://' + this.settingService.getValueString('general.tenant_host');

    if (!!!externalSystems) {
      this.router.navigateByUrl(AppRoutes.login);
    }

    this.iosService.checkAuth(ExternalSystem.threeShape, { email }).subscribe(result => {
      forkJoin([...externalSystems.map(system => this.iosService.logout(system.systemType, { email })), of(true)])
        .pipe(switchMap(() => this.authService.logout()), filter(Boolean), first()).subscribe(() => {
        if (hasThreeShape && environment.threeShapeLogoutUrl) {
          const threeShapeLogoutUrl = environment.threeShapeLogoutUrl;
          const idToken = result.id_token;

          window.location.href = `${threeShapeLogoutUrl}?id_token_hint=${idToken}&post_logout_redirect_uri=${encodeURIComponent(redirectUrl)}`;
        } else {
          this.router.navigateByUrl(AppRoutes.login);
        }
      });
    }, error => {
      this.router.navigateByUrl(AppRoutes.login);
    });
  }

  private getNotifications(): void {
    this.mockNotificationService.mockNotifications.subscribe(notification => {
      this.notifications = notification.map((value) => {
        return {
          title: value.title,
          subTitle: moment(value.created_at).format(DatesFormats.notificationFormat),
          body: value.message,
          bodyIsCollapsable: false,
          read: true,
          date: new Date(value.created_at),
          icon: value.type === 'info' ? 'fas fa-bell' : value.type === 'success' ? 'fas fa-flag' : 'fas fa-exclamation-triangle',
        };
      });
    });
  }
}
