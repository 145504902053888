<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <cap-textarea [formControl]="formControl"
                [label]="to.label"
                [hasTooltip]="to.tooltip"
                [id]="key"
                [maxlength]="to?.maxLength"
                [placeholder]="to.placeholder">
    <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
  </cap-textarea>
</div>
