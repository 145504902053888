<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <label *ngIf="to?.label">
    <span>{{ to.label }}</span>
  </label>

  <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
</div>

<ng-container *ngIf="to.options$.asObservable() | async as options">
  <p *ngFor="let option of options">{{ getValue(option, to.label_key) }}</p>
</ng-container>
