import { Injectable } from '@angular/core';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { ImageInputConfiguration } from '@features/client/form-builder/models/image-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { FileInputTypeBuilder } from '@features/client/form-builder/builders/file-input-type.builder';

@Injectable()
export class ImageInputTypeBuilder extends FileInputTypeBuilder implements FormInputConfig<ImageInputConfiguration> {
  protected inputType: string = InputType.image;
}
