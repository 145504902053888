import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';

@Component({
  selector: 'app-dropdown-image',
  templateUrl: './dropdown-image.component.html',
  styleUrls: ['./dropdown-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownImageComponent,
    },
  ],
})
export class DropdownImageComponent implements ControlValueAccessor {
  @Input() public options: BaseDataValueMapItem[];
  @Input() public label: string;
  @Input() public styleClass: string;
  @Input() public imagePath: string;
  @Input() public imageExtension: string = '.png';

  public selectedOption: BaseDataValueMapItem;
  public _selectedOptionId: string;

  public onChange = (id: string) => {
  }

  public onTouched = () => {
  }

  public writeValue(value: string): void {
    this.selectedOptionId = value;
  }

  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public get selectedOptionId(): string {
    return this._selectedOptionId;
  }

  public set selectedOptionId(id: string) {
    this.selectedOption = this.options.find(item => item.value === id);
    this._selectedOptionId = this.selectedOption?.value;

    this.onChange(this.selectedOptionId);
  }
}
