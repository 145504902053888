<cap-dropdown [options]="options"
              [label]="label"
              [autoDisplayFirst]="false"
              [styleClass]="styleClass"
              [(ngModel)]="selectedOptionId">
  <ng-template capTemplate="selectedItem" let-item>
    <ng-container *ngIf="selectedOption">
      <div class="d-flex align-items-center cap-dropdown-header">
        <img [src]="imagePath + selectedOption?.value_key + imageExtension | assets: 'images'" alt="" />

        <span>{{ selectedOption?.label }}</span>
      </div>
    </ng-container>
  </ng-template>

  <ng-template let-color capTemplate="item">
    <div class="d-flex align-items-center justify-content-between cap-dropdown-item">
      <span>{{ color.label }}</span>

      <img [src]="imagePath + color.value_key + imageExtension | assets: 'images'" alt="" />
    </div>
  </ng-template>
</cap-dropdown>
