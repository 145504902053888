import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { OrderProduct } from '@features/client/order-workflow/models/order-product.model';
import { Observable } from 'rxjs';
import { Order } from '@features/client/order-workflow/models/order.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class OrderProductApiService extends ApiService<OrderProduct> {
  protected endpoint: string = 'order-product';

  public get url(): string {
    return environment.baseUrl;
  }

  constructor(
    public apiHttp: ApiHttpService,
    private readonly http: HttpClient,
  ) {
    super(apiHttp);
  }

  public toggleConnection(id: string, side: string = null): Observable<Order> {
    let url = `/${this.endpoint}/${id}/toggle-connection`;

    if (side) {
      url += `/${side}`;
    }

    return this.apiHttp.post(url, {}).pipe(map((response: { data: Order }) => response.data));
  }

  public deleteByBatch(id: number | string, body: number[]): Observable<any> {
    const httpOptions = {
      headers: this.apiHttp.getHeaders(),
      body: { batch: body },
    };

    return this.http.delete<any>(`${this.url}/${this.endpoint}/${id}`, httpOptions);
  }
}
