import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
})
export class RadioInputComponent extends FieldType implements OnInit {
  public selectedValue: any = null;

  public ngOnInit(): void {
    this.selectedValue = this.formControl.value;
  }
}
