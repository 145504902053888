<div *ngIf="!!(notificationService.getNotification() | async)?.length"
     class="notification-area"
     @slideNotification>
  <div *ngFor="let notification of notifications; let i = index"
       @fromTopAnimation
       class="notification"
       [ngClass]="{
            'error': notification?.type === notificationType.error,
            'pending': notification?.type === notificationType.pending
          }">
    <div class="notification__body">
      <div class="notification__body__icon">
        <i class="fas fa-check-circle" [ngClass]="{
            'fa-times': notification?.type === notificationType.error,
            'fa-spinner-third': notification?.type === notificationType.pending
          }">
        </i>
      </div>

      <div class="notification__body__text">
        <h3>{{ notification?.title }}</h3>
        <p>{{ notification?.description }}</p>
      </div>

      <div class="notification__body__close">
        <i class="fal fa-times" (click)="closeNotification(notification.type, i)"></i>
      </div>
    </div>
  </div>
</div>
