import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import { isNil } from 'lodash';
import { WorkflowManagerService } from '@features/client/workflow-manager/services/workflow-manager.service';
import { LoadingService } from '@core/services/loading.service';
import { OrderApiService } from '@features/client/order-workflow/services/order-api.service';
import { Step } from '@features/client/workflow-manager/models/step.model';
import { Order } from '@features/client/order-workflow/models/order.model';
import { NotificationService } from '@core/services/notification.service';
import { ListOptions } from '@capturum/api';
import { ToothCrossService } from '@features/client/order-workflow/services/tooth-cross.service';
import { ToothCross } from '@features/client/order-workflow/models/tooth.model';
import { Store } from '@node_modules/@ngxs/store';
import { OpenChat, SetOrder } from '@features/client/order-workflow/state/order-workflow.actions';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import { AccordionService } from '@shared/modules/accordion/services/accordion.service';
import { OrderWorkflowService } from '@features/client/order-workflow/services/order-workflow.service';
import { BaseDataService } from '@core/services/base-data.service';
import { MapItem } from '@capturum/ui/api';
import { Accordion } from '@shared/modules/accordion/models/accordion.model';
import { TranslateService } from '@ngx-translate/core';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { AccordionLoadingService } from '@shared/modules/accordion/services/accordion-loading.service';
import { OrderWorkflowStep } from '@features/client/order-workflow/enums/order-workflow-step.enum';
import { FormBuilderService } from '@features/client/form-builder/services/form-builder.service';
import { OrderUpdateMethod } from '@features/client/order-workflow/enums/order-update-method.enum';
import { NotificationType } from '@shared/components/notification/notifications.enum';
import { IosApiService } from '@features/client/order-workflow/services/ios-api.service';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';
import { CaseScan } from '@features/client/order-workflow/models/case-scan.model';

@Injectable()
export class OrderWorkflowFacade {
  private checkIosDownloadStatus$: Subject<void> = new Subject<void>();

  constructor(
    private readonly workflowManagerService: WorkflowManagerService,
    private readonly loadingService: LoadingService,
    private readonly orderApiService: OrderApiService,
    private readonly notificationService: NotificationService,
    private readonly toothCrossService: ToothCrossService,
    private readonly store: Store,
    private readonly accordionService: AccordionService,
    private readonly orderWorkflowService: OrderWorkflowService,
    private readonly baseDataService: BaseDataService,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly accordionLoadingService: AccordionLoadingService,
    private readonly formBuilderService: FormBuilderService,
    private readonly iosApiService: IosApiService,
  ) {
  }

  public setOrderId(id: string): void {
    id = id !== 'new' ? id : null;

    this.store.dispatch(new SetOrder({ id }));
  }

  public getWorkflow(): Observable<Accordion[]> {
    return this.workflowManagerService.fetchSteps().pipe(
      map((steps: Step[]) => steps.map((step: Step) => ({
        title: this.translateService.instant(`${ModuleKeys.dental}.workflow.order.${step.step_key}`),
        id: step.step_key,
      }))),
    );
  }

  public toggleLoader(show: boolean): void {
    this.accordionLoadingService.setLoading(show);
  }

  public isAccordionLoading(): Observable<boolean> {
    return this.accordionLoadingService.isLoading();
  }

  public toggleGlobalLoader(show: boolean): void {
    this.loadingService.setLoading(show);
  }

  public getOrderStep(): Observable<string> {
    return this.store.select(OrderWorkflowSelectors.getOrder).pipe(
      distinctUntilKeyChanged('current_workflow_step_key'),
      switchMap(({ id, current_workflow_step_key: step }: Order) => {
        if (step) {
          return of(step);
        } else if (id) {
          return this.orderApiService.get(id).pipe(
            map((order: Order) => {
              this.store.dispatch(new SetOrder(order));

              return order.current_workflow_step_key || OrderWorkflowStep.summary;
            }),
          );
        } else {
          return this.getActiveStep();
        }
      }),
    );
  }

  public getActiveStep(): Observable<string> {
    return this.workflowManagerService.getActiveStep()
      .pipe(
        filter((step: string) => !isNil(step)),
        distinctUntilChanged(),
      );
  }

  public setActiveStep(step: string): void {
    this.workflowManagerService.setActiveStep(step);
    this.accordionService.setActiveTab(step);
  }

  public createOrder(data: Order, options: ListOptions = {}): Observable<Order> {
    return this.orderApiService.create(data, options)
      .pipe(
        tap((order: Order) => {
          this.setActiveStep(order.current_workflow_step_key);
          this.store.dispatch(new SetOrder(order));
          this.redirectToNewOrder(order.id);
        }),
      );
  }

  public continueOrder(id: string, data: Order, options: ListOptions = {}): Observable<Order> {
    this.formBuilderService.hideValidationMessages(false);

    return this.workflowManagerService.getActiveStep().pipe(
      first(),
      switchMap((activeStep: string) => this.updateOrder(id, { ...data, id }, options)),
    );
  }

  public updateOrder(id: string, data: Order, options: ListOptions = {}, method: string = OrderUpdateMethod.continue): Observable<Order> {
    let currentWorkflowStepKey: string = data.workflow_step_key;

    return (this.orderApiService[method]({ ...data, id }, options) as Observable<Order>)
      .pipe(
        tap((order: Order) => {
          if (method === OrderUpdateMethod.continue) {
            currentWorkflowStepKey = order.current_workflow_step_key;
          }

          this.setActiveStep(currentWorkflowStepKey);
          this.store.dispatch(new SetOrder(order));
        }),
      );
  }

  public patchOrder(id: string, data: Order, options: ListOptions = {}): Observable<Order> {
    return this.orderApiService.updatePatch({ ...data, id }, options);
  }

  public placeOrder(id: string, data: Order): Observable<Order> {
    return this.orderApiService.place(id, data);
  }

  public getOrder(id: string, options: ListOptions = {}): Observable<Order> {
    return this.orderApiService.get(id, options);
  }

  public clearToothCross(id: string): Observable<Order> {
    this.toothCrossService.clearSelectedTeeth();

    return this.orderApiService.clearToothCross(id);
  }

  public getOrderStatuses(): Observable<MapItem[]> {
    return this.baseDataService.getBaseDataValuesByKeyAndAttribute('order_status', 'is_manageable', true);
  }

  public showSuccessNotification(order: Order): void {
    const description: string = this.translateService.instant(`${ModuleKeys.dental}.order.notification.success.description`);

    this.notificationService.showNotification({
      type: NotificationType.success,
      title: this.translateService.instant(`${ModuleKeys.dental}.order.notification.success.title`, { order: order.order_number }),
      description,
    });
  }

  public setToothCross(toothCross: ToothCross): void {
    this.toothCrossService.setToothCross(toothCross);
  }

  public getOrderSnapshot(): Order {
    return this.store.selectSnapshot(OrderWorkflowSelectors.getOrder);
  }

  public processStep(nextStep: OrderWorkflowStep = null): void {
    this.formBuilderService.hideValidationMessages(false);
    this.orderWorkflowService.setProcessStep(nextStep);
  }

  public printLabel(): Observable<Blob> {
    const { id }: Order = this.getOrderSnapshot();

    return this.orderApiService.getPdf(id).pipe(first(), map((blob: Blob) => new Blob([blob], { type: 'application/pdf' })));
  }

  public printSummary(): Observable<Blob> {
    const { id }: Order = this.getOrderSnapshot();

    return this.orderApiService.getPdf(id).pipe(first(), map((blob: Blob) => new Blob([blob], { type: 'application/pdf' })));
  }

  public sendMessage(id: string, message: string): Observable<Order> {
    return this.orderApiService.sendMessage(id, message);
  }

  public markAsRead(id: string): Observable<Order> {
    return this.orderApiService.markAsRead(id);
  }

  public openChat(): Observable<boolean> {
    return this.store.select(OrderWorkflowSelectors.openChat)
      .pipe(filter(Boolean), map(result => result['openChat']));
  }

  public hideChat(): void {
    this.store.dispatch(new OpenChat(false));
  }

  public checkAuth(systemType: string, body: { email: string }): Observable<ExternalAuthStatus> {
    return this.iosApiService.checkAuth(systemType, body);
  }

  public login(systemType: string, body: ExternalAuthStatus): Observable<ExternalAuthStatus> {
    return this.iosApiService.login(systemType, body);
  }

  public getScans(systemType: string, options: ListOptions, body: ExternalAuthStatus): Observable<{ data: CaseScan[], meta: any }> {
    return this.iosApiService.getScans(systemType, options, body);
  }

  public downloadScans(systemType: string, body: ExternalAuthStatus): Observable<Record<string, string | boolean>> {
    return this.iosApiService.downloadScans(systemType, body);
  }

  public logout(systemType: string, body: { email: string }): Observable<Record<string, string | boolean>> {
    return this.iosApiService.logout(systemType, body);
  }

  public sendAuthCode(systemType: string, body: ExternalAuthStatus): Observable<ExternalAuthStatus> {
    return this.iosApiService.sendAuthCode(systemType, body);
  }

  public sendRefreshToken(systemType: string, body: ExternalAuthStatus): Observable<any> {
    return this.iosApiService.sendRefreshToken(systemType, body);
  }

  public getDownloadStatus(): Observable<{ cases_in_queue: number }> {
    const orderId = this.getOrderSnapshot()?.id;

    return this.orderApiService.getDownloadStatus(orderId);
  }

  public emitDownloadStatusMethod(): void {
    this.checkIosDownloadStatus$.next();
  }

  public callDownloadStatusMethod(): Observable<void> {
    return this.checkIosDownloadStatus$.asObservable();
  }

  private redirectToNewOrder(id: string): void {
    this.router.navigate(['/', AppRoutes.client, AppRoutes.orders, id]);
  }
}
