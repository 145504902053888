<div class="form-element image-uploader">
  <div class="d-flex justify-content-between align-items-center">
    <label>{{ label }}</label>

    <div *ngIf="tooltip" class="tooltip-content">
      <i class="fas fa-info-circle"></i>

      <app-tooltip position="tr">
        <p>{{ tooltip }}</p>
      </app-tooltip>
    </div>
  </div>

  <cap-file-upload *ngIf="!disabled && (control?.value?.length <= (maxCount - 1))"
                   #capFileUpload
                   styleClass="g-uploader no-print"
                   [id]="getControlName()"
                   [label]="''"
                   [multiple]="multiple"
                   [accept]="fileExtensions"
                   [uploaderContent]="uploadContent"
                   (onFileChange)="addFile($event)">
  </cap-file-upload>

  <div class="preview--section" *ngIf="control?.value?.length">
    <div class="avatar-wrapper">
      <div class="avatar-image" [style.background-image]="'url(' + control?.value[0].public_url + ')'"></div>

      <div class="avatar-actions">
        <i class="fas fa-trash-alt avatar--remove no-print"
           (click)="deleteFile(control?.value[0])"
           [attr.title]="'button.delete' | translate"></i>
      </div>
    </div>
  </div>

  <ng-template #uploadContent>
    <div *ngIf="!disabled" class="upload">
      <i class="fas fa-cloud-upload-alt"></i>

      <p [innerHTML]="description ? description : label"></p>
    </div>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>

<p-confirmDialog #confirmDialog [header]="label" [closable]="false">
  <p-footer>
    <cap-button [label]="'general.yes' | translate"
                styleClass="primary"
                (click)="confirmDialog.accept()">
    </cap-button>
  </p-footer>
</p-confirmDialog>
