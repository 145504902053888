import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-email-input',
  templateUrl: './email-input.component.html',
})
export class EmailInputComponent extends FieldType implements OnInit {
  public ngOnInit(): void {
    this.formControl.setValidators(Validators.email);
  }
}
