import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appManageValidatorDisplay]',
})
export class ManageValidatorDisplayDirective implements OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() private control: AbstractControl;

  constructor(
    public elementRef: ElementRef,
  ) {
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
