import { Injectable } from '@angular/core';
import {
  BaseDataApiModel,
  BaseDataService as CompleteBaseDataService,
  BaseDataValueApiModel,
  BaseDataValueIndexedDbModel,
} from '@capturum/complete';
import { forkJoin, from, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { map, tap } from 'rxjs/operators';
import { isEmpty, sortBy } from 'lodash';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { ApiHttpService } from '@capturum/api';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService extends CompleteBaseDataService {
  protected dataSet = new Map();
  protected baseDataMap = new Map<string, BaseDataApiModel>();
  protected baseDataPath: string = '/role/base-data';
  protected moduleKey: string = ModuleKeys.dental;

  constructor(apiHttp: ApiHttpService, protected translateService: TranslateService) {
    super(apiHttp);
  }

  public loadBaseData(): Observable<boolean> {
    return forkJoin([
      from(this.loadBaseDataByRole()),
    ]).pipe(map(() => true));
  }

  public listBaseData(key: string): Observable<BaseDataValueMapItem[]> {
    return this.getBaseDataValuesByKey(key).pipe(this.prepareBaseDataValues.bind(this));
  }

  public getBaseDataValuesByKeyAndAttribute(key: string, attributeKey: string = null, attributeValue: any = null): Observable<BaseDataValueMapItem[]> {
    return this.getBaseDataValuesByKey(key)
      .pipe(
        map((baseDataValues) => {
          const items = [];

          baseDataValues.forEach((baseDataValue) => {
            if (this.hasAttributeByKeyAndValue(baseDataValue, attributeKey, attributeValue) || (!attributeKey && !attributeValue)) {
              items.push({
                ...baseDataValue,
                ...this.convertBaseDataValueToMapItem({
                  id: baseDataValue.id,
                  value: baseDataValue.value,
                } as BaseDataValueApiModel),
              } as BaseDataValueMapItem);
            }
          });

          return sortBy(items, 'order');
        }),
      );
  }

  public getBaseDataValueTranslation(baseDataValue: BaseDataValueApiModel): string {
    return baseDataValue?.id ? this.translateService.instant(`base-data.${baseDataValue.id}`) : null;
  }

  public convertBaseDataValueToMapItem(baseDataValue: BaseDataValueApiModel): BaseDataValueMapItem {
    return {
      label: this.getBaseDataValueTranslation(baseDataValue),
      value: baseDataValue.id,
      value_key: baseDataValue.value,
    };
  }

  public getById(id: string): Observable<BaseDataValueMapItem> {
    const result = of(this.baseDataMap.get(id));

    return from(result).pipe(map((data: any) => ({ ...data, label: this.getBaseDataValueTranslation(data) })));
  }

  public loadBaseDataInMap(): Observable<boolean> {
    return this.apiHttp.get(this.baseDataPath).pipe(
      map((response: { data: BaseDataApiModel[] }) => {
        return response?.data?.map(baseDataValue => this.transformBaseDataValue(baseDataValue));
      }),
      tap((response) => {
        response?.forEach((baseDataValue) => {
          this.baseDataMap.set(baseDataValue?.id, baseDataValue);
        });
      }),
      map(() => true),
    );
  }

  public isLoaded(): boolean {
    return this.baseDataMap.size > 0;
  }

  public getBaseDataValuesByKey(key: string): Observable<BaseDataValueApiModel[]> {
    // @ts-ignore
    return of(
      this.getBaseDataArray()
        .filter((value) => value.base_data_key === `${this.moduleKey}.${key}`),
    ).pipe(
      map((baseDataValues) => sortBy(baseDataValues, 'order')),
    );
  }

  protected getBaseDataArray(): BaseDataApiModel[] {
    return [...this.baseDataMap.values()];
  }

  protected hasAttributeByKeyAndValue(baseDataValue: any, attributeKey: string, attributeValue: string): boolean {
    if (attributeKey && attributeValue && !isEmpty(baseDataValue.attributes)) {
      return !!baseDataValue.attributes.find(attribute => {
        if (attribute.attribute.data_type.type === 'boolean') {
          attribute.value = attribute.value === 'true' || attribute.value === true;
        }

        return attribute.attribute.key === attributeKey && attribute.value === attributeValue;
      });
    }

    return false;
  }

  protected prepareBaseDataValues(source: Observable<any>): Observable<BaseDataValueMapItem[]> {
    const self = this;

    return new Observable(subscriber => {
      source.subscribe({
        next(values: BaseDataValueIndexedDbModel[]): void {
          if (values) {
            /* @ts-ignore */
            subscriber.next(self.unflattenBaseDataValueChildren(values.map((baseDataValue: BaseDataValueApiModel) => {
              return {
                ...self.convertBaseDataValueToMapItem(baseDataValue),
                parent_id: baseDataValue.parent_id,
                attributes: baseDataValue.attributes,
              };
            })));
          } else {
            subscriber.next([]);
          }

          subscriber.complete();
        },
      });
    });
  }

  protected unflattenBaseDataValueChildren(list: BaseDataValueApiModel[]): BaseDataValueApiModel[] {
    const children = [];

    for (const item of list) {
      if (item.parent_id) {
        continue;
      }

      const groupItem = { ...item, children: [] };

      for (const subItem of list) {
        if (subItem.parent_id !== groupItem.value) {
          continue;
        }

        groupItem.children.push({ ...subItem });
      }

      children.push(groupItem);
    }

    return children;
  }
}
