<div class="color-system">
  <div *ngIf="openPart !== null" class="close-dropdown-overlay" (click)="closeColorPalette()"></div>

  <app-tooth-part [generalBase]="getColorByAttributeName(formGroup.get(ColorAttributes.base)?.value)"
                  [topBase]="getColorByAttributeName(formGroup.get(ColorAttributes.top)?.value)"
                  [middleBase]="getColorByAttributeName(formGroup.get(ColorAttributes.middle)?.value)"
                  [bottomBase]="getColorByAttributeName(formGroup.get(ColorAttributes.bottom)?.value)"
                  [partCount]="colorAttributeNames.length">
  </app-tooth-part>

  <div>
    <label>{{ 'dental.product_configuration.select_color.label' | translate }}*</label>

    <div *ngFor="let controlName of colorAttributeNames" class="cs-dropdown">
      <div [ngClass]="{
                   'top-arrow': openPart === controlName,
                   'invalid': formGroup.get(controlName).invalid && formGroup.get(controlName)?.touched
                 }"
           class="cs-dropdown__select"
           (click)="openColorPalette(controlName)">
        <ng-container *ngIf="getColorByAttributeName(formGroup.get(controlName).value) as color">
          <div [ngStyle]="{
                       'background-color': color?.color,
                       'width.px': 19,
                       'height.px': 19
                     }"
               class="tooth-color">
          </div>

          <span class="ml-2">{{ color?.label }}</span>
        </ng-container>
      </div>

      <div *ngIf="openPart === controlName" class="cs-dropdown__colors pb-0">
        <div *ngFor="let items of colors | keyvalue" class="cs-dropdown__colors__grid">
          <div *ngFor="let color of items.value"
               class="cs-dropdown__colors__grid__item"
               (click)="handleColorPaletteClick(color.id, controlName)">
            <div [ngStyle]="{ 'background-color': color?.color }"
                 [class.selected-color]="color?.id === formGroup.get(controlName).value"
                 class="tooth-color">
            </div>

            <p>{{ color?.label }}</p>
          </div>
        </div>
      </div>

      <i *ngIf="colorAttributeNames.length > 1"
         class="fas fa-trash ml-2"
         (click)="handleRemoveColor(controlName)"></i>
    </div>

    <p *ngIf="colorAttributeNames.length < 3" class="toggle-extra-colors" (click)="handleAddColor()">
      <span>
        <i class="fas fa-plus mr-2"></i>

        {{ 'dental.product_configuration.add_extra_colors.label' | translate }}
      </span>
    </p>
  </div>
</div>
