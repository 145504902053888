import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Product,
  ProductCategoryGroupNode,
  ProductGroupMapItem,
} from '@features/client/order-workflow/models/product.model';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Injectable()
export class ProductCategoryApiService extends ApiService<any> {
  protected endpoint: string = 'product-category';

  constructor(
    public apiHttpService: ApiHttpService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
  ) {
    super(apiHttpService);
  }

  public getProductGroups(productCategoryId: string): Observable<ProductCategoryGroupNode[]> {
    return super.get(`${productCategoryId}/product-group`, { include: ['products', 'products.productCategory', 'products.toothCrossZones'] });
  }

  public listProductGroups(productCategoryId: string): Observable<ProductGroupMapItem[]> {
    return this.getProductGroups(productCategoryId).pipe(
      map((productCategoryGroupNodes: ProductCategoryGroupNode[]) => {
        return productCategoryGroupNodes.map((productCategoryGroupNode: ProductCategoryGroupNode) => {
          return this.convertProductCategoryGroupNodeToMapItem(productCategoryGroupNode);
        });
      }),
    );
  }

  private convertProductCategoryGroupNodeToMapItem(
    productCategoryGroupNode: ProductCategoryGroupNode,
  ): ProductGroupMapItem {
    return {
      ...this.attributeBaseDataService.convertBaseDataValueToMapItem(productCategoryGroupNode),
      children: productCategoryGroupNode.products
        .filter((product: Product) => product?.productType)
        .map((product: Product) => {
          return {
            ...this.attributeBaseDataService.convertBaseDataValueToMapItem(product.productType),
            product_id: product.id,
            product: product,
          };
        }),
    };
  }
}
