<div [hidden]="hidden" class="denomination">
  <div *ngIf="field.label || field.tooltip" class="group-heading">
    <h3 class="label">{{ field.label }}</h3>

    <app-form-tooltip [text]="field.tooltip" class="tooltip-content"></app-form-tooltip>
  </div>

  <p class="description">{{ field?.description }}</p>
</div>

<formly-field *ngFor="let field of field.fieldGroup"
              [field]="field">
</formly-field>
