import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable()
export class AttributesBaseDataResolver implements Resolve<Observable<boolean>> {
  constructor(
    private readonly attributeBaseDataService: AttributeBaseDataService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.attributeBaseDataService.isLoaded()) {
      let orderId: string = route.params.id;
      orderId = orderId !== AppRoutes.new ? orderId : null;

      return this.attributeBaseDataService.init(orderId).loadBaseDataInMap();
    }

    return of(true);
  }
}
