import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DownloadFileService } from '@shared/modules/uploader/services/download-file.service';

@Component({
  selector: 'app-previewer',
  templateUrl: './previewer.component.html',
  styleUrls: ['./previewer.component.scss'],
})
export class PreviewerComponent {
  @Input() public items: any[];
  @Input() public deletable: boolean = false;
  @Input() public nameProperty: string = 'name';
  @Input() public imagePathProperty: string;
  @Input() public showThumbnail: boolean = true;
  @Input() public showFilename: boolean = true;
  @Input() public showLoader: boolean = false;
  @Output() public removeClick: EventEmitter<string> = new EventEmitter();
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();

  constructor(
    public readonly fileService: DownloadFileService,
  ) {
  }

  public isImageFile(file: File): boolean {
    return file.type && file.type.includes('image');
  }

  public handleRemoveIconClick(event: Event, file: any): void {
    event.stopPropagation();
    event.stopImmediatePropagation();

    this.removeClick.next(file);
  }
}
