<header>
  <p>{{ config?.data?.header }}</p>

  <i class="fas fa-times" (click)="ref.close()"></i>
</header>

<main>
  <p>{{ config?.data?.message$ | async }}</p>
</main>

<footer [class.justify-content-end]="(config?.data?.isConfirm$ | async) === false">
  <cap-button *ngIf="config?.data?.isConfirm$ | async"
              [label]="'general.no' | translate"
              styleClass="secondary"
              (click)="reject()">
  </cap-button>

  <cap-button [label]="(config?.data?.confirmText || 'general.yes') | translate"
              styleClass="primary"
              (click)="accept()">
  </cap-button>
</footer>
