import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { DropdownInputConfiguration } from '@features/client/form-builder/models/dropdown-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { FormlyFieldConfig } from '@node_modules/@ngx-formly/core';
import { FileInputConfiguration } from '@features/client/form-builder/models/file-input-configuration.model';

@Injectable()
export class DropdownInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<DropdownInputConfiguration> {
  protected inputType: string = InputType.dropdown;

  protected appendOptions(input: FormlyFieldConfig, configuration: FileInputConfiguration): void {
    input.templateOptions.multiple = configuration.options.is_multiple;
  }
}
