import { Component } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { CommonAnimations } from '@shared/animations/common.animations';

@Component({
  selector: 'app-detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: ['./detail-layout.component.scss'],
  animations: [CommonAnimations.detailExpand],
})
export class DetailLayoutComponent {
  constructor(
    public loadingService: LoadingService,
  ) {
  }
}
