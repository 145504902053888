import {
  trigger,
  transition,
  style,
  animate,
} from '@angular/animations';

export class NotificationAnimation {
  public static slideNotification = trigger('slideNotification', [
    transition(':enter', [
      style({ right: '-441px' }),
      animate('.5s', style({ right: '37px' })),
    ]),
    transition(':leave', [animate('.5s', style({ right: '-441px' }))]),
  ]);

  public static fromTopAnimation = trigger('fromTopAnimation', [
    transition(':enter', [
      style({ transform: 'translateY(-20px)', opacity: 0 }),
      animate('.3s', style({ transform: 'translateY(0px)', opacity: 1 })),
    ]),
    transition(':leave', [animate('.5s', style({ transform: 'translateX(450px)' }))]),
  ]);
}
