import {
  ApiHttpService,
  VaporFileUploadResponse,
  VaporFileUploadService as BaseVaporFileUploadService,
} from '@capturum/api';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CapturumRequestFile, InputFile, VaporFile } from '@shared/modules/uploader/models/file.model';
import { isEmpty, isNull, reject } from 'lodash';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VaporFileUploadService extends BaseVaporFileUploadService {
  protected apiService: ApiHttpService;

  private fileableTypes: any = {
    orderSupportMessage: {
      url: '/order/{{fileable_id}}/support/message',
      bodyKey: 'attachment',
    },
  };

  constructor(apiHttpService: ApiHttpService, http: HttpClient) {
    super(apiHttpService, http);

    // TODO: Make apiHttpService property as protected in capturum/api and remove the one below.
    this.apiService = apiHttpService;
  }

  public syncFile(file: VaporFile): Observable<VaporFile> {
    return this.apiService.post(this.getFileableUrl(file), this.makeFileableData(file));
  }

  public deleteFile(file: VaporFile): Observable<null> {
    return this.apiService.delete(`/file/${file.id}`);
  }

  public makeVaporFile(file: InputFile | any, options?: any): VaporFile {
    const vaporFile = {
      id: file.id,
      filename: file.name || file.filename,
      mime_type: file.type || file.mime_type,
      fileable_type: options.fileableType,
      fileable_id: options.fileableId,
    } as VaporFile;

    if (!isEmpty(options.tag)) {
      vaporFile.tags = [options.tag];
    }

    return vaporFile;
  }

  public getUploadFileRequest(response: VaporFileUploadResponse, file: File & { id?: string }, options?: any): Observable<VaporFile> {
    // @ts-ignore
    file.id = response.data.uuid;
    const vaporFile = this.makeVaporFile(file, options);

    return this.syncFile(vaporFile).pipe(first());
  }

  private getFileableUrl(file: VaporFile): string {
    let url = `/${file.fileable_type}/${file.fileable_id}/file`;

    if (!isEmpty(this.fileableTypes[file.fileable_type]) && !isEmpty(this.fileableTypes[file.fileable_type].url)) {
      url = this.fileableTypes[file.fileable_type].url.replace('{{fileable_id}}', file.fileable_id);
    }

    return url;
  }

  private makeFileableData(file: VaporFile): any {
    const data = {
      file: file.id,
      filename: file.filename,
      mime_type: file.mime_type,
    } as CapturumRequestFile;
    const customData: CapturumRequestFile = {};

    file.tags = reject(file.tags, isNull);

    if (!isEmpty(file.tags)) {
      data.tags = file.tags.map((tag: string) => {
        return { key: 'type', value: tag };
      });
    }

    if (!isEmpty(this.fileableTypes[file.fileable_type]) && !isEmpty(this.fileableTypes[file.fileable_type].bodyKey)) {
      customData[this.fileableTypes[file.fileable_type].bodyKey] = data;
    }

    return !isEmpty(customData) ? customData : data;
  }
}
