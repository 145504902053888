import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { CheckboxInputConfiguration } from '@features/client/form-builder/models/checkbox-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable()
export class CheckboxInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CheckboxInputConfiguration> {
  protected inputType: string = InputType.checkbox;
}
