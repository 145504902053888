import { Injectable, Injector, OnDestroy } from '@angular/core';
import { get, isEmpty } from 'lodash';
import { FormlyConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBuilderApiService } from '@features/client/form-builder/services/form-builder-api.service';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { inputTypeBuilders } from '@features/client/form-builder/configs/input-type-builders.config';
import { TranslateService } from '@ngx-translate/core';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { FormBuilderGroup, FormBuilderGroupConfiguration } from '../models/form-builder-group.model';
import { FormInputHelperService } from '@features/client/form-builder/services/form-input-helper.service';
import { FormFieldsConfig } from '@features/client/form-builder/interfaces/form-fields-config';
import { FormBuilderConfigService } from '@features/client/form-builder/form-builder.config';
import { inputTypeWrappers } from '@shared/configs/input-type-wrappers.config';
import { BehaviorSubject } from '@node_modules/rxjs';

@Injectable()
export class FormBuilderService implements OnDestroy {
  private _hideValidator$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  public get hideValidator(): Observable<boolean> {
    return this._hideValidator$.asObservable();
  }

  constructor(
    private readonly formBuilderApiService: FormBuilderApiService,
    private readonly injector: Injector,
    private readonly formlyConfig: FormlyConfig,
    private readonly translateService: TranslateService,
    private readonly formInputHelperService: FormInputHelperService,
    private readonly formBuilderConfigService: FormBuilderConfigService,
  ) {
  }

  public hideValidationMessages(value: boolean): void {
    this._hideValidator$.next(value);
  }

  public ngOnDestroy(): void {
    this.formInputHelperService.ngOnDestroy();
  }

  public getFields(formKey: string, defaultValue: any): Observable<FormFieldsConfig> {
    const dynamicForm = this.formBuilderConfigService.dynamicForms.find((form: any) => form.key === formKey);
    const formBuilderRequest = dynamicForm ? dynamicForm.callback(this.injector) : this.formBuilderApiService.getFormBuilderByKey(formKey);

    return formBuilderRequest.pipe(map((formConfigs) => this.getFieldConfig(formConfigs, defaultValue)));
  }

  private getFieldConfig(formConfigs: any, defaultValue: any): FormFieldsConfig {
    const configurationFields = [];
    let description: string = null;

    if (!isEmpty(formConfigs)) {
      const formConfigurations: any[] = get(formConfigs, 'configuration.groups');
      description = !isEmpty(formConfigs.configuration.description) ?
        this.translateService.instant(`${ModuleKeys.dental}.${formConfigs.configuration.description}`) :
        null;

      formConfigurations.forEach((group: FormBuilderGroupConfiguration, index: number) => {
        const hasWrapper = inputTypeWrappers.some(item => item.name === group.location);
        const groupField: FormBuilderGroup = {
          type: InputType.group,
          fieldGroup: [],
          wrappers: [hasWrapper ? group.location : null],
          label: group.label ? this.translateService.instant(`${ModuleKeys.dental}.${group.label}`) : null,
          description: group.description ? this.translateService.instant(`${ModuleKeys.dental}.${group.description}`) : null,
          tooltip: group.tooltip ? this.translateService.instant(`${ModuleKeys.dental}.${group.tooltip}`) : null,
          templateOptions: {
            hide_validator: this.hideValidator,
            index,
          },
        };

        group.inputs.forEach((input) => {
          const inputField = this.getInputByConfigs(input, defaultValue);

          if (inputField && this.formlyConfig.types.hasOwnProperty(inputField.type)) {
            groupField.fieldGroup.push(inputField);
          }
        });

        if (groupField.fieldGroup.length) {
          configurationFields.push(groupField);
        }
      });
    }

    return { inputs: configurationFields, description };
  }

  private getInputByConfigs(configs: any, defaultValue: any): FormBuilderGroup | null {
    const builderClass = inputTypeBuilders[configs.type];
    let fieldConfig = null;

    if (builderClass) {
      fieldConfig = this.injector.get(builderClass).getInput(configs, defaultValue);
    }

    return fieldConfig;
  }
}
