<div class="flex pt-2 justify-content-between align-items-center" appManageValidatorDisplay [control]="formControl">
  <span>{{ to.label }}</span>

  <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
</div>

<ng-container *ngIf="to.options$.asObservable() | async as options">
  <cap-checkbox *ngFor="let option of options"
                [label]="option[to.label_key]"
                (change)="setChecked(option, $event.checked)">
  </cap-checkbox>
</ng-container>
