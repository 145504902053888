import { Component } from '@angular/core';
import { FieldType } from '@node_modules/@ngx-formly/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
})
export class FileInputComponent extends FieldType {
}
