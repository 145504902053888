import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonAnimations } from '@shared/animations/common.animations';
import { CalendarNavigation } from '@shared/modules/calendar/enums/calendar-navigation.enum';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [CommonAnimations.detailExpand],
})
export class CalendarComponent {
  @Input() public daysOfWeek: string[] = [];
  @Input() public daysOfMonths: any;
  @Input() public months: any;
  @Input() public currentDate: any;
  @Input() public startDayOfWeek: number;
  @Input() public returnDate: any;
  @Input() public readonly: boolean;
  @Output() public selectAvailableDay: EventEmitter<any> = new EventEmitter<any>();
  @Output() public selectMonth: EventEmitter<string> = new EventEmitter<string>();

  public calendarNavigation: typeof CalendarNavigation = CalendarNavigation;

  public chooseDay(day: any): void {
    if (!this.readonly && day.available) {
      this.selectAvailableDay.emit(day?.date);
    }
  }
}
