import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { RadioInputConfiguration } from '@features/client/form-builder/models/radio-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable()
export class RadioInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<RadioInputConfiguration> {
  protected inputType: string = InputType.radio;
}
