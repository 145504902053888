import { Injectable } from '@angular/core';
import { SetValue } from '@features/client/form-builder/state/form-builder.actions';
import { Action, State, StateContext } from '@node_modules/@ngxs/store';

@State<any>({ name: 'formBuilderDental' })
@Injectable()
export class FormBuilderState {
  @Action(SetValue)
  public setValue(ctx: StateContext<any>, { key, value }: SetValue): void {
    ctx.patchState({ [key]: value });
  }
}
