<div class="table-filters mb-3 cap-table-filter">
  <div class="row align-items-center">
    <div class="mx-3">
      {{ 'table.filters' | translate }}
    </div>

    <div class="col-md-6 col-lg-8">
      <div class="row">
        <div *ngIf="infoTable" class="col-md-6">
          <cap-input class="has-icon hide-validator"
                     styleClass="cap-rounded"
                     [ngModel]="infoTable?.activeFilters?.key?.value"
                     [placeholder]="'translation.overview.label' | translate"
                     (onInput)="searchData($event.target.value)">
          </cap-input>

          <ng-container *ngTemplateOutlet="filterIcon; context:{ icon: 'fas fa-fw fa-search' }"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-scroll">
  <cap-info-table #infoTable
                  [columns]="tableColumns"
                  [loading]="showLoader"
                  [data]="tableData"
                  [sortable]="false"
                  [styleClass]="tableData?.length ? 'scans-table gray-rows' : 'scans-table'"
                  [pagination]="false"
                  [lazyLoading]="true"
                  [selectable]="true"
                  [(selectedRows)]="selectedScans"
                  (onLazyLoad)="loadTableData($event)">
  </cap-info-table>

  <ng-template #filterIcon let-icon="icon">
    <div class="filter-icon">
      <i [ngClass]="icon"></i>
    </div>
  </ng-template>
</div>

<cap-button *ngIf="totalScans > 10 && totalScans !== tableData?.length"
            styleClass="primary btn-rounded mt-3 btn-load-more"
            [disabled]="showLoader || isDownloadLoading"
            [icon]="showLoader ? 'fas fa-spinner-third' : 'fal fa-chevron-circle-down'"
            [label]="'dental.order.impression_delivery_method.ios_show_more_scans.button' | translate"
            (onClick)="loadMore()">
</cap-button>

<div class="d-flex mt-4 justify-content-between">
  <cap-button styleClass="secondary btn-cancel"
              [label]="'button.cancel' | translate"
              (onClick)="closePopup()">
  </cap-button>

  <cap-button styleClass="primary"
              [label]="'dental.order.impression_delivery_method.ios_download_selection.button' | translate"
              [disabled]="!selectedScans.length || isDownloadLoading || showLoader"
              [icon]="isDownloadLoading ? 'fas fa-spinner-third' : ''"
              (onClick)="downloadCase()">
  </cap-button>
</div>
