<div class="datepicker">
  <div class="datepicker__popup">
    <div class="datepicker__popup__header">
      <div class="month-name">
        <span>{{ selectedMonth | date: 'MMMM yyyy'}}</span>
        <i *ngIf="false" class="fas fa-chevron-down ml-2"></i>
      </div>

      <div class="arrows">
        <i class="fas fa-chevron-left" (click)="selectMonth.emit(false)"></i>
        <i class="fas fa-chevron-right" (click)="selectMonth.emit(true)"></i>
      </div>
    </div>

    <div class="datepicker__popup__body">
      <div class="week-days">
        <p class="item" *ngFor="let weekDay of daysOfWeek">{{ weekDay }}</p>
      </div>

      <div class="d-flex">
        <div class="week-number">
          <p class="item" *ngFor="let day of weekNumbers">{{ day }}</p>
        </div>

        <div class="days">
          <p *ngFor="let day of daysOfMonths"
             [ngClass]="{
               'current': day?.day === currentDate?.getDate() && day?.date?.getMonth() === currentDate?.getMonth(),
               'available': day?.available,
               'active': day?.date?.getTime() === activeDay?.getTime() || day?.date === selectedDay || null
             }"
             class="item" (click)="selectAvailableDate(day.date, day.available)">
            {{ day.day }}
          </p>
        </div>
      </div>
    </div>

    <div class="datepicker__popup__footer">
      <div></div>
      <cap-button label="Save" styleClass="primary" (click)="saveSelectedDay()"></cap-button>
    </div>
  </div>
</div>
