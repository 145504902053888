<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <cap-dropdown *ngIf="!to.multiple"
                [formControl]="formControl"
                [label]="to.label"
                [placeholder]="to.placeholder || ' '"
                [options]="to.options$.asObservable() | async"
                [optionLabel]="to.label_key"
                [optionValue]="to.value_key"
                [hidden]="to.is_hidden"
                [disabled]="to.is_disabled"
                [appendTo]="'body'"
                [sortAlphabetically]="false"
                [withImages]="!!to.image_key">
    <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
  </cap-dropdown>

  <cap-multi-select *ngIf="to.multiple && (to.options$ | async) as options"
                    [formControl]="formControl"
                    [label]="to.label"
                    [defaultLabel]="to.placeholder || ''"
                    [options]="options"
                    [optionLabel]="to.label_key"
                    [optionValue]="to.value_key"
                    [hidden]="to.is_hidden"
                    [readonly]="to.readonly || to.is_disabled"
                    [appendTo]="'body'">
  </cap-multi-select>
</div>
