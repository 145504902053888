<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <app-image-uploader *ngIf="to?.validations?.count === 1; else defaultUploader"
                      [control]="formControl"
                      [label]="to.label"
                      [tooltip]="to.tooltip"
                      [description]="to.description"
                      [multiple]="to.multiple"
                      [tag]="to?.tag"
                      [maxSize]="to?.validations?.max"
                      [maxCount]="to?.validations?.count"
                      [extensionsAccepted]="to?.availableExtensions"
                      [fileableType]="to?.fileableType">
  </app-image-uploader>

  <ng-template #defaultUploader>
    <app-uploader [control]="formControl"
                  [label]="to.label"
                  [tooltip]="to.tooltip"
                  [description]="to.description"
                  [multiple]="to.multiple"
                  [tag]="to?.tag"
                  [maxSize]="to?.validations?.max"
                  [maxCount]="to?.validations?.count"
                  [extensionsAccepted]="to?.availableExtensions"
                  [fileableType]="to?.fileableType">
    </app-uploader>
  </ng-template>
</div>
