import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toothStateHighlight',
})
export class ToothStateHighlightPipe implements PipeTransform {
  private readonly excludedUpperJawTeeth: number[] = [18, 28];
  private readonly excludedLowerJawTeeth: number[] = [38, 48];
  private readonly jawSeparator: number = 28;

  public transform(selectedJaw: string, toothNumber: number): boolean {
    switch (selectedJaw) {
      case 'upper':
        return !this.excludedUpperJawTeeth.includes(toothNumber) && toothNumber < this.jawSeparator;
      case 'lower':
        return !this.excludedLowerJawTeeth.includes(toothNumber) && toothNumber > this.jawSeparator;
    }
  }
}
