export class TextUtil {
  public static capitalize(key: string): string {
    if (typeof key !== 'string') {
      return '';
    }

    return key.charAt(0).toUpperCase() + key.slice(1);
  }

  public static camelCase(value: string): string | null {
    return !!value ? value.replace(/(^|\s|-)\S/g, letter => letter.toUpperCase()) : null;
  }
}
