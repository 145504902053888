import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SelectedTooth, ToothCross } from '@features/client/order-workflow/models/tooth.model';
import { RotaryDialButton } from '@features/client/order-workflow/models/rotary-dial-button.model';
import { CrownAction } from '@features/client/order-workflow/enums/crown-action.enum';
import { map } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';

@Injectable()
export class ToothCrossService implements OnDestroy {
  private selectedTooth$: BehaviorSubject<SelectedTooth> = new BehaviorSubject<SelectedTooth>(null);
  private clickTooth$: BehaviorSubject<SelectedTooth> = new BehaviorSubject<SelectedTooth>(null);
  private toothCross$: BehaviorSubject<ToothCross> = new BehaviorSubject<ToothCross>(null);
  private rootTypes$: BehaviorSubject<RotaryDialButton[]> = new BehaviorSubject<RotaryDialButton[]>(null);
  private hasCrownActions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private crownAction$: BehaviorSubject<CrownAction> = new BehaviorSubject<CrownAction>(null);
  private readonly$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private showRoot$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private isUpperJawSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private isLowerJawSelected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _batchSelectEnabled: boolean = false;
  private _isConfiguring: boolean;
  private _selectedTeeth: BehaviorSubject<Set<number>> = new BehaviorSubject<Set<number>>(new Set());
  private mountArticulationForm$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);
  private toothCrossFooterForm$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);

  public ngOnDestroy(): void {
    this.resetSelectedTooth();
    this.resetCrownAction();
    this.resetJawSelection();
    this.setReadonly(null);

    this.mountArticulationForm$.next(null);
    this.toothCrossFooterForm$.next(null);
  }

  public setMountArticulationForm(value: FormGroup): void {
    this.mountArticulationForm$.next(value);
  }

  public getMountArticulationForm(): Observable<FormGroup> {
    return this.mountArticulationForm$.asObservable();
  }

  public setToothCrossFooterForm(value: FormGroup): void {
    this.toothCrossFooterForm$.next(value);
  }

  public getToothCrossFooterForm(): Observable<FormGroup> {
    return this.toothCrossFooterForm$.asObservable();
  }

  public getSelectedTeeth(): Observable<number[]> {
    return this._selectedTeeth.asObservable().pipe(map((teethSet) => {
      return Array.from(teethSet);
    }));
  }

  public setSelectedTeeth(teeth: number[]): void {
    return this._selectedTeeth.next(new Set(teeth));
  }

  public addToSelectedTeeth(teeth: number[]): void {
    const selectedTeeth = this._selectedTeeth.getValue();

    for (const tooth of teeth) {
      selectedTeeth.add(tooth);
    }

    this._selectedTeeth.next(selectedTeeth);
  }

  public toggleToothToSelectedTeeth(tooth: number): void {
    const selectedTooth = this.selectedTooth$.getValue();

    if (selectedTooth && tooth !== selectedTooth.tooth.number) {
      const selectedTeeth = this._selectedTeeth.getValue();
      let selectedTeethArray = Array.from(selectedTeeth);

      if (selectedTeethArray.includes(tooth)) {
        selectedTeethArray = selectedTeethArray.filter((toothInTeeth) => toothInTeeth !== tooth);

        this.setSelectedTeeth(selectedTeethArray);
      } else {
        this.addToSelectedTeeth([tooth]);
      }
    }
  }

  public setSelectedTooth(selectedTooth: SelectedTooth): void {
    this.selectedTooth$.next(selectedTooth);
  }

  public clearSelectedTooth(): void {
    this.selectedTooth$.next(null);
  }

  public getSelectedTooth(): Observable<SelectedTooth> {
    return this.selectedTooth$.asObservable();
  }

  public disableBatchSelectMode(): void {
    this._batchSelectEnabled = false;
    this.clearSelectedTeeth();
  }

  public enableBatchSelectMode(): void {
    this._batchSelectEnabled = true;
  }

  public isBatchSelectionEnabled(): boolean {
    return this._batchSelectEnabled;
  }

  public clearSelectedTeeth(): void {
    this._selectedTeeth.next(new Set());
  }

  public setClickTooth(selectedTooth: SelectedTooth): void {
    this.clickTooth$.next(selectedTooth);
  }

  public getClickTooth(): Observable<SelectedTooth> {
    return this.clickTooth$.asObservable();
  }

  public setToothCross(toothCross: ToothCross): void {
    this.toothCross$.next(toothCross);
  }

  public getToothCross(): Observable<ToothCross> {
    return this.toothCross$.asObservable();
  }

  public setRootTypes(rootTypes: RotaryDialButton[]): void {
    this.rootTypes$.next(rootTypes);
  }

  public getRootTypes(): Observable<RotaryDialButton[]> {
    return this.rootTypes$.asObservable();
  }

  public setHasCrownActions(hasActions: boolean): void {
    this.hasCrownActions$.next(hasActions);
  }

  public hasCrownActions(): Observable<boolean> {
    return this.hasCrownActions$.asObservable();
  }

  public setCrownAction(action: CrownAction): void {
    this.crownAction$.next(action);
  }

  public getCrownAction(): Observable<CrownAction> {
    return this.crownAction$.asObservable();
  }

  public setReadonly(readonly: boolean): void {
    this.readonly$.next(readonly);
  }

  public getReadonly(): Observable<boolean> {
    return this.readonly$.asObservable();
  }

  public setShowRoot(showRoot: boolean): void {
    this.showRoot$.next(showRoot);
  }

  public getShowRoot(): Observable<boolean> {
    return this.showRoot$.asObservable();
  }

  public setIsConfiguring(isConfiguring: boolean): void {
    this._isConfiguring = isConfiguring;
  }

  public isConfiguring(): boolean {
    return this._isConfiguring;
  }

  public setIsUpperJawSelected(selected: boolean): void {
    this.isUpperJawSelected$.next(selected);
  }

  public isUpperJawSelected(): Observable<boolean> {
    return this.isUpperJawSelected$.asObservable();
  }

  public isUpperJawSelectedSnapshot(): boolean {
    return this.isUpperJawSelected$.value;
  }

  public setIsLowerJawSelected(selected: boolean): void {
    this.isLowerJawSelected$.next(selected);
  }

  public isLowerJawSelected(): Observable<boolean> {
    return this.isLowerJawSelected$.asObservable();
  }

  public isLowerJawSelectedSnapshot(): boolean {
    return this.isLowerJawSelected$.value;
  }

  public resetCrownAction(): void {
    this.crownAction$.next(null);
    this.hasCrownActions$.next(null);
  }

  public resetJawSelection(): void {
    this.isUpperJawSelected$.next(null);
    this.isLowerJawSelected$.next(null);
  }

  public isCurrentlySelectingBatch(): boolean {
    return this._selectedTeeth.getValue().size > 1;
  }

  private resetSelectedTooth(): void {
    this.selectedTooth$.next(null);
    this.clickTooth$.next(null);
    this.toothCross$.next(null);
    this.rootTypes$.next(null);

    this._isConfiguring = null;
  }
}
