import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '@core/services/notification.service';
import { Notification } from '@shared/components/notification/notifications.model';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NotificationAnimation } from '@shared/components/notification/notification.animations';
import { NotificationType } from '@shared/components/notification/notifications.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [NotificationAnimation.slideNotification, NotificationAnimation.fromTopAnimation],
})
export class NotificationComponent implements OnInit, OnDestroy {
  public notifications: Notification[] = [];
  public notificationType: typeof NotificationType = NotificationType;
  private subscription: Subscription;

  constructor(public readonly notificationService: NotificationService) {
  }

  public ngOnInit(): void {
    this.subscription = this.notificationService.getNotification()
      .pipe(filter(Boolean))
      .subscribe(response => {
        // @ts-ignore
        this.notifications = response;
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeNotification(type: string, index: number): void {
    this.notificationService.closeNotification(type, index);
  }
}
