import { Injector } from '@angular/core';
import { Observable } from 'rxjs';

export interface DynamicFormConfig {
  key: string;
  callback: (injector: Injector) => Observable<any>;
}

export interface UrlPlaceholderConfig {
  key: string;
  callback: (injector: Injector) => string;
}

export interface FormBuilderConfig {
  formBuilderApi?: string;
  dynamicForms?: DynamicFormConfig[];
  urlPlaceholders?: UrlPlaceholderConfig[];
}

export class FormBuilderConfigService implements FormBuilderConfig {
  public formBuilderApi: string;
  public dynamicForms: DynamicFormConfig[];
  public urlPlaceholders: UrlPlaceholderConfig[];
}
