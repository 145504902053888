import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { first, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { CheckboxListInputConfiguration } from '@features/client/form-builder/models/checkbox-list-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable()
export class CheckboxListInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CheckboxListInputConfiguration> {
  protected inputType: string = InputType.checkboxList;
  protected subscription: Subscription = new Subscription();

  protected appendOptions(input: FormlyFieldConfig, configuration: CheckboxListInputConfiguration): void {
    const options$ = this.optionsPipe.transform(configuration.options.source).pipe(shareReplay(1));

    input.hooks = {
      onInit: (field: FormlyFieldConfig) => {
        options$.pipe(first()).subscribe((options) => {
          input.templateOptions.options$.next(options);
        });

        if (configuration.sets) {
          this.subscription.add(field.formControl.valueChanges.pipe(
            startWith(field.formControl.value),
            switchMap((fieldValue) => field.templateOptions.options$.asObservable().pipe(
              map((fieldOptions) => ({ fieldValue, fieldOptions })),
            )),
          ).subscribe(({ fieldValue, fieldOptions }) => {
            const stateOptions = fieldOptions.filter((option) => {
              return fieldValue && fieldValue.includes(option[field.templateOptions.value_key]);
            });

            this.formInputHelperService.setValueByKey(configuration.sets, stateOptions);
          }));
        }

        if (!isEmpty(configuration.dependencies)) {
          this.formInputHelperService.resolveDependencies(
            field, [configuration.dependencies], configuration.dependency_operator,
          );
        }
      },
      onDestroy: () => this.subscription.unsubscribe(),
    };
  }
}
