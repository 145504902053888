<div class="d-flex pt-2 form-element">
  <label *ngIf="to?.label">
    <span>{{ to.label }}</span>
  </label>

  <div *ngIf="to.options$ | async as options" class="p-field-radiobutton">
    <div *ngFor="let option of options" class="p-field-checkbox">
      <p-radioButton [value]="option[to.value_key]"
                     [(ngModel)]="selectedValue"
                     [formControl]="formControl"
                     [inputId]="option[to.value_key]"
                     [name]="key">
      </p-radioButton>

      <label [for]="option[to.value_key]">{{ option[to.label_key] }}</label>
    </div>
  </div>

  <cap-validator *ngIf="formControl" [control]="formControl" [customName]="to.label"></cap-validator>
</div>
