import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { CustomInputConfiguration } from '@features/client/form-builder/models/custom-input-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class CustomButtonInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<CustomInputConfiguration> {
  protected inputType: string = InputType.customButton;
}
