import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { TextInputConfiguration } from '@features/client/form-builder/models/text-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { FormlyFieldConfig } from '@node_modules/@ngx-formly/core';
import { ValidationType } from '@features/client/form-builder/enums/validation-type.enum';
import { KeyFilterType } from '@features/client/form-builder/enums/key-filter-type.enum';

@Injectable()
export class TextInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<TextInputConfiguration> {
  protected inputType: string = InputType.text;

  protected appendOptions(input: FormlyFieldConfig, configuration: TextInputConfiguration): void {
    if (configuration.validations && Array.isArray(configuration.validations) && configuration.validations.includes(ValidationType.numeric)) {
      input.templateOptions.keyFilter = KeyFilterType.positiveInteger;
    }
  }
}
