enum Cases {
  client = 'client',
  cases = 'cases',
  orders = 'orders',
  profile = 'profile',
  location = 'location',
  settings = 'settings',
  users = 'users',
  translations = 'translations',
  systemSettings = 'system-settings',
  productTranslations = 'product-translations',
}

enum Auth {
  login = 'auth/login',
}

enum Actions {
  new = 'new',
}

export const AppRoutes = {
  ...Cases,
  ...Auth,
  ...Actions,
};

/**
 * It's very IMPORTANT to preserve the same standard for the routes below.
 *
 * Example: /client/orders
 * ...where the first character is a dash and there's no dash at the end.
 */
enum GeneralClientRoutes {
  clientOrders = '/client/orders',
}

export type ClientRoutes = typeof ClientRoutes;
export const ClientRoutes = {
  ...GeneralClientRoutes,
};
