export const API_DATE_PROPERTIES = [
  'starts_at',
  'ends_at',
  'created_at',
  'updated_at',
  'deleted_at',
];

export const API_DATE_BLACKLIST = [
  '/api/role/translation',
  '/api/role/module',
  '/api/base-data-key',
  '/api/role/setting',
  '/api/role/base-data',
  '/api/role/permission',
];

export enum DatesFormats {
  displayFormat = 'DD-MM-YYYY',
  displayWithTimeFormat = 'DD-MM-YYYY HH:mm:ss',
  sendFormat = 'YYYY-MM-DD',
  sendWithTimeFormat = 'YYYY-MM-DD HH:mm:ss',
  messageFormat = 'MMMM Do',
  messageTimeFormat = 'HH:mm',
  notificationFormat = 'MMMM DD [at] hh:mm',
  sortableFormat = 'YYYY-MM-DD_HH:mm:ss',
  short = 'DD/MM/YY',
  dateFnsDisplayFormat = 'dd-MM-yyyy',
  dateFnsSendFormat = 'yyyy-MM-dd',
}
