import { Injectable, Injector } from '@angular/core';
import { Store } from '@node_modules/@ngxs/store';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import { ProfileFacade } from '@features/client/profile/profile.facade';
import { Observable, of } from 'rxjs';

@Injectable()
export class FileableStateConfigService {
  constructor(private readonly injector: Injector) {
  }

  public getFileable(type: string): Observable<any> {
    switch (type) {
      case 'order':
      case 'orderSupportMessage':
        return this.injector.get(Store).select(OrderWorkflowSelectors.getOrder);
      case 'orderProduct':
        return this.injector.get(Store).select(OrderWorkflowSelectors.getOrderProduct);
      case 'user':
        return this.injector.get(ProfileFacade).getUser();
      default:
        return of(null);
    }
  }
}
