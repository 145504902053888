import { Component } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmConfig } from '@shared/components/confirm/confirm-config.model';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent {
  public confirmConfig: ConfirmConfig;
  public selectedItem: MapItem;

  constructor(public readonly ref: DynamicDialogRef, public readonly config: DynamicDialogConfig) {
    this.confirmConfig = config.data;

    if (this.confirmConfig?.options?.length > 0) {
      this.selectedItem = this.confirmConfig.options[0];
    }
  }

  public accept(): void {
    this.ref.close({ accept: true, value: this.selectedItem?.value });
  }

  public reject(): void {
    this.ref.close({ accept: false });
  }
}
