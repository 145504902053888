import { Injectable } from '@angular/core';
import { BaseDataService } from '@core/services/base-data.service';
import { ModuleKeys } from '@core/enums/module-keys.enum';
import { v4 as uuidv4 } from 'uuid';
import { ApiHttpService } from '@capturum/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AttributeBaseDataService extends BaseDataService {
  protected moduleKey: string = ModuleKeys.productFlow;

  constructor(
    apiHttp: ApiHttpService,
    translateService: TranslateService,
  ) {
    super(apiHttp, translateService);
  }

  public init(orderId: string): this {
    this.resetParameters();
    this.setOrderEndpoint(orderId);

    return this;
  }

  public resetParameters(): void {
    this.dataSet = new Map();
    this.baseDataMap = new Map();
    this.baseDataPath = '/attribute-base-data';
  }

  private setOrderEndpoint(orderId: string): void {
    if (orderId && uuidv4(orderId)) {
      this.baseDataPath += `/${orderId}`;
    }
  }
}
