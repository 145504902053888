import { Injectable } from '@angular/core';
import { BehaviorSubject } from '@node_modules/rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class AccordionMenuInputService {
  private breadcrumbShown$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private clickBreadcrumb$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private productTypeClicked$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public setBreadcrumbShown(show: boolean): void {
    this.breadcrumbShown$.next(show);
  }

  public isBreadcrumbShown(): Observable<boolean> {
    return this.breadcrumbShown$.asObservable();
  }

  public setClickBreadcrumb(click: boolean): void {
    this.clickBreadcrumb$.next(click);
  }

  public getClickBreadcrumb(): Observable<boolean> {
    return this.clickBreadcrumb$.asObservable();
  }

  public setProductTypeClicked(click: boolean): void {
    this.productTypeClicked$.next(click);
  }

  public isProductTypeClicked(): Observable<boolean> {
    return this.productTypeClicked$.asObservable();
  }
}
