import { Injectable } from '@angular/core';
import { ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { Locale } from '@features/client/profile/models/locale.model';
import { User } from '@features/client/profile/models/user.model';
import { LocaleApiService } from '@features/client/profile/services/locale-api.service';
import { UserApiService } from '@features/client/profile/services/user-api.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class ProfileFacade {
  private includes = ['dentist.locations'];

  constructor(
    private readonly authService: AuthService,
    private readonly localeApi: LocaleApiService,
    private readonly userApi: UserApiService,
    private readonly storageService: StorageService,
  ) {
  }

  public getUser(): Observable<User> {
    const userId = this.authService.getUser()?.id;
    const options: ListOptions = {
      include: this.includes,
    };

    return this.userApi.get(userId, options);
  }

  public getLocales(): Observable<Locale[]> {
    return this.localeApi.locales;
  }

  public update(user: User, updatedData: User): Observable<User> {
    const data = { ...user, ...updatedData };
    const options: ListOptions = {
      include: ['locale', 'currentRole', 'roles'],
    };

    return this.userApi.update(data, options).pipe(
      tap((response: User) => this.patchUser(response)),
    );
  }

  private patchUser(user: User): void {
    const patchedUser = { ...this.authService.getUser(), ...user };

    this.storageService.set('user', patchedUser);
  }
}
