<div class="flex pt-2" appManageValidatorDisplay [control]="formControl">
  <cap-calendar [formControl]="formControl"
                [label]="to.label"
                [minDate]="to.min_date"
                [maxDate]="to.max_date"
                [showIcon]="false"
                [placeholder]="to.placeholder"
                [hasTooltip]="to.tooltip"
                styleClass="p-inputgroup">
    <app-form-tooltip [text]="to.tooltip" class="tooltip-content"></app-form-tooltip>
  </cap-calendar>
</div>
