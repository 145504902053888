import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BaseDataService } from '@core/services/base-data.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { BaseDataKey } from '@features/client/order-workflow/enums/base-data-key.enum';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { get, isEmpty } from 'lodash';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { ProductConfigurationFacade } from '@features/client/order-workflow/facades/product-configuration.facade';
import { OrderProduct } from '@features/client/order-workflow/models/order-product.model';
import { FormlyTemplateOptions } from '@node_modules/@ngx-formly/core/lib/components/formly.field.config';
import { ColorAttributes } from '@features/client/order-workflow/enums/color-attributes.enum';
import { ColorSystemPickerComponent } from '@features/client/order-workflow/components/color-system-input/color-system-picker/color-system-picker.component';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Component({
  selector: 'app-color-system-input',
  templateUrl: './color-system-input.component.html',
  styleUrls: ['./color-system-input.component.scss'],
})
export class ColorSystemInputComponent extends FieldType implements OnInit, OnDestroy {
  @ViewChild(ColorSystemPickerComponent) public colorSystemPicker: ColorSystemPickerComponent;

  public readonly colorCategoryAttributeName: string = 'color_system_base_data_value_id';

  public colorSystemCategories$: Observable<BaseDataValueMapItem[]>;
  public selectedCategory$: Subject<BaseDataValueMapItem & { type: InputType, defaultValue: any }> = new Subject<BaseDataValueMapItem & { type: InputType, defaultValue: any }>();
  public formGroup: FormGroup;
  public subType: InputType;
  public InputType: typeof InputType = InputType;
  public orderProduct: OrderProduct;
  public orderProductId: string;
  public selectedColorSystem: BaseDataValueMapItem;

  private selectedInputType: string;
  private subscription: Subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly baseDataService: BaseDataService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
    private readonly productConfigurationFacade: ProductConfigurationFacade,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.colorSystemCategories$ = this.attributeBaseDataService.listBaseData(BaseDataKey.baseColorCategory).pipe(
      shareReplay(1), delay(0),
    );

    this.orderProduct = this.productConfigurationFacade.getOrderProductSnapshot();
    this.orderProductId = this.orderProduct?.id;

    this.initializeForm();
    this.changeFormValidation();


    this.formControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter((value) => !value),
        takeUntil(this.destroy$),
      )
      .subscribe((value) => {
        this.formControl.setValue(this.formGroup?.value);
      })
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public handleColorSystemChanges(): void {
    this.subscription.add(
      this.formGroup.get(this.colorCategoryAttributeName).valueChanges.pipe(
        startWith(this.formGroup.get(this.colorCategoryAttributeName)?.value),
        mergeMap((colorSystemValue) => {
          return this.colorSystemCategories$.pipe(
            map((colorCategories) => {
              return { colorSystemValue, colorCategories };
            }),
            shareReplay(1),
          );
        }),
      ).subscribe(({ colorSystemValue, colorCategories }) => {
        this.selectedColorSystem = colorCategories.find((colorSystem: BaseDataValueMapItem) => colorSystem?.value === colorSystemValue);

        const selectedInputType = this.getInputType(this.selectedColorSystem?.value_key);
        const defaultValues = this.getDefaultValuesPerInputType(this.field?.defaultValue, selectedInputType);
        this.selectedInputType = selectedInputType;

        this.updateColorForm(defaultValues);
        this.resetColors(this.selectedColorSystem?.value_key);

        this.selectedCategory$.next({
          ...this.selectedColorSystem,
          type: selectedInputType,
          defaultValue: defaultValues,
        });
      }),
    );
  }

  public changeColorSystemType(): void {
    if (this.colorSystemPicker) {
      this.colorSystemPicker.resetPositions();
    }
  }

  public updateColorForm(childFormValues: any): void {
    this.resetColorAttributes(childFormValues);

    this.formGroup.get('colorAttributes').reset();
    this.formGroup.get('colorAttributes').setValue(childFormValues);

    const colorAttributes = {
      [this.colorCategoryAttributeName]: this.formGroup.get(this.colorCategoryAttributeName)?.value,
      ...this.formGroup.get('colorAttributes')?.value,
    };

    this.form?.get(`${this.field.key}`)?.setValue(colorAttributes);
  }

  // TODO: Move to BE side by implementing has_color_system property.
  protected hasColorSystem(selectedColorSystem: string): boolean {
    switch (selectedColorSystem) {
      case 'color_system_sr_vivodent':
      case 'color_system_vita_classic':
      case 'color_system_vita_3d':
        return true;
      default:
        return false;
    }
  }

  protected getCommonValidations(templateOptions: FormlyTemplateOptions): ValidatorFn[] | null {
    return templateOptions?.required ? [Validators.required] : null;
  }

  private initializeForm(): void {
    this.subscription.add(
      this.colorSystemCategories$.subscribe(values => {
        const firstValue = { color_system_base_data_value_id: values[0]?.value };
        let defaultValue = !isEmpty(this.field?.defaultValue) ? this.field?.defaultValue : null;

        if (!defaultValue && this.hasColorSystem(this.selectedColorSystem?.value_key)) {
          defaultValue = firstValue;
        }

        this.formGroup = new FormGroup({
          [this.colorCategoryAttributeName]: new FormControl(
            get(defaultValue || null, this.colorCategoryAttributeName),
            this.getCommonValidations(this.field?.templateOptions),
          ),
          colorAttributes: new FormControl(null, this.getCommonValidations(this.field?.templateOptions)),
        });

        this.formControl.setValue(this.formGroup?.value);
        this.formControl.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();
        this.handleColorSystemChanges();
      }),
    );
  }

  private getInputType(selectedColorSystem: string): InputType {
    return this.hasColorSystem(selectedColorSystem) ? InputType.colorSystem : null;
  }

  private getDefaultValuesPerInputType(defaultValues: any, selectedType: InputType): any {
    if (!defaultValues) {
      return;
    }

    switch (selectedType) {
      case InputType.colorSystem:
        return Object.entries(defaultValues).reduce((acc, entry) => {
          if (Object.values(ColorAttributes).includes(entry[0] as any)) {
            return { ...acc, [entry[0]]: entry[1] };
          }
        }, {});
      default:
        return null;
    }
  }

  private resetColorAttributes(childFormValues: any): void {
    Object.values(ColorAttributes).forEach((colorControl) => this.form.removeControl(colorControl));

    if (!childFormValues) {
      this.form.addControl(
        ColorAttributes.base,
        new FormControl(null, this.getCommonValidations(this.field?.templateOptions)),
      );
    } else {
      Object.keys(childFormValues).forEach((colorControl) => {
        this.form.addControl(
          colorControl,
          new FormControl(null, this.getCommonValidations(this.field?.templateOptions)),
        );

        this.form.get(colorControl).setValue(childFormValues[colorControl]);
      });
    }
  }

  private resetColors(selectedColorSystem: string): void {
    if (!this.hasColorSystem(selectedColorSystem)) {
      this.formControl.setValue({
        [this.colorCategoryAttributeName]: this.formGroup.get(this.colorCategoryAttributeName)?.value,
      });
    }
  }

  private changeFormValidation(): void {
    this.subscription.add(this.form.valueChanges.subscribe((form) => {
      const colorSystemShade = Object.keys(form).find(shade => shade.includes('_colorsystem_shade'));

      this.checkInputType(form[colorSystemShade]);
    }));
  }

  private checkInputType(lvlColors: Record<string, string> | boolean): void {
    this.insertValidation(lvlColors);

    this.selectedCategory$.pipe(first()).subscribe(category => {
      this.selectedInputType = category.type;

      this.insertValidation(lvlColors);
    });
  }

  private insertValidation(lvlColors: Record<string, string> | boolean): void {
    this.productConfigurationFacade.colorSystemHiddenFields[`${this.field.key}`] = this.field.hide;
    const isAllColorSystemFieldsHidden = Object.values(this.productConfigurationFacade.colorSystemHiddenFields).every(Boolean);
    const isColorSystemTypeSelected = !!this.selectedInputType;
    const isColorSelected = lvlColors ? lvlColors[ColorAttributes.base] || lvlColors[ColorAttributes.top] || lvlColors[ColorAttributes.middle] || lvlColors[ColorAttributes.bottom] : false;
    const clearValidation = isAllColorSystemFieldsHidden || !isColorSystemTypeSelected || (isColorSystemTypeSelected && isColorSelected);

    if (ColorAttributes.base in this.form?.value) {
      this.form.controls[ColorAttributes.base].setValidators(clearValidation ? [] : this.getCommonValidations(this.field.templateOptions));
      this.form.controls[ColorAttributes.base].updateValueAndValidity({ emitEvent: false });
      this.form.updateValueAndValidity({ emitEvent: false });
    }
  }
}
