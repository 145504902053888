import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Pipe({
  name: 'assetExists',
})
export class AssetExistsPipe implements PipeTransform {
  constructor(private readonly httpClient: HttpClient) {
  }

  public transform(assetPath: string): Observable<boolean> {
    return (assetPath === '')
      ? of(false)
      : this.httpClient.get(assetPath, { observe: 'response', responseType: 'blob' }).pipe(
        map((response) => !!response?.body?.type?.includes('image')),
        catchError(() => of(false)),
      );
  }
}
