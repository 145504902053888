import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ListOptions } from '@capturum/api';
import { Observable } from 'rxjs';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';
import { CaseScan } from '@features/client/order-workflow/models/case-scan.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class IosApiService extends ApiService<any> {
  protected endpoint: string = 'ios';

  constructor(
    public apiHttp: ApiHttpService,
    private http: HttpClient,
  ) {
    super(apiHttp);
  }

  public checkAuth(systemType: string, body: { email: string }): Observable<ExternalAuthStatus> {
    // @ts-ignore
    return this.apiHttp.get(`/${this.endpoint}/${systemType}/auth/check`, { params: body });
  }

  public sendAuthCode(systemType: string, body: ExternalAuthStatus): Observable<ExternalAuthStatus> {
    return this.apiHttp.post(`/${this.endpoint}/${systemType}/auth/code`, body);
  }

  public sendRefreshToken(systemType: string, body: ExternalAuthStatus): Observable<ExternalAuthStatus> {
    return this.apiHttp.post(`/${this.endpoint}/${systemType}/auth/refresh`, body);
  }

  public login(systemType: string, body: ExternalAuthStatus): Observable<ExternalAuthStatus> {
    return this.apiHttp.post(`/${this.endpoint}/${systemType}/auth/login`, body);
  }

  public getScans(systemType: string, options: ListOptions = {}, body: ExternalAuthStatus): Observable<{ data: CaseScan[], meta: any }> {
    // @ts-ignore
    return this.apiHttp.get(`/${this.endpoint}/${systemType}/case${this.getOptionsQuery(options)}`, { params: body });
  }

  public downloadScans(systemType: string, body: ExternalAuthStatus): Observable<Record<string, string | boolean>> {
    return this.apiHttp.post(`/${this.endpoint}/${systemType}/case/files/download`, body);
  }

  public logout(systemType: string, body: { email: string }): Observable<Record<string, string | boolean | any>> {
    return this.apiHttp.post(`/${this.endpoint}/${systemType}/auth/logout`, body);
  }
}

