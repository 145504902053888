import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ValidationMessage, ValidationMessages, ValidatorService } from '@capturum/ui/api';
import { FormGroup } from '@angular/forms';
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DentalValidatorService {
  public backendValidation: BehaviorSubject<{ [key: string]: string[] }>;
  public backendValidation$: Observable<{ [key: string]: string[] }>;

  constructor(
    private readonly validatorService: ValidatorService,
  ) {
    this.reset();
  }

  public setBackendErrorsOnForm(form: FormGroup, backendErrors: { [key: string]: string[] }): void {
    const generatedErrors = {};

    if (!isEmpty(backendErrors)) {
      Object.keys(backendErrors).forEach(key => {
        const errorKey = `BE_${key}`;

        if (form?.controls[key]) {
          if (Array.isArray(backendErrors[key])) {
            form.controls[key].setErrors({ [errorKey]: true });
            generatedErrors[errorKey] = backendErrors[key].join(' | ');
          }
        }
      });
    }

    this.setValidationMessages(generatedErrors);
  }

  public setValidationMessages(messages: ValidationMessages): void {
    this.validatorService.messages.next({ messages: { ...ValidationMessage, ...messages } });
  }

  public reset(): void {
    this.backendValidation = new BehaviorSubject(null);
    this.backendValidation$ = this.backendValidation.asObservable();
  }
}
