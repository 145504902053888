<div class="form-element">
  <div class="d-flex justify-content-between align-items-center">
    <label>{{ label }}</label>

    <div *ngIf="tooltip" class="tooltip-content">
      <i class="fas fa-info-circle"></i>

      <app-tooltip position="tr">
        <p>{{ tooltip }}</p>
      </app-tooltip>
    </div>
  </div>

  <cap-file-upload *ngIf="!disabled && (control?.value?.length <= (maxCount - 1))"
                   styleClass="g-uploader no-print"
                   [id]="getControlName()"
                   [label]="''"
                   [multiple]="multiple"
                   [accept]="fileExtensions"
                   [uploaderContent]="uploadContent"
                   (onFileChange)="addFile($event)">
  </cap-file-upload>

  <div *ngIf="control?.value?.length > maxCount" class="upload prime-file-upload">
    <i class="fas fa-cloud-upload-alt"></i>

    <span>
      {{ 'dental.validation.count.file' | translate: { count: maxCount } }}
    </span>
  </div>

  <div class="preview--section">
    <app-previewer *ngIf="!hasPopup"
                   [showLoader]="showLoader"
                   [items]="totalScans"
                   [deletable]="!disabled"
                   [showThumbnail]="showThumbnail"
                   (cardClick)="cardClick?.emit($event)"
                   (removeClick)="deleteFile($event)">
    </app-previewer>

    <app-previewer-popup *ngIf="hasPopup"
                         [showLoader]="showLoader"
                         [items]="control?.value"
                         [deletable]="!disabled"
                         [showThumbnail]="showThumbnail"
                         (cardClick)="cardClick?.emit($event)"
                         (removeClick)="deleteFile($event)">
    </app-previewer-popup>

    <app-scans-download-status (downloadedScans)="setDownloadedScans($event)"></app-scans-download-status>
  </div>

  <ng-template #uploadContent>
    <div *ngIf="!disabled" class="upload">
      <i class="fas fa-cloud-upload-alt"></i>
      <p class="d-none d-lg-block" [innerHTML]="description ? description : label"></p>
      <p class="d-lg-none">{{ 'file.upload.label' | translate }}</p>
    </div>
  </ng-template>

  <cap-validator *ngIf="control" [control]="control" [customName]="label"></cap-validator>
</div>

<p-confirmDialog #confirmDialog [header]="label" [closable]="false">
  <p-footer>
    <cap-button [label]="'general.yes' | translate"
                styleClass="primary"
                (click)="confirmDialog.accept()">
    </cap-button>
  </p-footer>
</p-confirmDialog>
