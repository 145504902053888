import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { AttributesBaseDataResolver } from '@core/resolvers/attributes-base-data.resolver';
import { AttributesTranslationResolver } from '@core/resolvers/attributes-translation.resolver';
import { LoadingService } from '@core/services/loading.service';

@Injectable()
export class OrderResolver implements Resolve<Observable<boolean>> {
  constructor(
    private readonly attributesBaseDataResolver: AttributesBaseDataResolver,
    private readonly attributesTranslationResolver: AttributesTranslationResolver,
    private readonly loadingService: LoadingService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.loadingService.setLoading(true);

    return forkJoin([
      this.attributesBaseDataResolver.resolve(route, state),
      this.attributesTranslationResolver.resolve(route, state),
    ]).pipe(
      finalize(() => this.loadingService.setLoading(false)),
      catchError(error => {
        this.loadingService.setLoading(false);

        return of(error.status === 404);
      }),
      map(() => {
        this.loadingService.setLoading(false);

        return true;
      }),
    );
  }
}
