import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@node_modules/@ngx-formly/core';
import { DialogService } from '@node_modules/primeng/dynamicdialog';
import { CaseScansComponent } from '@features/client/order-workflow/containers/case-scans/case-scans.component';
import { combineLatest, Subject } from 'rxjs';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import { Store } from '@node_modules/@ngxs/store';
import { SetExternalAuthStatus } from '@features/client/order-workflow/state/order-workflow.actions';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { StorageService } from '@core/services/storage.service';
import { NotificationService } from '@core/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { TextUtil } from '@shared/utils/text-util';
import { OrderWorkflowFacade } from '@features/client/order-workflow/facades/order-workflow.facade';
import { environment } from '@environments/environment';
import { ExternalSystem } from '../../enums/external-system.enum';

@Component({
  selector: 'app-custom-button-input',
  templateUrl: './custom-button-input.component.html',
  styleUrls: ['./custom-button-input.component.scss'],
})
export class CustomButtonInputComponent extends FieldType implements OnInit, OnDestroy {
  public isLogged: boolean;
  public systemTypeName: string;
  public environment = environment;
  public systemType: string;
  private destroy$: Subject<boolean> = new Subject();
  private authStatus: ExternalAuthStatus[] = [];

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly storageService: StorageService,
    private readonly notificationService: NotificationService,
    private readonly translateService: TranslateService,
    private readonly orderWorkflowFacade: OrderWorkflowFacade,
  ) {
    super();
  }

  public ngOnInit(): void {
    this.systemType = this.key.toString().substring('button_'.length);
    this.systemTypeName = TextUtil.camelCase(this.systemType.replace(/_/g, ' '));

    this.store.select(OrderWorkflowSelectors.externalAuthStatus).pipe(takeUntil(this.destroy$)).subscribe(authStatus => {
      if (authStatus) {
        this.authStatus = [...authStatus];
        this.isLogged = authStatus.find(auth => auth.systemType === this.systemType).success;
      }
    });

    // if (this.systemType === ExternalSystem.threeShape) {
    //   this.checkForExistingExternalData();
    // }

    this.checkForExistingExternalData(this.systemType);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public logout(): void {
    const email = this.storageService.get('user').email;

    this.orderWorkflowFacade.logout(this.systemType, { email }).pipe(first()).subscribe(() => {
      const authData = { systemType: this.systemType, success: false };
      const authIndex = this.authStatus.findIndex(auth => auth.systemType === this.systemType);
      this.authStatus[authIndex] = authData;

      // console.log('logout - authStatus', this.authStatus);

      this.store.dispatch(new SetExternalAuthStatus(this.authStatus));
      this.notificationService.showNotification({
        title: this.translateService.instant('toast.success.title'),
        description: this.translateService.instant('dental.order.impression_delivery_method.ios_logout.label'),
        type: 'success',
      });
    }, () => {
      this.notificationService.showNotification({
        title: this.translateService.instant('toast.error.title'),
        description: this.translateService.instant('toast.error.message'),
        type: 'error',
      });
    });
  }

  public connect(): void {
    const email = this.storageService.get('user')?.email;

    this.orderWorkflowFacade.checkAuth(this.systemType, { email })
    .pipe(
      first(),
      map(auth => ({ ...auth, systemType: this.systemType }))
    ).subscribe((authStatus) => {
      const authIndex = this.authStatus.findIndex(auth => auth.systemType === this.systemType);

      this.isLogged = authStatus?.success;
      this.authStatus[authIndex] = authStatus;
      this.store.dispatch(new SetExternalAuthStatus(this.authStatus));

      if (!this.isLogged) {
        this.openExternalAuth(this.systemType);
      } else {
        const authResult = this.authStatus.find(auth => auth.systemType === this.systemType);
  
        this.openScans({ ...authResult, systemType: this.systemType });
      }
    });
  }

  /* private openAuthPopup(systemType: string): void {
     this.dialogService.open(ExternalAuthComponent, {
       closable: true,
       showHeader: true,
       header: this.translateService.instant('dental.order.impression_delivery_method.ios_connect.button', { variable: this.systemTypeName }),
       modal: true,
       width: '30rem',
       data: { systemType },
     }).onClose.pipe(takeUntil(this.destroy$)).subscribe(authResult => {
       if (authResult) {
         const authData = { ...authResult, systemType, success: true };
         const authIndex = this.authStatus.findIndex(auth => auth.systemType === systemType);
         this.authStatus[authIndex] = authData;

         this.store.dispatch(new SetExternalAuthStatus(this.authStatus));
         this.openScans(authData);
       }
     });
   } */

  private openScans(authResult: ExternalAuthStatus): void {
    this.dialogService.open(CaseScansComponent, {
      closable: true,
      showHeader: true,
      header: this.translateService.instant('dental.order.impression_delivery_method.ios_scans.label', { variable: this.systemTypeName }),
      modal: true,
      width: '80rem',
      data: { authResult },
    });
  }

  private checkForExistingExternalData(systemType: string): void {
    const externalSystemParams = this.storageService.get('externalSystemParams');
    const currentSystemType = this.storageService.get('currentSystemType');
    // console.log('openSystemType', currentSystemType);
    // console.log('checkForExistingExternalData - externalSystemParams', externalSystemParams);

    if (externalSystemParams && currentSystemType === this.systemType) {
      this.orderWorkflowFacade.sendAuthCode(systemType, externalSystemParams).pipe(first()).subscribe(authResult => {
        const authData = { ...authResult, systemType, success: true };
        const authIndex = this.authStatus.findIndex(auth => auth.systemType === systemType);
        this.authStatus[authIndex] = authData;
        // console.log('checkForExistingExternalData - authStatus', this.authStatus);

        this.store.dispatch(new SetExternalAuthStatus(this.authStatus));
        this.storageService.remove('currentSystemType');
        this.storageService.remove('externalSystemParams');

        this.openScans({ ...authResult, systemType, success: true });
      });
    }
  }

//   private openExternalAuth(systemType: string): void { // TODO: temporary use hardcode
//     this.storageService.set('orderId', this.orderWorkflowFacade.getOrderSnapshot().id);
//     this.storageService.set('currentSystemType', systemType);
//
//     const redirectUrl = window.location.origin + '/';
//     let url;
//
//     if (systemType === ExternalSystem.meditLink) {
//       const clientId = 'Hpg1DOJGCPSLGk6kIxZTF1z9ZT4ScQlZ';
//       const scope = 'USER GROUP';
//       const state = 'bn6q6ru91ygkzwo8rfc0s1f7';
//       url = `https://stage-openapi-developers.meditlink.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&scope=${scope}&state=${state}`;
//     } else if (systemType === ExternalSystem.threeShape) {
//       const clientId = 'ModernDental.Staging';
//       const scope = 'openid+api+offline_access+communicate.connections.manage+data.companies.read_only+data.users.read_only';
//       url = `https://staging-identity.3shape.com/connect/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUrl}`;
//     }
//     console.log('url', url)
// debugger
//     window.open(url, '_self');
//   }

  private openExternalAuth(systemType: string): void { // TODO: temporary use hardcode
    this.storageService.set('orderId', this.orderWorkflowFacade.getOrderSnapshot().id);
    this.storageService.set('currentSystemType', systemType);

    // if (systemType === ExternalSystem.meditLink) {
    //   const clientId = 'Hpg1DOJGCPSLGk6kIxZTF1z9ZT4ScQlZ';
    //   const scope = 'USER GROUP';
    //   const state = 'bn6q6ru91ygkzwo8rfc0s1f7';
    //   url = `https://stage-openapi-developers.meditlink.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&scope=${scope}&state=${state}`;
    // } else if (systemType === ExternalSystem.threeShape) {
    //   const clientId = 'ModernDental.Staging';
    //   const scope = 'openid+api+offline_access+communicate.connections.manage+data.companies.read_only+data.users.read_only';
    //   url = `https://staging-identity.3shape.com/connect/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectUrl}`;
    // }
    // console.log('url', url)

    const paramsFromBackEnd = this.authStatus.find(authStatus => authStatus.systemType === systemType).login_url;
    // let paramsFromBe;

    // switch (systemType) {
    //   case ExternalSystem.meditLink:
    //     paramsFromBe = {
    //       external_url: 'https://stage-openapi-developers.meditlink.com/oauth/authorize',
    //       scope: 'USER GROUP',
    //       client_id: '0ErX9nEipcSqUmgDnKGRqHLxvAM9f0Bv',
    //       response_type: 'code',
    //       state: 'bn6q6ru91ygkzwo8rfc0s1f7',
    //     };
    //     break;
    //   case ExternalSystem.threeShape:
    //     paramsFromBe = {
    //       external_url: 'https://staging-identity.3shape.com/connect/authorize',
    //       scope: 'openid+api+offline_access+communicate.connections.manage+data.companies.read_only+data.users.read_only',
    //       client_id: 'ModernDental.Staging',
    //       response_type: 'code',
    //     };
    //     break;
    // }

    const params = {
      tenant_url: window.location.origin + '/',
      external_url: paramsFromBackEnd.external_url,
      ...paramsFromBackEnd.params,
    };

    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    // console.log('queryString', queryString);

    // const currentUrl = window.location.origin + '/';
    // const clientId = 'ModernDental.Staging';
    // const scope = 'openid+api+offline_access+communicate.connections.manage+data.companies.read_only+data.users.read_only';
    // const url = `https://staging-identity.3shape.com/connect/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${currentUrl}`;
    const proxyUrl = `https://d1eyg7ug9s8yp9.cloudfront.net?${queryString}`;

    // console.log('proxyUrl', proxyUrl);

    window.open(proxyUrl, '_self');
    // window.location.href = proxyUrl;
  }
}
