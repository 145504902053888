import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@features/client/order-workflow/models/order.model';

@Pipe({
  name: 'checkIfPriorityStatus',
})
export class CheckIfPriorityStatusPipe implements PipeTransform {
  public transform(order: Order): boolean {
    const today = new Date().setHours(0, 0, 0, 0);
    const returnDate = new Date(order.patient_return_at).setHours(0, 0, 0, 0);
    const deliveryDate = !!order.delivery_return_at ? new Date(order.delivery_return_at).setHours(0, 0, 0, 0) : null;

    return (!!deliveryDate && deliveryDate > returnDate) && (today <= deliveryDate);
  }
}
