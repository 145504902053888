import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { TextareaInputConfiguration } from '@features/client/form-builder/models/textarea-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable()
export class TextareaInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<TextareaInputConfiguration> {
  protected inputType: string = InputType.textarea;

  protected appendOptions(input: FormlyFieldConfig, configuration: TextareaInputConfiguration): void {
    input.templateOptions.cols = configuration.options.cols;
    input.templateOptions.rows = configuration.options.rows;
  }
}
