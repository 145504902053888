import { Injectable } from '@angular/core';
import { ImplantCustomApiService } from '@features/client/order-workflow/services/implant-custom-api.service';
import { Observable } from '@node_modules/rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ImplantCustomFacade {
  constructor(
    private readonly implantCustomApiService: ImplantCustomApiService,
    private readonly translateService: TranslateService,
  ) {
  }

  public getImplants(values: string[]): Observable<any> {
    return this.implantCustomApiService.getImplants(values).pipe(map(implants => {
      return implants.map(implant => {
        return { ...implant, value: this.translateService.instant(`base-data.dental.${implant.value}`) };
      });
    }));
  }
}
