import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { Color } from '@features/client/order-workflow/models/color.model';
import { cloneDeep, get, isEmpty } from 'lodash';
import { ColorAttributes } from '@features/client/order-workflow/enums/color-attributes.enum';

@Component({
  selector: 'app-color-system-picker',
  templateUrl: './color-system-picker.component.html',
  styleUrls: ['./color-system-picker.component.scss'],
})
export class ColorSystemPickerComponent implements OnInit, OnChanges {
  @Input() public colorCategories: BaseDataValueMapItem[];
  @Input() public defaultValue: FormlyFieldConfig;
  @Input() public selectedColorSystem: BaseDataValueMapItem & { defaultValue: any };
  @Output() public colorChanged: EventEmitter<any> = new EventEmitter();

  public colors: Map<string, Color[]> = new Map<string, Color[]>();
  public ColorAttributes: typeof ColorAttributes = ColorAttributes;
  public colorAttributeNames: ColorAttributes[] = [ColorAttributes.base];
  public formGroup: FormGroup;
  public openPart: string = null;
  private _colors: Map<string, Color[]>;

  public ngOnInit(): void {
    this.initializeForm();
    this.handleFormChanges();
  }

  public ngOnChanges({ selectedColorSystem }: SimpleChanges): void {
    if (selectedColorSystem && selectedColorSystem.currentValue) {
      this.closeColorPalette();
      this.setColors(selectedColorSystem.currentValue.children.map((child) => {
        return { id: child.value, label: child.label, color: child.attributes[0].value };
      }));
    }
  }

  public handleFormChanges(): void {
    this.formGroup.valueChanges.subscribe(() => {
      const updatedValues = this.colorAttributeNames.reduce((acc, attributeName) => {
        return { ...acc, [attributeName]: this.formGroup.get(attributeName)?.value };
      }, {});

      this.colorChanged.emit(updatedValues);
    });
  }

  public handleAddColor(): void {
    if (this.colorAttributeNames.length === 1) {
      const baseColorValue = this.formGroup.get(ColorAttributes.base)?.value;

      this.colorAttributeNames = [ColorAttributes.top, ColorAttributes.bottom];

      this.formGroup.addControl(ColorAttributes.top, new FormControl(baseColorValue, [Validators.required]));
      this.formGroup.addControl(ColorAttributes.bottom, new FormControl(null, [Validators.required]));
      this.formGroup.removeControl(ColorAttributes.base);
    } else if (this.colorAttributeNames.length === 2) {
      const bottomColorValue = this.formGroup.get(ColorAttributes.bottom)?.value;

      this.colorAttributeNames = [ColorAttributes.top, ColorAttributes.middle, ColorAttributes.bottom];

      this.formGroup.addControl(ColorAttributes.middle, new FormControl(bottomColorValue, [Validators.required]));
      this.formGroup.get(ColorAttributes.bottom).reset(null);
    }
  }

  public handleRemoveColor(controlName: string): void {
    if (this.colorAttributeNames.length === 3) {
      this.colorAttributeNames = [ColorAttributes.top, ColorAttributes.bottom];

      const middleColorValue = this.formGroup.get(ColorAttributes.middle)?.value;

      this.formGroup.get(controlName).setValue(middleColorValue);
      this.formGroup.removeControl(ColorAttributes.middle);
    } else if (this.colorAttributeNames.length === 2) {
      this.colorAttributeNames = [ColorAttributes.base];

      const leftoverColor = this.formGroup.get(
        (controlName === ColorAttributes.top) ? ColorAttributes.bottom : ColorAttributes.top,
      )?.value;

      this.formGroup.addControl(ColorAttributes.base, new FormControl(leftoverColor, [Validators.required]));
      this.formGroup.removeControl(ColorAttributes.top);
      this.formGroup.removeControl(ColorAttributes.bottom);
    }
  }

  public resetPositions(): void {
    this.colorAttributeNames = [ColorAttributes.base];

    this.formGroup.addControl(ColorAttributes.base, new FormControl(null, [Validators.required]));
    this.formGroup.removeControl(ColorAttributes.top);
    this.formGroup.removeControl(ColorAttributes.middle);
    this.formGroup.removeControl(ColorAttributes.bottom);
  }

  public handleColorPaletteClick(color: string, controlName: string): void {
    this.closeColorPalette();
    this.formGroup.get(controlName).setValue(color);
  }

  public openColorPalette(controlName: string): void {
    this.openPart = controlName;
  }

  public closeColorPalette(): void {
    this.openPart = null;
  }

  public getColorByAttributeName(attributeName: string): Color {
    let colors = [];

    if (attributeName && this._colors?.size) {
      const attributeNameLower = attributeName.toLocaleLowerCase();

      colors = Array.from(this._colors.values())
        .reduce((arr, items) => arr.concat(items), [])
        .filter(color => color.label.toLocaleLowerCase().includes(attributeNameLower) || color.id === attributeName);
    }

    return colors.length ? colors[0] : null;
  }

  private initializeForm(): void {
    const defaultValue = !isEmpty(this.selectedColorSystem?.defaultValue) ? this.selectedColorSystem?.defaultValue : null;

    if (defaultValue) {
      this.colorAttributeNames = Object.keys(this.selectedColorSystem?.defaultValue).sort((a: string, b: string) => {
        const indexOfA = Object.values(ColorAttributes).indexOf(a as ColorAttributes);
        const indexOfB = Object.values(ColorAttributes).indexOf(b as ColorAttributes);

        return indexOfA > indexOfB ? 1 : -1;
      }) as ColorAttributes[];

      if (!this.colorAttributeNames.length) {
        this.colorAttributeNames = [ColorAttributes.base];
      }
    }

    this.formGroup = new FormGroup(this.colorAttributeNames.reduce((formGroup, controlName) => ({
      ...formGroup,
      [controlName]: new FormControl(get(defaultValue || null, controlName), [Validators.required]),
    }), {}));
  }

  private setColors(value: Color[]): void {
    this.colors = new Map<string, Color[]>();

    const initials = new Set(value.map((color => color.label.slice(0, 1))));

    initials.forEach((symbol: string) => {
      const colors = value.filter(item => item.label.startsWith(symbol))
        .sort((currentColor: Color, nextColor: Color) =>
          currentColor.label.localeCompare(nextColor.label, 'en', { numeric: true }),
        );

      this.colors.set(symbol, colors);
    });

    this._colors = cloneDeep(this.colors);
  }
}
