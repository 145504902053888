import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { CheckboxInputComponent } from '@features/client/form-builder/components/checkbox-input/checkbox-input.component';
import { CheckboxListInputComponent } from '@features/client/form-builder/components/checkbox-list-input/checkbox-list-input.component';
import { TextInputComponent } from '@features/client/form-builder/components/text-input/text-input.component';
import { TextareaInputComponent } from '@features/client/form-builder/components/textarea-input/textarea-input.component';
import { LabelInputComponent } from '@features/client/form-builder/components/label-input/label-input.component';
import { EmailInputComponent } from '@features/client/form-builder/components/email-input/email-input.component';
import { DropdownInputComponent } from '@features/client/form-builder/components/dropdown-input/dropdown-input.component';
import { ImageInputComponent } from '@features/client/form-builder/components/image-input/image-input.component';
import { NumberInputComponent } from '@features/client/form-builder/components/number-input/number-input.component';
import { DateInputComponent } from '@features/client/form-builder/components/date-input/date-input.component';
import { GroupInputComponent } from '@features/client/form-builder/components/group-input/group-input.component';
import { RadioInputComponent } from '@features/client/form-builder/components/radio-input/radio-input.component';
import { FileInputComponent } from '@features/client/form-builder/components/file-input/file-input.component';
import { ColorSystemInputComponent } from '@features/client/order-workflow/components/color-system-input/color-system-input.component';
import { ImplantCustomInputComponent } from '@features/client/order-workflow/components/implant-custom-input/implant-custom-input.component';
import { PatientCalendarContainer } from '@shared/modules/calendar/containers/patient-calendar/patient-calendar.container';
import { CustomButtonInputComponent } from '@features/client/order-workflow/components/custom-button-input/custom-button-input.component';

export const inputTypeComponents = [
  { name: InputType.checkbox, component: CheckboxInputComponent, wrappers: ['content'] },
  { name: InputType.checkboxList, component: CheckboxListInputComponent },
  { name: InputType.text, component: TextInputComponent },
  { name: InputType.textarea, component: TextareaInputComponent },
  { name: InputType.label, component: LabelInputComponent },
  { name: InputType.email, component: EmailInputComponent },
  { name: InputType.dropdown, component: DropdownInputComponent },
  { name: InputType.image, component: ImageInputComponent },
  { name: InputType.number, component: NumberInputComponent },
  { name: InputType.date, component: DateInputComponent },
  { name: InputType.group, component: GroupInputComponent },
  { name: InputType.radio, component: RadioInputComponent },
  { name: InputType.file, component: FileInputComponent },
  { name: InputType.colorSystem, component: ColorSystemInputComponent },
  { name: InputType.implantCustom, component: ImplantCustomInputComponent },
  { name: InputType.calendar, component: PatientCalendarContainer },
  { name: InputType.customButton, component: CustomButtonInputComponent },
];
