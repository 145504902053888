<div class="position-relative" (capClickOutside)="isOpen = false">
  <cap-button [styleClass]=[styleClass]
              [icon]="icon"
              [label]="label"
              (click)="toggleMenu()">
  </cap-button>

  <ul *ngIf="actions?.length"
      class="row-action__items"
      [ngClass]="{ 'row-action__items--open': isOpen }">
    <ng-container *ngFor="let action of actions">
      <li *ngIf="!action?.hidden"
          [attr.data-test]="action?.key || action?.value"
          (click)="executeAction(action)">
        <div *ngIf="action?.icon" class="row-action__icon">
          <i [class]="action?.icon"></i>
        </div>

        <div class="row-action__info">
          <p class="title">{{ action?.label | observablePipe | async }}</p>
          <p *ngIf="action?.description | observablePipe | async as description" class="description">
            {{ description }}
          </p>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
