import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Pipe({
  name: 'attributeBaseData',
})
export class AttributeBaseDataPipe implements PipeTransform {
  constructor(private readonly attributeBaseDataService: AttributeBaseDataService) {
  }

  public transform(value: string): Observable<BaseDataValueMapItem> {
    return value ? this.attributeBaseDataService.getById(value) : of(null);
  }
}
