import { Action, State, StateContext } from '@node_modules/@ngxs/store';
import {
  ClearOrderWorkflow,
  OpenChat,
  PatchOrder,
  SetExternalAuthStatus,
  SetOrder,
  SetOrderProduct,
  SetProductType,
} from '@features/client/order-workflow/state/order-workflow.actions';
import { Injectable } from '@angular/core';
import { OrderWorkflowStateModel } from '@features/client/order-workflow/state/order-workflow-state.model';

@State<OrderWorkflowStateModel>({ name: 'orderWorkflow' })
@Injectable()
export class OrderWorkflowState {
  @Action(SetOrder)
  public setOrder(ctx: StateContext<OrderWorkflowStateModel>, { order }: SetOrder): void {
    ctx.patchState({ order });
  }

  @Action(SetProductType)
  public setProductType(ctx: StateContext<OrderWorkflowStateModel>, { productType }: SetProductType): void {
    ctx.patchState({ productType });
  }

  @Action(SetOrderProduct)
  public setOrderProduct(ctx: StateContext<OrderWorkflowStateModel>, { orderProduct }: SetOrderProduct): void {
    ctx.patchState({ orderProduct });
  }

  @Action(ClearOrderWorkflow)
  public clearOrderWorkflow(ctx: StateContext<OrderWorkflowStateModel>): void {
    ctx.setState({} as any);
  }

  @Action(PatchOrder)
  public patchOrder(ctx: StateContext<OrderWorkflowStateModel>, { unsavedOrder }: PatchOrder): void {
    ctx.patchState({ unsavedOrder });
  }

  @Action(OpenChat)
  public openChat(ctx: StateContext<OrderWorkflowStateModel>, openChat: boolean): void {
    ctx.patchState({ openChat });
  }

  @Action(SetExternalAuthStatus)
  public setExternalAuthStatus(ctx: StateContext<OrderWorkflowStateModel>, { externalAuthStatus }: SetExternalAuthStatus): void {
    ctx.patchState({ externalAuthStatus });
  }
}
