<p-dialog [header]="confirmConfig?.title || 'confirm' | translate"
          [visible]="true"
          [resizable]="false"
          [modal]="true"
          [draggable]="false"
          [closable]="false"
          [closeIcon]="">
  <div class="confirm-content">
    <div *ngIf="confirmConfig?.message" class="confirm-message">
      {{ confirmConfig?.message | translate }}
    </div>

    <div *ngIf="confirmConfig?.options?.length > 0" class="confirm-options p-field-radiobutton">
      <div *ngFor="let option of confirmConfig?.options" class="p-field-checkbox">
        <p-radioButton [value]="option"
                       [inputId]="option.value"
                       [(ngModel)]="selectedItem">
        </p-radioButton>

        <label [for]="option.value">{{ option.label }}</label>
      </div>
    </div>
  </div>

  <p-footer>
    <cap-button [label]="'general.no' | translate"
                styleClass="secondary"
                icon="fas fa-times"
                (click)="reject()">
    </cap-button>

    <cap-button [label]="'general.yes' | translate"
                styleClass="primary"
                icon="fas fa-check"
                (click)="accept()">
    </cap-button>
  </p-footer>
</p-dialog>
