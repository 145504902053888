import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderWorkflowFacade } from '@features/client/order-workflow/facades/order-workflow.facade';
import { Order } from '@features/client/order-workflow/models/order.model';
import { Store } from '@node_modules/@ngxs/store';
import { OrderWorkflowService } from '@features/client/order-workflow/services/order-workflow.service';
import { OrderWorkflowStep } from '@features/client/order-workflow/enums/order-workflow-step.enum';

@Injectable()
export class OrderWorkflowStepFacade implements OnDestroy {
  constructor(
    protected readonly orderWorkflowFacade: OrderWorkflowFacade,
    protected readonly orderWorkflowService: OrderWorkflowService,
    protected readonly store: Store,
  ) {
  }

  public ngOnDestroy(): void {
    this.orderWorkflowService.ngOnDestroy();
  }

  public submitData(formData: any): Observable<Order> {
    const orderId = this.orderWorkflowFacade.getOrderSnapshot().id;

    return this.orderWorkflowFacade.continueOrder(orderId, this.getSubmitData(formData));
  }

  public getProcessStep(): Observable<OrderWorkflowStep> {
    return this.orderWorkflowService.getProcessStep();
  }

  public getSubmitData(formData: any): Partial<Order> {
    return formData;
  }
}
