import { Injectable } from '@angular/core';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { FormInputConfig } from '@features/client/form-builder/interfaces/form-input-config';
import { ColorSystemInputConfiguration } from '@features/client/form-builder/models/color-system-input-configuration.model';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ColorSystemInputTypeBuilder extends InputTypeBuilder implements FormInputConfig<ColorSystemInputConfiguration> {
  protected inputType: string = InputType.colorSystem;
}
