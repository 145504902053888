import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
  name: 'fileUrl',
})
export class FileUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {
  }

  public transform(file: any): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(file));
  }
}
