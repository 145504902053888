import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AttributesTranslationService } from '@core/services/attributes-translation.service';
import { AppRoutes } from '@core/enums/routes.enum';

@Injectable()
export class AttributesTranslationResolver implements Resolve<Observable<boolean>> {
  constructor(
    private readonly attributeTranslationService: AttributesTranslationService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!this.attributeTranslationService.isLoaded()) {
      let orderId: string = route.params.id;
      orderId = orderId !== AppRoutes.new ? orderId : null;

      return this.attributeTranslationService.init(orderId).loadTranslations();
    }

    return of(true);
  }
}
