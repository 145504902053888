import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { isEmpty } from 'lodash';
import { VaporFileUploadService } from '@shared/modules/uploader/services/vapor-file-upload.service';
import { InputFile, VaporFile } from '@shared/modules/uploader/models/file.model';
import { VaporFileUploadResponse } from '@capturum/api';
import { HttpEventType } from '@angular/common/http';
import { TranslateDentalPipe } from '@shared/pipes/translate-dental.pipe';
import { DialogService } from '@node_modules/primeng/dynamicdialog';
import { ConfirmationComponent } from '@shared/components/confirmation/confirmation.component';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, finalize, first, map, switchMap } from 'rxjs/operators';
import { FileableStateConfigService } from '@shared/modules/uploader/services/fileable-state-config.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss'],
})
export class UploaderComponent implements OnInit, OnDestroy {
  @Input() public control: AbstractControl = new FormControl();
  @Input() public id: string;
  @Input() public label: string;
  @Input() public tooltip: string;
  @Input() public description: string;
  @Input() public multiple: boolean = true;
  @Input() public maxSize: number = 5000;
  @Input() public tag: string;
  @Input() public showThumbnail: boolean = true;
  @Input() public extensionsAccepted: string | string[] = [];
  @Input() public disabled: boolean = false;
  @Input() public fileableType: string;
  @Input() public fileableId: string;
  @Input() public messages: { [key: string]: string };
  @Input() public maxCount: number = null;
  @Output() public cardClick: EventEmitter<string> = new EventEmitter();
  @Output() public filesChanged: EventEmitter<void> = new EventEmitter();
  @Output() public fileRemoved: EventEmitter<void> = new EventEmitter();
  @Output() public filesAdded: EventEmitter<VaporFile[]> = new EventEmitter();

  public showLoader: boolean = false;
  public hasPopup: boolean = false;
  public totalScans: VaporFile[] & InputFile[] = [];
  private vaporFiles: VaporFile[] = [];
  private subscription: Subscription = new Subscription();

  constructor(
    protected readonly vaporFileUploadService: VaporFileUploadService,
    protected readonly fileableStateConfigService: FileableStateConfigService,
    protected readonly translateDental: TranslateDentalPipe,
    protected readonly dialogService: DialogService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
  }

  public get fileExtensions(): string {
    const extensionsAccepted = Array.isArray(this.extensionsAccepted) ? this.extensionsAccepted : this.extensionsAccepted.split(',');
    const uppercaseExtensions: string[] = [];

    if (extensionsAccepted.length) {
      for (let j = 0; j < extensionsAccepted.length; j++) {
        uppercaseExtensions.push(extensionsAccepted[j].toUpperCase());
      }
    }

    return extensionsAccepted.length ? null : [...extensionsAccepted, ...uppercaseExtensions].join(',');
  }

  public get files(): File[] {
    return this.control.value || [];
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.checkFile();
      this.checkFileable();
      this.setVaporFiles(this.control.value);
      this.setDownloadedScans(this.control?.value);
      this.totalScans = this.control.value;
  
      this.subscription = this.control.valueChanges.subscribe(() => {
        this.totalScans = this.control.value;
  
        this.setVaporFiles(this.totalScans);
      });
    }, 1000);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public getControlName(): string | null {
    const formGroup = this.control.parent && this.control.parent.controls;
    let controlName = null;

    if (formGroup) {
      controlName = Object.keys(formGroup).find(name => this.control === formGroup[name]) || null;
    }

    return controlName;
  }

  public setDownloadedScans(scans: VaporFile[]): void {
    this.control.patchValue(scans);
  }

  public addFile(files: File[]): void {
    if (!isEmpty(files)) {
      const validationMessage = this.getValidationMessage(files);

      if (validationMessage) {
        this.dialogService.open(ConfirmationComponent, {
          closable: false,
          showHeader: false,
          styleClass: 'confirm-dialog',
          data: {
            confirmText: 'dental.ok',
            message$: of(validationMessage),
            isConfirm$: of(false),
            header: this.label,
          },
        });
      } else {
        this.showLoader = true;

        const fileRequests: Observable<{ response: VaporFile, file: File & { id: string } }>[] = files.map((file) => {
          return this.vaporFileUploadService.uploadFile(file).pipe(
            filter((response) => response.type === HttpEventType.Response),
            first(),
            switchMap((response: VaporFileUploadResponse | any) => {
              return this.vaporFileUploadService.getUploadFileRequest(response, file, this.getVaporFileOptions())
                .pipe(map((fileResponse) => {
                  // @ts-ignore
                  return { response: fileResponse.data, file: file as File & { id: string } };
                }));
            }),
          );
        });

        combineLatest(fileRequests).subscribe((fileResponses) => {
          if (this.multiple) {
            this.control.setValue([...(this.control.value || []), ...fileResponses.map((fileResponse) => fileResponse.response)]);
          } else {
            this.control.setValue(fileResponses.map((fileResponse) => fileResponse.response));
          }

          fileResponses.forEach((file) => {
            this.vaporFiles[file.response.id] = this.vaporFileUploadService.makeVaporFile(file.response, this.getVaporFileOptions());
          });

          this.showLoader = false;

          this.filesChanged.emit();
          this.filesAdded.emit(this.vaporFiles);
          this.cdr.detectChanges();
        }, () => {
          this.showLoader = false;
        });
      }
    }

    this.filesChanged.emit();
  }

  public deleteFile(file: InputFile): void {
    const files = this.totalScans.filter(item => item['id'] !== file.id);
    this.showLoader = true;

    this.vaporFileUploadService.deleteFile(this.vaporFiles[file.id]).pipe(
      first(),
      finalize(() => this.showLoader = false),
    ).subscribe(() => {
      this.control.setValue(isEmpty(files) ? null : files);

      delete this.vaporFiles[file.id];

      this.filesChanged.emit();
      this.fileRemoved.emit();
      this.cdr.detectChanges();
    });
  }

  private checkFile(): void {
    if (this.control?.value && !Array.isArray(this.control.value)) {
      this.control.setValue([this.control.value]);
    }
  }

  private checkFileable(): void {
    if (this.fileableType) {
      this.fileableStateConfigService.getFileable(this.fileableType)
        .subscribe(entity => {
          this.hasPopup = this.tag === 'order_tooth_cross'; // TODO: Decouple this from order tooth cross.
          this.maxCount = this.maxCount ? +this.maxCount : 1000;

          if (this.getControlName() !== 'attachment') {
            this.disabled = entity && entity.hasOwnProperty('is_editable') && !entity.is_editable;
          }

          if (!this.fileableId) {
            this.fileableId = entity && entity?.id;
          }

          if (!!entity?.files?.length && !this.control?.value) {
            this.control.setValue(
              // Filter the files by the tag that current file-uploader is using.
              entity.files.filter(file => file?.tags?.some(tag => tag.value === this.tag)),
            );
          }
        });
    }
  }

  private setVaporFiles(files: InputFile[]): void {
    if (files?.length) {
      files.forEach(file => this.vaporFiles[file.id] = this.vaporFileUploadService.makeVaporFile(file, this.getVaporFileOptions()));
    }
  }

  private getValidationMessage(files: File[]): string {
    const areFileTypesInvalid = files.some(file => this.fileExtensions?.split(',')?.every(extension => !file.name.endsWith(extension)));
    const areFileSizesInvalid = files.some(file => file.size > this.maxSize);
    const uploadedFiles = this.control?.value?.length;
    const uploadingTooManyFiles = (files.length + uploadedFiles) > this.maxCount;
    let message = null;

    if (areFileTypesInvalid) {
      message = this.translateDental.transform((!!this.messages && this.messages.length && this.messages['mimes'])
        ? this.messages['mimes']
        : 'validation.mimes.file',
      );
    }

    if (areFileSizesInvalid) {
      message = this.translateDental.transform((!!this.messages && this.messages.length && this.messages['max'])
        ? this.messages['max']
        : 'validation.max.file',
      );
    }

    if (uploadingTooManyFiles) {
      message = this.translateDental.transform('validation.count.file', { count: this.maxCount });
    }

    return message;
  }

  private getVaporFileOptions(): Record<string, string> {
    return {
      fileableType: this.fileableType,
      fileableId: this.fileableId,
      tag: this.tag,
    };
  }
}
