import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class AccordionService {
  private activeTab$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public setActiveTab(tabId: string): void {
    this.activeTab$.next(tabId);
  }

  public getActiveTab(): Observable<string> {
    return this.activeTab$.asObservable();
  }
}
