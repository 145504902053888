import { Component, OnInit, ViewChild } from '@angular/core';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { NotificationService } from '@core/services/notification.service';
import { DynamicDialogConfig, DynamicDialogRef } from '@node_modules/primeng/dynamicdialog';
import { OrderWorkflowFacade } from '@features/client/order-workflow/facades/order-workflow.facade';
import { first } from 'rxjs/operators';
import { FilterMatchMode } from '@capturum/ui/api';
import { ListOptions } from '@capturum/api';
import { LazyLoadEvent } from '@node_modules/primeng/api';
import { NotificationType } from '@shared/components/notification/notifications.enum';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DatesFormats } from '@core/configs/date-config';
import { ExternalAuthStatus } from '@features/client/order-workflow/models/external-auth-status.model';
import { CaseScan } from '@features/client/order-workflow/models/case-scan.model';

@Component({
  selector: 'app-case-scans',
  templateUrl: './case-scans.component.html',
  styleUrls: ['./case-scans.component.scss'],
})
export class CaseScansComponent implements OnInit {
  @ViewChild(CapturumInfoTableComponent, { static: false }) public table: CapturumInfoTableComponent;
  public tableColumns: InfoTableColumn[];
  public showLoader: boolean = false;
  public tableData: CaseScan[];
  public listOptions: ListOptions = {
    perPage: 10,
    page: 1,
  };
  public selectedScans: CaseScan[] = [];
  public isDownloadLoading: boolean;
  public totalScans: number;

  private authResult: ExternalAuthStatus;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly dialogRef: DynamicDialogRef,
    private readonly dialogConfig: DynamicDialogConfig,
    private readonly orderWorkflowFacade: OrderWorkflowFacade,
    private readonly translateService: TranslateService,
  ) {
  }

  public ngOnInit(): void {
    this.authResult = this.dialogConfig?.data?.authResult;
    this.tableColumns = this.getColumns();
  }

  public loadTableData(event?: LazyLoadEvent): void {
    const sendData = {
      ios_user_id: this.authResult.ios_user_id,
    };

    this.showLoader = true;

    if (event?.filters?.key) {
      this.listOptions.filters = [
        {
          field: 'global',
          value: event.filters?.key?.value,
          operator: 'like',
        },
      ];
    } else {
      delete this.listOptions.filters;
    }

    this.orderWorkflowFacade.getScans(this.authResult?.systemType, this.listOptions, sendData)
      .pipe(first())
      .subscribe(response => {
        this.totalScans = response?.meta?.pagination?.total;
        this.tableData = response?.data?.map(scan => {
          return {
            ...scan,
            scanned: scan.scanned ? moment(scan.scanned).format(DatesFormats.short) : '-',
            birthDate: scan.birthDate ? moment(scan.birthDate).format(DatesFormats.short) : '-',
            ordered: scan.ordered ? moment(scan.ordered).format(DatesFormats.short) : '',
          };
        });
        this.showLoader = false;
      });
  }

  public closePopup(): void {
    this.dialogRef.close();
  }

  public downloadCase(): void {
    this.isDownloadLoading = true;
    const orderId = this.orderWorkflowFacade.getOrderSnapshot().id;
    const scanIds = this.selectedScans.map(scan => scan.id);
    const sendData = {
      ios_user_id: this.authResult.ios_user_id,
      order_id: orderId,
      cases: scanIds,
    };

    this.orderWorkflowFacade.downloadScans(this.authResult?.systemType, sendData).pipe(first()).subscribe(response => {
      this.isDownloadLoading = false;

      this.notificationService.showNotification({
        title: this.translateService.instant('toast.success.title'),
        description: response?.message || this.translateService.instant('toast.success.title'),
        type: NotificationType.success,
      });

      this.orderWorkflowFacade.emitDownloadStatusMethod();
      this.closePopup();
    }, () => {
      this.isDownloadLoading = false;

      this.notificationService.showNotification({
        title: this.translateService.instant('toast.error.title'),
        description: this.translateService.instant('toast.error.message'),
        type: NotificationType.error,
      });
    });
  }

  public searchData(value: string): void {
    this.listOptions.page = 1;

    this.table.filterTable(value, 'key', FilterMatchMode.LIKE);
  }

  public loadMore(): void {
    this.listOptions.perPage += 10;

    this.loadTableData();
  }

  private getColumns(): InfoTableColumn[] {
    return [
      {
        field: 'status',
        title: this.translateService.instant('dental.user.profile.status'),
      },
      {
        field: 'name',
        title: this.translateService.instant('dental.order.impression_delivery_method.ios_case_name.column'),
      },
      {
        field: 'patient.name',
        title: this.translateService.instant('dental.order_summary.patient_name.label'),
      },
      {
        field: 'birthDate',
        title: this.translateService.instant('dental.patient.birthdate_at.label'),
      },
      {
        field: 'information',
        title: this.translateService.instant('dental.order.impression_delivery_method.ios_form_information.column'),
      },
      {
        field: 'scanned',
        title: this.translateService.instant('dental.order.impression_delivery_method.ios_date_scanned.column'),
      },
      {
        field: 'ordered',
        title: this.translateService.instant('dental.order.impression_delivery_method.ios_date_ordered.column'),
      },
      {
        field: 'labName',
        title: this.translateService.instant('dental.order.impression_delivery_method.ios_lab_name.column'),
      },
    ];
  }
}
