import { Pipe, PipeTransform } from '@angular/core';
import { DatesFormats } from '@core/configs/date-config';
import * as moment from 'moment';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  public transform(value: string, type: 'date' | 'datetime' = 'date'): string {
    if (!value) {
      return '';
    }

    switch (type) {
      case 'datetime':
        return moment(value).format(DatesFormats.displayWithTimeFormat);
      case 'date':
      default:
        return moment(value).format(DatesFormats.displayFormat);
    }
  }
}
