import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataService } from '@core/services/base-data.service';
import { Observable, of } from 'rxjs';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';

@Pipe({
  name: 'baseData',
})
export class BaseDataPipe implements PipeTransform {
  constructor(private readonly baseDataService: BaseDataService) {
  }

  public transform(value: string): Observable<BaseDataValueMapItem> {
    return value ? this.baseDataService.getById(value) : of(null);
  }
}
