import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from '@node_modules/rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProductConfigurationService implements OnDestroy {
  private _applyConfiguration$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private isLoading$ = new BehaviorSubject<boolean>(false);

  public ngOnDestroy(): void {
    this._applyConfiguration$.next(null);
  }

  public setApplyConfiguration(apply: boolean): void {
    this._applyConfiguration$.next(apply);
  }

  public get onApplyConfigurationSet$(): Observable<boolean> {
    return this._applyConfiguration$.asObservable();
  }

  public setLoading(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  public getIsLoading(): Observable<boolean> {
    return this.isLoading$.asObservable();
  }
}
