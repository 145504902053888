import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormlyModule } from '@ngx-formly/core';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { FormModule } from '@capturum/formly';
import { CapturumInputModule } from '@capturum/ui/input';
import { CheckboxModule } from 'primeng/checkbox';
import { SharedModule } from '@shared/shared.module';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { FormBuilderContainer } from '@features/client/form-builder/containers/form-builder/form-builder.container';
import { OptionsPipe } from '@features/client/form-builder/pipes/options.pipe';
import { CheckboxInputComponent } from '@features/client/form-builder/components/checkbox-input/checkbox-input.component';
import { CheckboxListInputComponent } from '@features/client/form-builder/components/checkbox-list-input/checkbox-list-input.component';
import { TextInputComponent } from '@features/client/form-builder/components/text-input/text-input.component';
import { TextareaInputComponent } from '@features/client/form-builder/components/textarea-input/textarea-input.component';
import { LabelInputComponent } from '@features/client/form-builder/components/label-input/label-input.component';
import { EmailInputComponent } from '@features/client/form-builder/components/email-input/email-input.component';
import { DropdownInputComponent } from '@features/client/form-builder/components/dropdown-input/dropdown-input.component';
import { ImageInputComponent } from '@features/client/form-builder/components/image-input/image-input.component';
import { NumberInputComponent } from '@features/client/form-builder/components/number-input/number-input.component';
import { DateInputComponent } from '@features/client/form-builder/components/date-input/date-input.component';
import { GroupInputComponent } from '@features/client/form-builder/components/group-input/group-input.component';
import { RadioInputComponent } from '@features/client/form-builder/components/radio-input/radio-input.component';
import { inputTypeComponents } from '@features/client/form-builder/configs/input-type-components.config';
import { FormBuilderService } from '@features/client/form-builder/services/form-builder.service';
import { FormBuilderApiService } from '@features/client/form-builder/services/form-builder-api.service';
import { FormBuilderResolver } from '@features/client/form-builder/resolvers/form-builder.resolver';
import { CheckboxInputTypeBuilder } from '@features/client/form-builder/builders/checkbox-input-type.builder';
import { CheckboxListInputTypeBuilder } from '@features/client/form-builder/builders/checkbox-list-input-type.builder';
import { FormInputHelperService } from '@features/client/form-builder/services/form-input-helper.service';
import { TextInputTypeBuilder } from '@features/client/form-builder/builders/text-input-type.builder';
import { TextareaInputTypeBuilder } from '@features/client/form-builder/builders/textarea-input-type.builder';
import { LabelInputTypeBuilder } from '@features/client/form-builder/builders/label-input-type.builder';
import { EmailInputTypeBuilder } from '@features/client/form-builder/builders/email-input-type.builder';
import { DropdownInputTypeBuilder } from '@features/client/form-builder/builders/dropdown-input-type.builder';
import { ImageInputTypeBuilder } from '@features/client/form-builder/builders/image-input-type.builder';
import { NumberInputTypeBuilder } from '@features/client/form-builder/builders/number-input-type.builder';
import { DateInputTypeBuilder } from '@features/client/form-builder/builders/date-input-type.builder';
import { InputTypeBuilder } from '@features/client/form-builder/builders/input-type.builder';
import { RadioInputTypeBuilder } from '@features/client/form-builder/builders/radio-input-type.builder';
import { FormBuilderConfig, FormBuilderConfigService } from '@features/client/form-builder/form-builder.config';
import { CustomInputTypeBuilder } from '@features/client/form-builder/builders/custom-input-type.builder';
import { NgxsModule } from '@node_modules/@ngxs/store';
import { FormBuilderState } from '@features/client/form-builder/state/form-builder.state';
import { FileInputComponent } from './components/file-input/file-input.component';
import { FileInputTypeBuilder } from '@features/client/form-builder/builders/file-input-type.builder';
import { UploaderModule } from '@shared/modules/uploader/uploader.module';
import { TooltipModule } from 'primeng/tooltip';
import { ManageValidatorDisplayDirective } from '@features/client/form-builder/directives/hide-validator.directive';
import { ColorSystemInputTypeBuilder } from '@features/client/form-builder/builders/color-system-input-type.builder';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { ImplantCustomInputTypeBuilder } from '@features/client/form-builder/builders/implant-custom-input-type.builder';

@NgModule({
  declarations: [
    FormBuilderContainer,
    OptionsPipe,
    CheckboxInputComponent,
    CheckboxListInputComponent,
    TextInputComponent,
    TextareaInputComponent,
    LabelInputComponent,
    EmailInputComponent,
    DropdownInputComponent,
    ImageInputComponent,
    NumberInputComponent,
    DateInputComponent,
    GroupInputComponent,
    RadioInputComponent,
    FileInputComponent,
    ManageValidatorDisplayDirective,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormModule,
    ReactiveFormsModule,
    FormlyModule.forChild({
      types: inputTypeComponents,
    }),
    CapturumCheckboxModule,
    CapturumDropdownModule,
    CapturumMultiSelectModule,
    CapturumFileUploadModule,
    CapturumSharedModule,
    CapturumTextareaModule,
    CapturumInputModule,
    CheckboxModule,
    CapturumCalendarModule,
    NgxsModule.forFeature([FormBuilderState]),
    UploaderModule,
    TooltipModule,
  ],
  providers: [
    FormBuilderService,
    FormBuilderApiService,
    FormBuilderResolver,
    CheckboxInputTypeBuilder,
    CheckboxListInputTypeBuilder,
    FormInputHelperService,
    TextInputTypeBuilder,
    TextareaInputTypeBuilder,
    LabelInputTypeBuilder,
    EmailInputTypeBuilder,
    DropdownInputTypeBuilder,
    ImageInputTypeBuilder,
    NumberInputTypeBuilder,
    DateInputTypeBuilder,
    InputTypeBuilder,
    RadioInputTypeBuilder,
    CustomInputTypeBuilder,
    FileInputTypeBuilder,
    ColorSystemInputTypeBuilder,
    ImplantCustomInputTypeBuilder,
    { provide: OptionsPipe, useClass: OptionsPipe },
  ],
  exports: [
    FormBuilderContainer,
    OptionsPipe,
  ],
})
export class FormBuilderModule {
  public static forRoot(configs: FormBuilderConfig = {}): ModuleWithProviders<FormBuilderModule> {
    return {
      ngModule: FormBuilderModule,
      providers: [{
        provide: FormBuilderConfigService,
        useValue: {
          formBuilderApi: configs.formBuilderApi || '/form-builder',
          dynamicForms: configs.dynamicForms || [],
          urlPlaceholders: configs.urlPlaceholders || [],
        },
      }],
    };
  }
}
