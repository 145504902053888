import { UploaderComponent } from '@shared/modules/uploader/containers/uploader/uploader.component';
import { Component } from '@angular/core';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.scss']
})
export class ImageUploaderComponent extends UploaderComponent {
}
