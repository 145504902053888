import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { combineLatest, Observable } from '@node_modules/rxjs';
import { Product } from '@features/client/order-workflow/models/product.model';
import { BaseDataKey } from '@features/client/order-workflow/enums/base-data-key.enum';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { map } from 'rxjs/operators';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Injectable()
export class RootProductApiService extends ApiService<Product> {
  protected endpoint: string = 'root-product';

  constructor(
    public readonly apiHttpService: ApiHttpService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
  ) {
    super(apiHttpService);
  }

  public getAll(): Observable<BaseDataValueMapItem[]> {
    return combineLatest([
      this.index(),
      this.attributeBaseDataService.listBaseData(BaseDataKey.rootType),
    ]).pipe(
      map(([rootProducts, rootProductBaseDataValues]) => {
        const products = rootProducts.data.map((product: Product) => {
          return {
            ...product.productType,
            id: product.id,
          };
        });

        return rootProductBaseDataValues.map((rootProductBaseDataValue: BaseDataValueMapItem) => {
          const product = products.find(item => item.value === rootProductBaseDataValue.value_key);

          return {
            ...rootProductBaseDataValue,
            value: product.id,
          };
        });
      }),
    );
  }
}
