import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import {
  CompleteConfig,
  ModuleResolver,
  PermissionResolver,
  SettingResolver,
  TranslationResolver,
} from '@capturum/complete';
import { LoadingService } from '@capturum/ui/loader';
import { TranslateService } from '@ngx-translate/core';
import { catchError, finalize, map } from 'rxjs/operators';
import { DentalBaseDataResolver } from '@core/resolvers/dental-base-data.resolver';

@Injectable()
export class DentalCompleteResolver implements Resolve<Observable<boolean>> {
  constructor(
    private baseDataResolver: DentalBaseDataResolver,
    private moduleResolver: ModuleResolver,
    private settingResolver: SettingResolver,
    private translationResolver: TranslationResolver,
    private loadingService: LoadingService,
    private translateService: TranslateService,
    private coreConfig: CompleteConfig,
    private permissionResolver: PermissionResolver,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.loadingService.toggleLoading(
      true,
      this.coreConfig.useTranslatedLoaderText ? this.translateService.instant(this.coreConfig.loaderText) : this.coreConfig.loaderText,
      this.coreConfig.loaderImage,
      'complete-resolver-loader',
    );

    return forkJoin([
      this.baseDataResolver.resolve(route, state),
      this.moduleResolver.resolve(route, state),
      this.settingResolver.resolve(route, state),
      this.translationResolver.resolve(route, state),
      this.permissionResolver.resolve(route, state),
    ]).pipe(
      finalize(() => this.loadingService.hideLoader()),
      catchError(() => {
        this.loadingService.hideLoader();

        return of(false);
      }),
      map(() => {
        this.loadingService.hideLoader();

        return true;
      }),
    );
  }
}
