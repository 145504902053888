import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumButtonModule } from '@capturum/ui/button';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AccordionModule as PrimeAccordionModule } from 'primeng/accordion';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { CapturumSharedModule } from '@capturum/ui/api';
import { FormlyModule } from '@ngx-formly/core';
import { inputTypeWrappers } from '@shared/configs/input-type-wrappers.config';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { AccordionModule } from '@shared/modules/accordion/accordion.module';
import { CapturumPrimeNotificationBarModule } from '@capturum/ui/notification-bar';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { NgxPermissionsModule } from '@node_modules/ngx-permissions';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { CapturumInputModule } from '@capturum/ui/input';

// PIPES
import { ParseUrls } from './pipes/text-to-url.pipe';
import { AttributeBaseDataPipe } from '@shared/pipes/attribute-base-data.pipe';
import { BoolifyPipe } from './pipes/boolify.pipe';
import { CheckIfPriorityStatusPipe } from '@shared/pipes/check-if-priority-status.pipe';
import { DefaultValuePipe } from '@shared/pipes/default-value.pipe';
import { AssetExistsPipe } from '@shared/pipes/asset-exists.pipe';
import { BaseDataPipe } from '@shared/pipes/base-data.pipe';
import { TranslateDentalPipe } from '@shared/pipes/translate-dental.pipe';
import { FormatDatePipe } from './pipes/formatDate.pipe';
import { AssetsPipe } from './pipes/assets.pipe';

// COMPONENTS
import { HeaderComponent } from './components/header/header.component';
import { FormAlertComponent } from './components/form-alert/form-alert.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { DetailLayoutComponent } from './components/detail-layout/detail-layout.component';
import { TooltipComponent } from '@shared/components/tooltip/tooltip.component';
import { ContentWrapperComponent } from '@shared/wrappers/content-wrapper/content-wrapper.component';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';
import { DropdownImageComponent } from './components/dropdown-image/dropdown-image.component';
import { TableFiltersComponent } from './components/table-filters/table-filters.component';
import { CalendarFooterComponent } from './components/calendar-footer/calendar-footer.component';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { FormTooltipComponent } from './components/form-tooltip/form-tooltip.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { DefaultInputWrapperComponent } from './wrappers/default-input-wrapper/default-input-wrapper.component';

const EXPORTED_COMPONENTS = [
  HeaderComponent,
  SubHeaderComponent,
  FormAlertComponent,
  SidebarComponent,
  DetailLayoutComponent,
  TooltipComponent,
  ConfirmComponent,
  NotificationComponent,
  DropdownButtonComponent,
  FormTooltipComponent,
  ConfirmationComponent,
  CalendarFooterComponent,
  DropdownImageComponent,
  TableFiltersComponent,
];

const EXPORTED_PIPES = [
  AssetsPipe,
  AssetExistsPipe,
  FormatDatePipe,
  TranslateDentalPipe,
  BaseDataPipe,
  AttributeBaseDataPipe,
  CheckIfPriorityStatusPipe,
  DefaultValuePipe,
  BoolifyPipe,
  ParseUrls,
];

const EXPORTED_MODULES = [
  CapturumButtonModule,
  FormsModule,
  ReactiveFormsModule,
  RadioButtonModule,
  DialogModule,
  PrimeAccordionModule,
  NgxPermissionsModule,
];

@NgModule({
  declarations: [
    ContentWrapperComponent,
    DefaultInputWrapperComponent,
    ...EXPORTED_PIPES,
    ...EXPORTED_COMPONENTS,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    AccordionModule,
    ProgressSpinnerModule,
    DynamicDialogModule,
    CapturumFileUploadModule,
    CapturumSharedModule,
    FormlyModule.forChild({
      wrappers: inputTypeWrappers,
    }),
    CapturumPrimeNotificationBarModule,
    CapturumDropdownModule,
    CapturumCalendarModule,
    CapturumInputModule,
    ...EXPORTED_MODULES,
  ],
  exports: [
    ...EXPORTED_COMPONENTS,
    ...EXPORTED_PIPES,
    ...EXPORTED_MODULES,
  ],
  providers: [
    { provide: TranslateDentalPipe, useClass: TranslateDentalPipe },
  ],
})
export class SharedModule {
}
