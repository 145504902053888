export enum InputType {
  checkbox = 'checkbox',
  checkboxList = 'checkbox_list',
  text = 'text',
  textarea = 'textarea',
  custom = 'custom',
  label = 'label',
  email = 'email',
  dropdown = 'dropdown',
  image = 'image',
  number = 'number',
  date = 'date',
  group = 'group',
  radio = 'radio',
  file = 'file',
  colorSystem = 'color_system',
  implantCustom = 'implant_custom',
  calendar = 'calendar',
  customButton = 'custom_button',
}
