import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from '@node_modules/primeng/dynamicdialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  constructor(
    public readonly ref: DynamicDialogRef,
    public readonly config: DynamicDialogConfig,
  ) {
  }

  public accept(): void {
    if (this.config.data && this.config.data.accept) {
      this.config.data.accept();
    }

    this.ref.close(true);
  }

  public reject(): void {
    this.ref.close(false);
  }
}
