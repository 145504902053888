import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonAction } from './button-action.model';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent {
  @Input() public icon: string;
  @Input() public label: string;
  @Input() public actions: ButtonAction[] = [];
  @Input() public isOpen: boolean = false;
  @Input() public styleClass: string;
  @Output() public clickAction: EventEmitter<ButtonAction> = new EventEmitter<ButtonAction>();

  constructor(private readonly router: Router) {
  }

  public toggleMenu(): void {
    this.isOpen = !this.isOpen && this.actions?.length > 0;
  }

  public executeAction(action: ButtonAction): void {
    this.isOpen = false;

    this.clickAction.emit(action);

    if (action && action.url) {
      this.router.navigate([action.url]);
    } else if (action.callback) {
      action.callback();
    }
  }
}
