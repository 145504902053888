import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@node_modules/@ngx-formly/core';
import { ImplantCustomFacade } from '@features/client/order-workflow/facades/implant-custom.facade';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from '@node_modules/rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ImplantCustomKey } from '@features/client/order-workflow/enums/implant-custom-key.enum';
import { InputType } from '@features/client/form-builder/enums/input-type.enum';
import { FormlyTemplateOptions } from '@node_modules/@ngx-formly/core/lib/components/formly.field.config';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Component({
  selector: 'app-implant-custom-input',
  templateUrl: './implant-custom-input.component.html',
})
export class ImplantCustomInputComponent extends FieldType implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public implantBrandOptions: any;
  public implantSystemOptions: any;
  public implantPlatformOptions: any;
  public platformRequired = true;
  public systemRequired = true;
  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly implantCustomFacade: ImplantCustomFacade,
    private readonly formBuilder: FormBuilder,
    private readonly attributeBaseDataService: AttributeBaseDataService,
    private readonly translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.initializeForm();

    this.subscribeToBrandChanges();
    this.subscribeToSystemChanges();
    this.subscribeToPlatformChanges();
    this.subscribeToMaterialChanges();

    this.setBrands();
    this.patchValue();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  protected getCommonValidations(templateOptions: FormlyTemplateOptions): ValidatorFn[] {
    return templateOptions?.required ? [Validators.required] : null;
  }

  private initializeForm(): void {
    const commonValidations = this.getCommonValidations(this.field?.templateOptions);

    this.formGroup = this.formBuilder.group({
      [ImplantCustomKey.brandBaseDataValueId]: [null, commonValidations],
      [ImplantCustomKey.systemBaseDataValueId]: [null, commonValidations],
      [ImplantCustomKey.platformBaseDataValueId]: [null, commonValidations],
      [ImplantCustomKey.groupBaseDataValueId]: [null, commonValidations],
    });
  }

  private subscribeToMaterialChanges(): void {
    // Trigger fetching needed fields for implat custom input when material was changed
    this.form
      .get('attribute_1lvl_dropdown_mandatory_material_9')
      ?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const brandFormControl = this.formGroup.controls[ImplantCustomKey.brandBaseDataValueId];

          if (brandFormControl && brandFormControl.value) {
            brandFormControl.setValue(brandFormControl.value);
          }
        },
      });
  }

  private subscribeToBrandChanges(): void {
    this.formGroup.controls[ImplantCustomKey.brandBaseDataValueId]?.valueChanges
      .pipe(
        filter(Boolean),
        switchMap(() => {
          this.implantSystemOptions = [];
          this.implantPlatformOptions = [];

          this.clearFormValues([
            ImplantCustomKey.systemBaseDataValueId,
            ImplantCustomKey.platformBaseDataValueId,
            ImplantCustomKey.groupBaseDataValueId,
          ]);

          this.cdr.detectChanges();

          return this.implantCustomFacade.getImplants(this.getImplantValues());
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        this.implantSystemOptions = response;

        this.platformRequired = true;
        this.systemRequired = true;

        if (!response.length) {
          this.implantSystemOptions = [{ id: 'empty', value: '-' }];
          this.formGroup.get(ImplantCustomKey.platformBaseDataValueId).enable();
          this.formGroup.get(ImplantCustomKey.systemBaseDataValueId).enable();

          this.cdr.detectChanges();

          this.formGroup.get(ImplantCustomKey.platformBaseDataValueId).removeValidators(Validators.required);
          this.formGroup.get(ImplantCustomKey.systemBaseDataValueId).removeValidators(Validators.required);

          this.platformRequired = false;
          this.systemRequired = false;

          this.patchAsEmpty(ImplantCustomKey.platformBaseDataValueId);
          this.patchAsEmpty(ImplantCustomKey.systemBaseDataValueId);

          this.formGroup.updateValueAndValidity();
        }

        this.formGroup.get(ImplantCustomKey.platformBaseDataValueId).addValidators(Validators.required);
        this.formGroup.get(ImplantCustomKey.systemBaseDataValueId).addValidators(Validators.required);
        this.cdr.detectChanges();

        this.addImplantsToForm();
      });
  }

  private subscribeToSystemChanges(): void {
    this.formGroup.controls[ImplantCustomKey.systemBaseDataValueId]?.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(Boolean),
        switchMap((value) => {
          this.implantPlatformOptions = [];
          this.clearFormValues([ImplantCustomKey.platformBaseDataValueId, ImplantCustomKey.groupBaseDataValueId]);

          return this.implantCustomFacade.getImplants(this.getImplantValues());
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((response) => {
        if (response.length) {
          this.implantPlatformOptions = response;
        } else {
          this.implantPlatformOptions = [{ id: 'empty', value: '-' }];

          this.cdr.detectChanges();
          this.patchAsEmpty(ImplantCustomKey.platformBaseDataValueId);
          this.patchAsEmpty(ImplantCustomKey.groupBaseDataValueId);
        }

        this.addImplantsToForm();
      });
  }

  private subscribeToPlatformChanges(): void {
    this.formGroup.controls[ImplantCustomKey.platformBaseDataValueId]?.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        filter(Boolean),
        switchMap((value) => {
          this.clearFormValues([ImplantCustomKey.groupBaseDataValueId]);

          return this.implantCustomFacade
            .getImplants(this.getImplantValues())
            .pipe(map((response) => ({ value, response })));
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(({ value, response }) => {
        if (value) {
          if (response.length) {
            this.formGroup.controls[ImplantCustomKey.groupBaseDataValueId]?.patchValue(response[0]?.id);
            this.addImplantsToForm();
          } else {
            this.patchAsEmpty(ImplantCustomKey.groupBaseDataValueId);
          }
        } else {
          this.clearFormValues([ImplantCustomKey.groupBaseDataValueId]);
        }

        this.cdr.detectChanges();
      });
  }

  private setBrands(): void {
    this.attributeBaseDataService
      .getBaseDataValuesByKey(ImplantCustomKey.implantBrand)
      .pipe(first())
      .subscribe((brands) => {
        this.implantBrandOptions = brands.map((brand) => {
          return { ...brand, value: this.translateService.instant(`base-data.dental.${brand.value}`) };
        });
      });
  }

  private getImplantValues(): string[] {
    const implantValues = Object.values(this.formGroup.value).filter((item) => !!item) as string[];

    implantValues.unshift(this.key as string);

    return implantValues;
  }

  private clearFormValues(keys: string[]): void {
    for (let i = 0; i < keys.length; i++) {
      this.formGroup.get(keys[i]).setValue(null, { emitEvent: false });
    }
  }

  private patchAsEmpty(controlName: string): void {
    this.formGroup.controls[controlName]?.setValue('empty');
  }

  private addImplantsToForm(): void {
    const implantCustomAttributeKey = `attribute_${InputType.implantCustom}`;

    const formData = {
      [`${this.key}`]: this.formGroup.value[ImplantCustomKey.groupBaseDataValueId] || null,
      [implantCustomAttributeKey]: { ...this.formGroup.value },
    };

    if (!this.form.controls[implantCustomAttributeKey]) {
      this.form.addControl(
        implantCustomAttributeKey,
        this.formBuilder.control(null, this.getCommonValidations(this.field?.templateOptions))
      );
    } else {
      this.form.controls[implantCustomAttributeKey].removeValidators(Validators.required);
    }

    this.form.patchValue(formData);
  }

  private patchValue(): void {
    if (this.formControl?.value) {
      this.formGroup.patchValue(this.formControl?.value);
    }
  }
}
