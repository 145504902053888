import { Type } from '@angular/core';
import { WorkflowManager } from '@features/client/workflow-manager/interfaces/workflow-manager';

export interface WorkflowManagerConfig {
  key: string;
  workflowManagerService?: Type<WorkflowManager>;
}

export class WorkflowManagerConfigService implements WorkflowManagerConfig {
  public key: string;
  public workflowManagerService: Type<WorkflowManager>;
}
