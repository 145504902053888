import { Injectable } from '@angular/core';
import { ApiHttpService, ApiIndexResult, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Locale } from '@features/client/profile/models/locale.model';

@Injectable()
export class LocaleApiService extends ApiService<Locale> {
  protected endpoint = 'tenant/locale';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public get locales(): Observable<Locale[]> {
    return this.index().pipe(
      map((result: ApiIndexResult<Locale>) => result?.data?.map(locale => {
        return { ...locale, url: `assets/flags/${locale.code}.svg` };
      })),
    );
  }
}
