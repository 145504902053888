import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { MapItem } from '@capturum/ui/api';
import { OrderWorkflowFacade } from '@features/client/order-workflow/facades/order-workflow.facade';
import { BaseDataService } from '@core/services/base-data.service';
import { ProductCategoryApiService } from '@features/client/order-workflow/services/product-category-api.service';
import { OrderProductApiService } from '@features/client/order-workflow/services/order-product-api.service';
import { BaseDataKey } from '@features/client/order-workflow/enums/base-data-key.enum';
import { ProductGroupMapItem, ProductTypeMapItem } from '@features/client/order-workflow/models/product.model';
import { OrderProduct } from '@features/client/order-workflow/models/order-product.model';
import { SelectedTooth, Tooth, ToothCross } from '@features/client/order-workflow/models/tooth.model';
import { ToothPart } from '@features/client/order-workflow/enums/tooth-part.enum';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { Order } from '@features/client/order-workflow/models/order.model';
import { flatten, isArray, isNil, startsWith } from 'lodash';
import { ToothCrossService } from '@features/client/order-workflow/services/tooth-cross.service';
import { RotaryDialButton } from '@features/client/order-workflow/models/rotary-dial-button.model';
import { RootProductApiService } from '@features/client/order-workflow/services/root-product-api.service';
import { BaseDataValueMapItem } from '@core/models/base-data-value-map-item.model';
import { Store } from '@node_modules/@ngxs/store';
import { OrderWorkflowSelectors } from '@features/client/order-workflow/state/order-workflow.selectors';
import {
  SetOrder,
  SetOrderProduct,
  SetProductType,
} from '@features/client/order-workflow/state/order-workflow.actions';
import { CrownAction } from '@features/client/order-workflow/enums/crown-action.enum';
import * as moment from 'moment';
import { DatesFormats } from '@core/configs/date-config';
import { AccordionMenuInputService } from '@features/client/order-workflow/components/accordion-menu-input/accordion-menu-input.service';
import { BehaviorSubject, of } from '@node_modules/rxjs';
import { ProductConfigurationService } from '@features/client/order-workflow/services/product-configuration.service';
import { OrderApiService } from '@features/client/order-workflow/services/order-api.service';
import { ToothStateHighlightPipe } from '@features/client/order-workflow/pipes/tooth-state-highlight.pipe';
import { OrderWorkflowStepFacade } from '@features/client/order-workflow/facades/order-workflow-step.facade';
import { OrderWorkflowService } from '@features/client/order-workflow/services/order-workflow.service';
import { AttributeBaseDataService } from '@core/services/attribute-base-data.service';

@Injectable()
export class ProductConfigurationFacade extends OrderWorkflowStepFacade {
  public colorSystemHiddenFields: Record<string, boolean> = {};
  private _updateValidation$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  private readonly orderProductInclude: string[] = [
    'product.productCategory',
    'order.toothCross',
    'configuration',
    'attributeValues',
    'product.toothCrossZones',
    'files.tags',
  ];

  constructor(
    protected readonly orderWorkflowFacade: OrderWorkflowFacade,
    protected readonly orderWorkflowService: OrderWorkflowService,
    protected readonly store: Store,
    private readonly baseDataService: BaseDataService,
    private readonly attributeBaseDataService: AttributeBaseDataService,
    private readonly productCategoryApiService: ProductCategoryApiService,
    private readonly orderProductApiService: OrderProductApiService,
    private readonly toothCrossService: ToothCrossService,
    private readonly rootProductApiService: RootProductApiService,
    private readonly accordionMenuInputService: AccordionMenuInputService,
    private readonly productConfigurationService: ProductConfigurationService,
    private readonly orderApiService: OrderApiService,
    private readonly toothStateHighlight: ToothStateHighlightPipe,
  ) {
    super(orderWorkflowFacade, orderWorkflowService, store);
  }

  private _isFormValid$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public get isFormValid$(): Observable<boolean> {
    return this._isFormValid$.asObservable();
  }

  public get onUpdateValidation$(): Observable<void> {
    return this._updateValidation$.asObservable();
  }

  public get onApplyConfigurationSet$(): Observable<boolean> {
    return this.productConfigurationService.onApplyConfigurationSet$;
  }

  public setIsFormValid(status: boolean): void {
    this._isFormValid$.next(status);
  }

  public updateValidation(): void {
    this._updateValidation$.next();
  }

  public fetchProductConfiguration(): Observable<Order> {
    return this.store.select(OrderWorkflowSelectors.getOrder).pipe(
      first(),
      switchMap(({ id }: Order) => this.orderWorkflowFacade.getOrder(id, { include: ['toothCross', 'toothCrossFiles'] })),
      map((order: Order) => {
        this.store.dispatch(new SetOrder(order));
        this.toothCrossService.setToothCross(order.toothCross);

        return order;
      }),
      first(),
    );
  }

  public getOrderStep(): Observable<string> {
    return this.orderWorkflowFacade.getOrderStep();
  }

  public getProductCategories(): Observable<MapItem[]> {
    return this.attributeBaseDataService.listBaseData(BaseDataKey.productCategory);
  }

  public getProductGroupsByCategory(productCategory: string): Observable<ProductGroupMapItem[]> {
    return this.productCategoryApiService.listProductGroups(productCategory);
  }

  public getJawTypes(): Observable<MapItem[]> {
    return this.baseDataService.listBaseData(BaseDataKey.jawType);
  }

  public getRootTypes(): Observable<BaseDataValueMapItem[]> {
    return this.rootProductApiService.getAll();
  }

  public getOrderProduct(id: string): Observable<OrderProduct> {
    if (id) {
      return this.orderProductApiService.get(id, { include: this.orderProductInclude });
    }
  }

  public createOrderProduct(orderProduct: OrderProduct, autoSelectCreated: boolean = true): Observable<OrderProduct> {
    return this.orderProductApiService.create(orderProduct, { include: this.orderProductInclude })
      .pipe(
        switchMap((data: OrderProduct) => {
          const order = data.order;
          const teeth = flatten(order.toothCross.groups.map(group => group.teeth));
          const tooth = teeth.find((item: Tooth) => orderProduct.tooth_number === item.number);
          const toothPart = orderProduct.is_root ? ToothPart.root : ToothPart.crown;

          this.toothCrossService.setToothCross(order.toothCross);
          this.store.dispatch(new SetOrder(order));

          if (!autoSelectCreated) {
            if (tooth.is_configured) {
              this.setSelectedTooth(null);

              return EMPTY;
            } else {
              autoSelectCreated = true;
            }
          }

          if (autoSelectCreated) {
            this.setOrderProduct(data);
            this.toothCrossService.setSelectedTooth({ tooth, toothPart });
          }

          return of(order);
        }),
      );
  }

  public updateOrderProduct(orderProduct: OrderProduct): Observable<OrderProduct> {
    return this.orderProductApiService
      .update(this.prepareOrderProductData(orderProduct), { include: this.orderProductInclude })
      .pipe(
        tap((data: OrderProduct) => {
          const order = data.order;

          this.toothCrossService.setToothCross(order.toothCross);
          this.store.dispatch(new SetOrder(order));
        }),
      );
  }

  public deleteOrderProduct(orderProduct: OrderProduct): Observable<OrderProduct> {
    return this.orderProductApiService.deleteByBatch(orderProduct.id, orderProduct.batch)
      .pipe(
        tap((response) => this.toothCrossService.setToothCross(response.data.toothCross)),
      );
  }

  public submitData(data: Partial<Order>): Observable<Order> {
    const order = this.orderWorkflowFacade.getOrderSnapshot();
    data = this.getSubmitData({ ...order, ...data });

    return this.toothCrossService.getToothCross().pipe(
      first(),
      switchMap((toothCross: ToothCross) => {
        return this.orderWorkflowFacade.continueOrder(order.id, data);
      }),
    );
  }

  public getSelectedTooth(): Observable<SelectedTooth> {
    return this.toothCrossService.getSelectedTooth();
  }

  public getClickTooth(): Observable<SelectedTooth> {
    return this.toothCrossService.getClickTooth().pipe(filter((value: SelectedTooth) => !isNil(value)));
  }

  public setSelectedTooth(selectedTooth: SelectedTooth): void {
    return this.toothCrossService.setSelectedTooth(selectedTooth);
  }

  public getOrderProductByToothPart(tooth: Tooth, toothPart: ToothPart): OrderProduct {
    let orderProductData: OrderProduct = null;

    if (this.hasProductType()) {
      orderProductData = {
        order_id: this.orderWorkflowFacade.getOrderSnapshot()?.id,
        product_id: this.getProductTypeSnapshot()?.product_id,
        tooth_number: tooth.number,
        is_root: toothPart === ToothPart.root,
      };
    }

    return orderProductData;
  }

  public setRootTypes(rootTypes: RotaryDialButton[]): void {
    this.toothCrossService.setRootTypes(rootTypes);
  }

  public setShowRoot(showRoot: boolean): void {
    this.toothCrossService.setShowRoot(showRoot);
  }

  public getShowRoot(): Observable<boolean> {
    return this.toothCrossService.getShowRoot();
  }

  public getOrderProductOptionsByTooth(tooth: Tooth): MapItem[] {
    return tooth.crown.orderProducts.map((orderProduct: OrderProduct) => ({
      value: orderProduct.id,
      label: this.attributeBaseDataService.getBaseDataValueTranslation(orderProduct.product.productType),
    }));
  }

  public setProductType(productType: ProductTypeMapItem): void {
    this.store.dispatch(new SetProductType(productType));

    if (productType) {
      const orderProduct: any = this.getOrderProductSnapshot() || {};

      this.store.dispatch(new SetOrderProduct({ ...orderProduct, product_id: productType?.product_id }));
    }
  }

  public getProductTypeSnapshot(): ProductTypeMapItem {
    return this.store.selectSnapshot(OrderWorkflowSelectors.getProductType);
  }

  public getProductType(): Observable<ProductTypeMapItem> {
    return this.store.select(OrderWorkflowSelectors.getProductType);
  }

  public hasProductType(): boolean {
    const productType = this.store.selectSnapshot(OrderWorkflowSelectors.getProductType);

    return !isNil(productType);
  }

  public setOrderProduct(orderProduct: OrderProduct): void {
    if (orderProduct) {
      this.store.dispatch(new SetOrderProduct(orderProduct));
    }
  }

  public getOrderProductSnapshot(): OrderProduct {
    return this.store.selectSnapshot(OrderWorkflowSelectors.getOrderProduct);
  }

  public setCurrentOrderProduct(tooth: Tooth): void {
    this.setOrderProduct(
      tooth ? this.getCurrentOrderProduct(tooth) : null,
    );
  }

  public getCurrentOrderProduct(tooth: Tooth): OrderProduct {
    return tooth.crown.orderProducts.find((item: OrderProduct) => item.id === tooth.crown.current_product_id);
  }

  public setHasCrownActions(hasCrownActions: boolean): void {
    this.toothCrossService.setHasCrownActions(hasCrownActions);
  }

  public getCrownAction(): Observable<CrownAction> {
    return this.toothCrossService.getCrownAction();
  }

  public resetCrownAction(): void {
    this.toothCrossService.resetCrownAction();
  }

  public setIsConfiguring(isConfiguring: boolean): void {
    this.toothCrossService.setIsConfiguring(isConfiguring);
  }

  public setApplyConfiguration(apply: boolean): void {
    this.productConfigurationService.setApplyConfiguration(apply);
  }

  public resetProductConfigurationService(): void {
    this.productConfigurationService.ngOnDestroy();
  }

  public setBreadcrumbShown(show: boolean): void {
    this.accordionMenuInputService.setBreadcrumbShown(show || false);

    if (!show) {
      this.setClickBreadcrumb(false);
    }
  }

  public isBreadcrumbShown(): Observable<boolean> {
    return this.accordionMenuInputService.isBreadcrumbShown();
  }

  public getClickBreadcrumb(): Observable<boolean> {
    return this.accordionMenuInputService.getClickBreadcrumb();
  }

  public setClickBreadcrumb(click: boolean): void {
    this.accordionMenuInputService.setClickBreadcrumb(click);
  }

  public setProductTypeClicked(click: boolean): void {
    this.accordionMenuInputService.setProductTypeClicked(click);
  }

  public isProductTypeClicked(): Observable<boolean> {
    return this.accordionMenuInputService.isProductTypeClicked();
  }

  public isUpperJawSelectedSnapshot(): boolean {
    return this.toothCrossService.isUpperJawSelectedSnapshot();
  }

  public isLowerJawSelectedSnapshot(): boolean {
    return this.toothCrossService.isLowerJawSelectedSnapshot();
  }

  public resetJawSelection(): void {
    this.toothCrossService.resetJawSelection();
  }

  public addJawProducts(jaw: string, productId: string): Observable<Order> {
    const orderId = this.orderWorkflowFacade.getOrderSnapshot().id;

    return this.orderApiService.addJawProducts(orderId, productId, jaw).pipe(map(order => {
      const teeth = flatten(order.toothCross.groups.map(group => group.teeth));
      const jawTeeth = teeth.filter((item: Tooth) => this.toothStateHighlight.transform(jaw, item.number));
      let tooth = jawTeeth.find(item => {
        const availableOrderProduct = item.crown.orderProducts.find(orderProduct => orderProduct.product_id === productId);

        return !availableOrderProduct?.is_configured;
      });
      // There exists some case where all products are configured and it will not
      // be able to find a tooth to select and will be undefined.
      // In this case select the first tooth of the jaw
      if (!tooth) {
        tooth = jawTeeth[0];
      }

      this.setCurrentOrderProduct(tooth);
      this.toothCrossService.setSelectedTooth({ tooth, toothPart: ToothPart.crown });

      return order;
    }));
  }

  public deleteJawProducts(jaw: string, productId: string): Observable<Order> {
    const orderId = this.orderWorkflowFacade.getOrderSnapshot().id;

    return this.orderApiService.deleteJawProducts(orderId, productId, jaw);
  }

  public getToothCross(): Observable<ToothCross> {
    return this.toothCrossService.getToothCross();
  }

  public setLoading(isLoading: boolean): void {
    this.productConfigurationService.setLoading(isLoading);
  }

  public getIsLoading(): Observable<boolean> {
    return this.productConfigurationService.getIsLoading();
  }

  public getSubmitData(formData: any): Partial<Order> {
    return {
      ...formData,
      patient_return_at: formData?.patient_return_at ? moment(new Date(formData?.patient_return_at)).format(DatesFormats.sendFormat) : null,
    };
  }

  private prepareOrderProductData(orderProduct: OrderProduct): OrderProduct {
    const formBody = { ...orderProduct };

    for (const property in formBody) {
      // Remove file attributes from the body as files are saved on upload itself.
      if (startsWith(property, 'attribute_') && isArray(formBody[property]) && !!formBody[property].length) {
        if (formBody[property][0]?.filename && !!formBody[property][0]?.tags?.length) {
          delete formBody[property];
        }
      }
    }

    return formBody;
  }
}
