import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionItemComponent } from '@shared/modules/accordion/components/accordion-item/accordion-item.component';
import { AccordionContainer } from '@shared/modules/accordion/containers/accordion/accordion.container';
import { AccordionService } from '@shared/modules/accordion/services/accordion.service';
import { ProgressSpinnerModule } from '@node_modules/primeng/progressspinner';
import { AccordionLoadingService } from '@shared/modules/accordion/services/accordion-loading.service';

@NgModule({
  declarations: [
    AccordionItemComponent,
    AccordionContainer,
  ],
  imports: [
    CommonModule,
    ProgressSpinnerModule,
  ],
  exports: [
    AccordionContainer,
    AccordionItemComponent,
  ],
  providers: [
    AccordionService,
    AccordionLoadingService,
  ],
})
export class AccordionModule {
}
