import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { User } from '@features/client/profile/models/user.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserApiService extends ApiService<User> {
  protected endpoint = 'user';

  constructor(
    public apiHttp: ApiHttpService,
  ) {
    super(apiHttp);
  }

  public restore(userId: string): Observable<User> {
    return this.apiHttp.post(`/${this.endpoint}/${userId}/restore`, null);
  }
}
