<div class="filters">
  <h5 class="filters__title text-right mx-3">
    {{ 'table.filters' | translate }}
  </h5>

  <div class="row w-100 mr-0 align-items-center">
    <div class="col-9 col-md-3">
      <cap-input [placeholder]="'search.placeholder' | translate"
                 [ngModel]="activeFilters?.global?.value"
                 class="has-icon"
                 styleClass="cap-rounded"
                 [formControl]="searchControl">
      </cap-input>

      <div class="filter-icon">
        <i class="fas fa-fw fa-search"></i>
      </div>
    </div>

    <ng-container *ngIf="hasDateFilter">
      <div class="col-9 col-md-3" *ngxPermissionsOnly="'order.manage.all'">
        <cap-calendar styleClass="cap-rounded"
                      icon="fas fa-calendar-alt"
                      clearButtonStyleClass="cap-button secondary p-1 mt-2"
                      todayButtonStyleClass="cap-button secondary p-1 mt-2"
                      [maxDate]="maxDate"
                      [showButtonBar]="true"
                      [readonlyInput]="true"
                      [disabled]="disableFilters"
                      [selectionMode]="'range'"
                      [placeholder]="'dental.delivery_return_at.placeholder' | translate"
                      [(ngModel)]="datesRangeFilter"
                      (select)="filterByCreatedAtDate()"
                      (clearClick)="datesRangeFilter = null; filterByCreatedAtDate()">
        </cap-calendar>
      </div>
    </ng-container>
  </div>
</div>
